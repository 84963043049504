import React from "react";
import "./Dashboard.scss";
import DashboardSidebar from "../../organisms/DashboardSidebar/DashboardSidebar";
import DashNav from "../../organisms/DashNav/DashNav";
import DashHeading from "../../organisms/DashHeading/DashHeading";
import DashStates from "../../organisms/DashStates/DashStates";
import DashClocks from "../../organisms/DashClocks/DashClocks";
import DashboardRevenueChart from "../../organisms/DashboardRevenueChart/DashboardRevenueChart";
import DashboardRecentTransaction from "../../organisms/DashboardRecentTransactions/DashboardRecentTransaction";
import DashCampaign from "../../organisms/DashCampaign/DashCampaign";
import DashSalesQuantity from "../../organisms/DashSalesQuantity/DashSalesQuantity";
import { useSelector } from "react-redux";
import DashboardSearch from "../../molecules/DashboardSearch/DashboardSearch";
import DashboardPage from "../DashboardPage/DashboardPage";
import usePermissions from "../../../customHooks/usePermissions";
import Loading from "../../organisms/Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import ItDashBoard from "../../organisms/progressDashboard/ItDashboard/ITDashBoard";
export default function Dashboard() {
  const isNightMode = useSelector((state) => state.isNightMode);
  // permission
  const [permissions, permissionsFlag] = usePermissions(["permissions_management.search_platform"]);
  return (
    // <div className={isNightMode ? "dashboard dashboard-night" : "dashboard"}>
    //   <div className="dashboard-left">
    //     <DashboardSidebar />
    //   </div>
    //   <div className="dashboard-right w-full">
    //     <DashNav />
    //     <DashHeading />
    //     <DashStates />
    //     <DashClocks />
    //     <div className="charts">
    //       {/* <DashboardRevenueChart />
    //       <DashboardRecentTransaction /> */}
    //     </div>
    //     <div className="w-full">
    //       {/* <DashCampaign />
    //       <DashSalesQuantity /> */}
    //       <DashboardSearch />
    //     </div>
    //   </div>
    // </div>

    <DashboardPage>
      {permissionsFlag ? (
        <div className="w-full h-full flex flex-col gap-y-5 justify-center items-center ">
          <DashClocks />
          <DashStates />
          {/* <ItDashBoard /> */}
          <div className="w-full ">{permissions.some((item) => item.value) && <DashboardSearch />}</div>
        </div>
      ) : (
        <div className="w-full flex items-center justify-center h-screen ">
          <TopBarProgress />
          <Loading />
        </div>
      )}
    </DashboardPage>
  );
}
