import api_token from "../../api/UserApi";
import {
  CARDSTATUS,
  CLEARBOOKINGORDER,
  DATAOFBOOKINGORDER,
  DECREASEQTY,
  DELETEDATAOFBOOKINGORDER,
  INCREASEQTY,
  TOTALPRICE,
  TOTOALAMOUNT,
} from "../types/types";
import { toast } from "react-hot-toast";

export const statusCardAction = (cardStatus) => {
  return async (dispatch, getState) => {
    dispatch({
      type: CARDSTATUS,
      payload: cardStatus,
    });
  };
};

export const setDataOFBookingOrder = (data, roomId, mealType, selectedroom, roomtype_id, uniqueId) => {
  return async (dispatch, getState) => {
    if (getState().cardReducer.cardTotalAmount === 9) {
      toast.error(`Max Added Rooms is 9`);
    } else {
      const cardTotalAmount = getState().cardReducer.cardTotalAmount + 1;
      const newBookingCard = { ...data, quantity: 1 };

      const itemIndex = getState().cardReducer.cardData.findIndex((item) => item.uniqueId === uniqueId);

      if (itemIndex >= 0) {
        const newItem = getState().cardReducer.cardData.map((item) => {
          if (item.uniqueId === uniqueId) {
            return { ...item, quantity: item.quantity + 1 };
          } else {
            return item;
          }
        });
        dispatch({
          type: DATAOFBOOKINGORDER,
          payload: newItem,
        });
        dispatch({
          type: TOTOALAMOUNT,
          payload: cardTotalAmount,
        });

        toast.success(`${selectedroom} QTY INCREASED`);
      } else {
        dispatch({
          type: DATAOFBOOKINGORDER,
          payload: [...getState().cardReducer.cardData, newBookingCard],
        });
        dispatch({
          type: TOTOALAMOUNT,
          payload: cardTotalAmount,
        });
        toast.success(`${selectedroom} added to Cart`);
      }
    }
  };
};

export const deleteDataOfBookingOrder = (room_id, mealType, price, selectedroom, roomtype_id, uniqueId) => {
  return async (dispatch, getState) => {
    const room = getState().cardReducer.cardData.find((item) => item.price === price);
    const qty = getState().cardReducer.cardTotalAmount;
    const allQty = qty - room.quantity;

    const removeItem = getState().cardReducer.cardData.filter((item) => item.uniqueId !== uniqueId);

    dispatch({
      type: DELETEDATAOFBOOKINGORDER,
      payload: removeItem,
    });
    dispatch({
      type: TOTOALAMOUNT,
      payload: allQty,
    });
    toast.success(`${selectedroom} with  ${mealType} Removed From Booking Order`);
  };
};

export const increaseData = (room_id, mealType, price, roomtype_id, uniqueId) => {
  return async (dispatch, getState) => {
    if (getState().cardReducer.cardTotalAmount === 9) {
      toast.error(`Max Added Rooms is 9`);
    } else {
      const cardTotalAmount = getState().cardReducer.cardTotalAmount + 1;
      const room = getState().cardReducer.cardData.find((item) => item.uniqueId === uniqueId);
      if (room) {
        const cartItem = [...getState().cardReducer.cardData].map((item) => {
          if (item.uniqueId === uniqueId) {
            return { ...item, quantity: room.quantity + 1 };
          } else {
            return item;
          }
        });
        dispatch({
          type: INCREASEQTY,
          payload: cartItem,
        });
        dispatch({
          type: TOTOALAMOUNT,
          payload: cardTotalAmount,
        });
      } else {
        dispatch({
          type: INCREASEQTY,
          payload: room,
        });
        dispatch({
          type: TOTOALAMOUNT,
          payload: cardTotalAmount,
        });
      }
    }
  };
};

export const decreaseData = (room_id, mealType, price, roomtype_id, uniqueId) => {
  return async (dispatch, getState) => {
    const cardTotalAmount = getState().cardReducer.cardTotalAmount - 1; // if quantity room > 1 use this to minus all amount
    const cardTotalAmount1 = getState().cardReducer.cardTotalAmount; // but use this if  quantity room < 1 to minus all room
    const room = getState().cardReducer.cardData.find((item) => item.uniqueId === uniqueId);

    if (room) {
      const cartItem = [...getState().cardReducer.cardData].map((item) => {
        if (item.uniqueId === uniqueId && room.quantity > 1) {
          dispatch({
            type: TOTOALAMOUNT,
            payload: cardTotalAmount,
          });
          return { ...item, quantity: room.quantity - 1 };
        } else {
          return item;
        }
      });
      dispatch({
        type: DECREASEQTY,
        payload: cartItem,
      });
    } else {
      dispatch({
        type: DECREASEQTY,
        payload: room,
      });

      dispatch({
        type: TOTOALAMOUNT,
        payload: cardTotalAmount1,
      });
    }
  };
};

export const totalPirce = (cardData) => {
  return async (dispatch, getState) => {
    const total = cardData.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.price * currentItem.quantity;
    }, 0);
    dispatch({
      type: TOTALPRICE,
      payload: total,
    });
  };
};

export const clearBookingOrder = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: CLEARBOOKINGORDER,
      payload: [],
    });
    dispatch({
      type: TOTOALAMOUNT,
      payload: 0,
    });
    toast.success(`Booking Order is Cleared`);
  };
};

export const getRoomDescription = (token, id, lang, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`rooms/api/v1/room-detail-id/${id}`, {
        headers: { "Accept-Language": lang },
      });
      callback(response);
    } catch (error) {}
  };
};

export const getHotelsDescription = (token, hotelId, lang, callback) => {
  return async (dispatch, getState) => {
    try {
      const response = await api_token(token).get(`/hotels/api/v1/get-put-delete-hotels/${hotelId}/`, {
        headers: { "Accept-Language": lang },
      });
      callback(response);
    } catch (error) {}
  };
};
