import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import Loading from "../Loading/Loading";

import { main_URL } from "../../../urls";
import QRCode from "qrcode";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Errorfetch from "../Errorfetch/Errorfetch";
import dayjs from "dayjs";
import html2canvas from "html2canvas";

function MainInfoItem({ title, value }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "4px",
      }}
    >
      <p>{`${title} : `}</p>
      <p
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: value ? "-12px" : "",
          flexGrow: 1,
        }}
      >
        {value && (
          <span
            style={{
              color: "#000",
            }}
          >
            {value}
          </span>
        )}
        <span
          style={{
            marginTop: value ? "-4px" : "",
            borderBottomWidth: "2px",
            borderStyle: "dotted",
            borderColor: "#465695",
            width: "100%",
            height: "4px",
          }}
        />
      </p>
    </div>
  );
}

function FormInput({ title, value }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "4px",
        padding: "4px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "4px",
        borderColor: "#465695",
      }}
    >
      <h3>{`${title} : `}</h3>
      <h4
        style={{
          color: "#000",
          flexGrow: 1,
        }}
      >
        {value}
      </h4>
    </div>
  );
}

function GuestNames({ names }) {
  return (
    <div
      style={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "4px",
        borderColor: "#465695",
        padding: "4px",
        minHeight: "120px",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          gap: "4px",
        }}
      >
        <p>{`Guest Name: `}</p>
        <p
          style={{
            columnCount: 2,
            columnGap: "16px",
            columnFill: "auto",
            columnRule: "1px solid #eee",
            maxHeight: "180px",
            lineHeight: "normal",
          }}
        >
          {names?.map((name, index) => (
            <p
              key={index}
              style={{
                color: "#000",
              }}
            >
              {name}
            </p>
          ))}
        </p>
      </div>
    </div>
  );
}

const VoucherImage = forwardRef((props, ref) => {
  const location = useLocation();

  const [qrcode, setQrcode] = useState("");
  const { voucherRandomId } = useParams();

  const randomId = voucherRandomId || props?.voucher?.random_id;

  const url = `${main_URL}/booking-voucher/${randomId}`;
  const htmlContent = useRef(null);

  useEffect(() => {
    if (location.pathname && main_URL) {
      QRCode.toDataURL(url)
        .then((url) => {
          setQrcode(url);
        })
        .catch((err) => {});
    }
  }, [location.pathname, url]);

  const {
    data: createdVoucher,
    isLoading: isGettingVoucher,
    isError,
  } = useQuery({
    queryKey: ["voucher", randomId],
    queryFn: () => {
      return fetchDataQuery(`booking/api/v1/e-voucher-random-id/${randomId}/`);
    },
    enabled: !!voucherRandomId,
  });

  const [path, setPath] = React.useState("");

  const voucher = props?.voucher || createdVoucher;

  useEffect(() => {
    if (!props.voucherCreated) {
      if (htmlContent.current && voucher && qrcode && htmlContent.current.hasChildNodes()) {
        const convertToPng = async () => {
          if (qrcode && htmlContent.current && htmlContent.current.hasChildNodes() && voucher) {
            html2canvas(htmlContent.current, {
              allowTaint: true,
              useCORS: true,
              scrollX: 0,
              scrollY: 0,
              windowWidth: document.documentElement.offsetWidth,
              windowHeight: document.documentElement.offsetHeight,
            }).then((canvas) => {
              const image = canvas.toDataURL("image/png");
              setPath(image);
            });
          }
        };

        if (voucher) {
          convertToPng();
        }
      }
    }
  }, [props, qrcode, voucher]);

  useEffect(() => {
    if (path && !props.voucherCreated && props.setIsVoucherCreated) {
      props.setIsVoucherCreated(true);
    }
  }, [path, props]);

  const {
    voucherId,
    voucherType,
    hotelConfirmationNumber,
    systemConfirmationNumber,
    hotelName,
    guestNames,
    date,
    personsNum,
    childrenAges,
    checkOutDate,
    remarks,
    checkInDate,
    currency,
    totalPrice,
    nightsNum,
    mealPlan,
    nationality,
    roomType,
    issuedBy,
    netPrices,
  } = {
    voucherId: voucher?.id,
    voucherType: voucher?.voucher_type,
    hotelConfirmationNumber: voucher?.hotel_confirmation_number,
    systemConfirmationNumber: voucher?.system_confirmation_number,
    hotelName: voucher?.hotel_name,
    guestNames: voucher?.guest_name,
    date: voucher?.created_date,
    personsNum: voucher?.number_of_persons,
    childrenAges: voucher?.children_age,
    nationality: voucher?.nationality,
    roomType: voucher?.room_type,
    mealPlan: voucher?.meal_plan,
    checkInDate: voucher?.check_in_date,
    checkOutDate: voucher?.check_out_date,
    nightsNum: (new Date(voucher?.check_out_date) - new Date(voucher?.check_in_date)) / (1000 * 3600 * 24) - 1,
    issuedBy: voucher?.issued_by?.first_name + " " + voucher?.issued_by?.last_name,
    netPrices: voucher?.net_price_json,
    remarks: "",
    totalPrice: voucher?.total_price,
    currency: voucher?.currency,
  };

  if (isGettingVoucher || !voucher || !qrcode) {
    return <Loading />;
  }

  if (isError) {
    return <Errorfetch Error={`Error Fetching voucher`} />;
  }

  return (
    <div ref={ref} className={" w-full "}>
      {!path ? (
        <div className="w-full relative">
          <div className="bg-white w-full absolute top-0 left-0 bottom-0 right-0 z-10"></div>

          <div
            key={"htmlContent"}
            ref={htmlContent}
            style={{
              padding: "16px",

              fontSize: "14px",

              lineHeight: "20px",

              fontWeight: "600",

              margin: "0px auto",

              backgroundColor: "#ffffff",

              width: "793.7px",

              height: "1122.52px",

              overflow: "scroll",

              color: "#465695",

              flexShrink: 0,
            }}
          >
            <div
              style={{
                position: "relative",

                paddingTop: "16px",

                paddingBottom: "16px",

                paddingLeft: "32px",

                paddingRight: "32px",

                borderWidth: "1px",

                width: "100%",

                height: "100%",

                borderColor: "#465695",
              }}
            >
              <div
                style={{
                  display: "flex",

                  alignItems: "center",

                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",

                    flexDirection: "column",

                    gap: "8px",
                  }}
                >
                  <img
                    src={"/images/nugget-tours-black-logo.png"}
                    alt="Nugget Tours Logo"
                    style={{
                      width: "112px",

                      height: "64px",
                    }}
                  />

                  <div
                    style={{
                      display: "flex",

                      gap: "4px",
                    }}
                  >
                    <p>Tel </p>

                    <ul>
                      <li>01005373070</li>

                      <li>01111188796</li>
                    </ul>
                  </div>
                </div>

                <h1
                  style={{
                    fontSize: "16px",

                    lineHeight: "24px",

                    fontWeight: "700",

                    textAlign: "center",

                    flexGrow: 1,
                  }}
                >
                  E - Voucher{" "}
                  <span
                    style={{
                      fontWeight: "700",

                      color: "#DC2626",
                    }}
                  >
                    No:
                  </span>{" "}
                  <span
                    style={{
                      color: "#000",
                    }}
                  >
                    {voucherId}
                  </span>
                </h1>
              </div>

              <div
                style={{
                  marginTop: " 8px",

                  marginBottom: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",

                    marginBottom: "16px",

                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      flex: 2,
                    }}
                  >
                    <MainInfoItem title={"Hotel Confirmation No"} value={hotelConfirmationNumber} />
                  </div>

                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <MainInfoItem title={"Date"} value={dayjs(date).format("DD-MM-YYYY")} />
                  </div>

                  <div
                    style={{
                      flex: 1,
                    }}
                  >
                    <MainInfoItem title={"Time"} value={dayjs(date).format("HH:mm")} />
                  </div>
                </div>

                <div
                  style={{
                    display: "grid",

                    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",

                    gap: "8px",
                  }}
                >
                  <MainInfoItem title={"Voucher Type"} value={voucherType} />

                  <MainInfoItem title={"System Confirmation No"} value={systemConfirmationNumber} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",

                  flexDirection: "column",

                  gap: "8px",
                }}
              >
                <FormInput title="Hotel Name" value={hotelName} />

                <GuestNames names={guestNames} />

                <div
                  style={{
                    display: "grid",

                    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",

                    gap: "16px",
                  }}
                >
                  <FormInput title="Room Type" value={roomType} />

                  <FormInput title="Check-in date" value={dayjs(checkInDate).format("DD-MM-YYYY")} />

                  <FormInput title="Number of adults" value={personsNum} />

                  <FormInput title="Check-out date" value={dayjs(checkOutDate).format("DD-MM-YYYY")} />

                  <FormInput title="Number of children" value={childrenAges?.length || ""} />

                  <FormInput title="Total Nights" value={nightsNum} />

                  <FormInput
                    title="Children age"
                    value={childrenAges?.length > 0 ? childrenAges?.map((age) => `${age}Y`).join("-") : ""}
                  />

                  <FormInput title="Total price" value={totalPrice ? totalPrice + " " + currency : ""} />

                  <FormInput title="Nationality" value={nationality} />

                  <FormInput title="Meals plan" value={mealPlan?.toUpperCase()} />
                </div>

                <FormInput title="Remarks" value={remarks} />
              </div>

              <div
                style={{
                  display: "flex",

                  marginTop: "32px",

                  justifyContent: "space-between",

                  alignItems: "center",
                }}
              >
                <div>
                  <MainInfoItem title={"Issued by"} value={issuedBy} />
                </div>

                <div>
                  <MainInfoItem title={"Mobile"} value={"+201005373070"} />
                </div>
              </div>

              <div
                style={{
                  display: "flex",

                  position: "absolute",

                  right: "2rem",

                  bottom: "2rem",

                  left: "2rem",

                  marginTop: "1rem",

                  gap: "1rem",

                  alignItems: "flex-end",
                }}
              >
                {netPrices?.length === 0 && (
                  <div
                    style={{
                      flexBasis: "85%",

                      padding: "4px",

                      marginTop: "4px",
                      visibility: "hidden",
                      borderWidth: "1px",

                      borderColor: "#465695",

                      borderStyle: "solid",
                    }}
                  />
                )}
                {netPrices?.length > 0 && (
                  <div
                    style={{
                      flexBasis: "85%",

                      padding: "4px",

                      marginTop: "4px",

                      borderWidth: "1px",

                      borderColor: "#465695",

                      borderStyle: "solid",
                    }}
                  >
                    <h5
                      style={{
                        marginBottom: "4px",

                        fontSize: "20px",

                        lineHeight: "28px",

                        textAlign: "center",
                      }}
                    >
                      {" "}
                      Net Prices
                    </h5>

                    <div
                      style={{
                        display: "flex",

                        flexWrap: "wrap",

                        gap: "4px",

                        width: "100%",
                      }}
                    >
                      {netPrices?.map(({ date, price }, index) => {
                        return (
                          <p
                            key={index}
                            style={{
                              fontSize: "10px",

                              flexBasis: "30%",
                            }}
                          >
                            Date:{" "}
                            <span
                              style={{
                                color: "#000",
                              }}
                            >
                              {" "}
                              {dayjs(date).format("DD-MM-YYYY")}{" "}
                            </span>
                            price:{" "}
                            <span
                              style={{
                                color: "#000",
                              }}
                            >
                              {price?.toFixed(2)} {currency}
                            </span>
                          </p>
                        );
                      })}
                    </div>
                  </div>
                )}

                <div
                  style={{
                    borderWidth: " 1px",

                    borderColor: "#6B7280",

                    width: "128px",

                    height: "128px",

                    flexGrow: 1,

                    borderStyle: "solid",
                  }}
                  className="w-32 h-32 border border-gray-500 grow"
                >
                  <img src={qrcode} alt={url} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div key={"image"} className="flex justify-center items-center w-full">
          <img src={path} alt="Converted Voucher" />
        </div>
      )}
    </div>
  );
});

export default VoucherImage;
