import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Loading from "../../organisms/Loading/Loading";
import Navbar from "../../organisms/Navbar/Navbar";

function BookingSuccess() {
  const { shortId } = useParams();

  const { isLoading, data: bookings } = useQuery({
    queryKey: ["booking", shortId],
    queryFn: () => {
      return fetchDataQuery("/booking/api/v1/get-booked-rooms/group/?short_id=" + shortId);
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />
    </>
  );
}

export default BookingSuccess;
