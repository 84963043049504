import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { DatePicker, Select, Input, Button } from "antd";
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa6";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import CountryPriceSelector from "./optionsComponents/CountryPriceSelector";
import CityPriceSelector from "./optionsComponents/CityPriceSelector";
import RoomPriceSelector from "./optionsComponents/RoomPriceSelector";
import HotelPriceSelector from "./optionsComponents/HotelPriceSelector";
import dayjs from "dayjs";
import * as Yup from "yup";

const validationSchemaInitial = Yup.object({
  name: Yup.string().required("Name is required"),
  name_ar: Yup.string().required("Arabic Name is required"),
  markup_tool_price: Yup.array().of(
    Yup.object({
      type_price: Yup.string().required("Price Type is required"),
      market: Yup.string().required("Market is required"),
      user_category: Yup.string().required("User Category is required"),
      min_nights: Yup.number()
        .required("Min Nights is required")
        .positive("Min Nights must be a positive number")
        .integer("Min Nights must be an integer")
        .min(1, "Min Nights must be at least 1"),
    }),
  ),
});

const { Option } = Select;
const { RangePicker } = DatePicker;
const fetchCountries = () => fetchDataQuery("/hotels/api/v1/get-hotel-countries/");
const fetchCities = () => fetchDataQuery("/hotels/api/v1/get-hotel-cities/");
const fetchHotels = (city) => fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${city}]`);
const fetchRooms = (hotel) =>
  hotel && fetchDataQuery(`/en/rooms/api/v1/get-rooms-in-hotel/${hotel ? `${hotel}/` : ""}`);
const FormComponent = ({ initialValues, handleSubmit, isPending, update }) => {
  const [markupTypeData, setmarkupTypes] = useState([]);
  const [marketPriceData, setmarketPrice] = useState([]);
  const [userGroupData, setUserGroup] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [chosenCity, setChosenCity] = useState([]);
  const [chosenHotel, setChosenHotel] = useState(null);
  const [hotelData, setHotelData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const { data: countries, isSuccess: countrySuccess } = useQuery({
    queryKey: ["countries"],
    queryFn: fetchCountries,
  });
  useEffect(() => {
    if (countrySuccess && countries && Array.isArray(countries)) {
      setCountryData(countries);
    }
  }, [countrySuccess, countries]);
  const countryOptions = useMemo(() => countryData?.map((country) => country), [countryData]);
  const { data: cities, isSuccess: citySuccess } = useQuery({
    queryKey: ["citiesData"],
    queryFn: fetchCities,
  });
  useEffect(() => {
    if (citySuccess && cities) {
      setCityData(cities);
    }
  }, [citySuccess, cities]);

  const cityOptions = useMemo(() => cityData?.map((city) => city), [cityData]);

  const {
    data: hotels,
    isSuccess: hotelSuccess,
    refetch: refetchHotels,
  } = useQuery({
    queryKey: ["hotels", chosenCity],
    queryFn: () => fetchHotels(chosenCity),
    // enabled: !!chosenCity,
  });

  useEffect(() => {
    if (hotelSuccess && hotels) {
      setHotelData(hotels);
    }
  }, [hotelSuccess, hotels]);
  useEffect(() => {
    if (chosenCity) {
      refetchHotels();
    }
  }, [chosenCity, refetchHotels]);
  const {
    data: rooms,
    isSuccess: roomSuccess,
    isLoading: loadingRooms,
    refetch: refetchRooms,
  } = useQuery({
    queryKey: ["rooms", chosenHotel],
    queryFn: () => fetchRooms(chosenHotel),
    // enabled: !!chosenHotel,
  });
  useEffect(() => {
    if (roomSuccess && rooms) {
      setRoomData(rooms);
    }
  }, [roomSuccess, rooms]);
  useEffect(() => {
    if (chosenHotel) {
      refetchRooms();
    }
  }, [chosenHotel, refetchRooms]);
  const { data: markupType, isSuccess: typeSuccess } = useQuery({
    queryKey: ["markup-type"],
    queryFn: () => fetchDataQuery("/markup/api/markup-types/"),
  });
  useEffect(() => {
    if (typeSuccess && markupType) {
      setmarkupTypes(markupType);
    }
  }, [markupType, typeSuccess]);
  const { data: marketprice, isSuccess: marketSuccess } = useQuery({
    queryKey: ["market-price"],
    queryFn: () => fetchDataQuery("/site-settings/api/market"),
  });
  useEffect(() => {
    if (marketSuccess && marketprice) {
      setmarketPrice(marketprice);
    }
  }, [marketprice, marketSuccess]);
  const { data: userGroup, isSuccess: userGroupSuccess } = useQuery({
    queryKey: ["userGroup"],
    queryFn: () => fetchDataQuery("/permissions/api/v1/user-groups/"),
  });
  useEffect(() => {
    if (userGroupSuccess && userGroup) {
      setUserGroup(userGroup);
    }
  }, [userGroup, userGroupSuccess]);
  const checkDateOverlap = (selectedDates, existingDates) => {
    const [selectedStart, selectedEnd] = selectedDates.map((date) => dayjs(date));
    return existingDates.some(({ from_date, to_date }) => {
      const existingStart = dayjs(from_date);
      const existingEnd = dayjs(to_date);
      return selectedStart.isBefore(existingEnd) && selectedEnd.isAfter(existingStart);
    });
  };
  const transformInitialValues = (initialValues) => {
    if (!initialValues || !initialValues.markup_tool_price) return {};
    return {
      markup_tool_price: initialValues.markup_tool_price?.map((price) => {
        const options = [];
        if (price.price) {
          options.push({
            type: "all",
            all: true,
            price: price.price || "",
          });
        }
        if (price.price_room || price.rooms.length > 0) {
          options.push({
            type: "rooms",
            rooms: price.rooms || [],
          });
        }
        if (price.country_price_markup_tool?.length > 0) {
          options.push({
            type: "country",
            country_price_markup_tool: price.country_price_markup_tool || [],
          });
        }
        if (price.hotel_price_markup_tool?.length > 0) {
          options.push({
            type: "hotels",
            hotel_price_markup_tool: price.hotel_price_markup_tool || [],
          });
        }
        if (price.city_price_markup_tool?.length > 0) {
          options.push({
            type: "city",
            city_price_markup_tool: price.city_price_markup_tool || [],
          });
        }

        return {
          ...price,
          options, // Include the generated options array
        };
      }),
    };
  };
  const transformedValues = transformInitialValues(initialValues);
  const transformedInitialValues = transformedValues?.markup_tool_price?.map((i) => i);
  const initialFormValues = {
    name: initialValues?.name || "",
    name_ar: initialValues?.name_ar || "",
    markup_tool_price: transformedInitialValues, // This is now correct as it references
  };

  const initialRow = {
    market: null,
    user_category: null,
    rooms: [],
    all: false,
    optionType: "",
    type_price: "",
    price: "",
    price_room: "",
    min_nights: null,
    from_date: null,
    to_date: null,
    hotel_price_markup_tool: [],
    country_price_markup_tool: [],
    city_price_markup_tool: [],
  };
  return (
    initialFormValues.markup_tool_price?.length > 0 && (
      <Formik initialValues={initialFormValues} onSubmit={handleSubmit} validationSchema={validationSchemaInitial}>
        {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
          <Form className="formPage-wrap" autocomplete="off">
            <div className="input-par p-4 bg-gray-100 rounded-lg mb-4">
              <div className="input-chil">
                <div className="mb-4">
                  <label className="block text-sm font-medium">Name</label>
                  <Field name="name" as={Input} placeholder="Name" />
                  {touched.name && errors.name && <div className="text-xs text-red-500">{errors.name}</div>}
                </div>
              </div>

              <div className="input-chil">
                <div className="mb-4">
                  <label className="block text-sm font-medium">Arabic Name</label>
                  <Field name="name_ar" as={Input} placeholder="Arabic Name" />
                  {touched.name_ar && errors.name_ar && <div className="text-xs text-red-500">{errors.name_ar}</div>}
                </div>
              </div>
            </div>
            <FieldArray name="markup_tool_price">
              {({ remove, push }) => (
                <>
                  {values?.markup_tool_price?.map((form, index) => (
                    <div key={index} className="p-4 bg-gray-100 rounded-lg mb-4">
                      {/* Date Range Picker */}
                      <div className="mb-4">
                        <label className="block text-sm font-medium">Date Period (From - To)</label>
                        <RangePicker
                          value={form.from_date && form.to_date ? [dayjs(form.from_date), dayjs(form.to_date)] : []}
                          onChange={(dates) => {
                            if (!dates || checkDateOverlap(dates, values?.markup_tool_price)) {
                              setFieldValue(`markup_tool_price[${index}].from_date`, null);
                              setFieldValue(`markup_tool_price[${index}].to_date`, null);
                              toast.error("The selected date range overlaps with another one.");
                            } else {
                              const [start, end] = dates;
                              const formatDate = (date) => (date ? date.format("YYYY-MM-DD") : null);
                              setFieldValue(`markup_tool_price[${index}].from_date`, formatDate(start));
                              setFieldValue(`markup_tool_price[${index}].to_date`, formatDate(end));
                            }
                          }}
                          className="w-full"
                        />
                        <div className="flex w-full flex-wrap justify-between">
                          {touched?.markup_tool_price?.[index]?.from_date &&
                          errors?.markup_tool_price?.[index]?.from_date ? (
                            <div className="text-xs flex-1 text-red-500">
                              {errors?.markup_tool_price?.[index]?.from_date}
                            </div>
                          ) : null}

                          {touched?.markup_tool_price?.[index]?.to_date &&
                          errors?.markup_tool_price?.[index]?.to_date ? (
                            <div className="text-xs flex-1 text-red-500">
                              {errors?.markup_tool_price?.[index]?.to_date}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Price Type, Market, Category, Min Nights */}
                      <div className="input-par">
                        <div className="mb-4 input-chil">
                          <label className="block text-sm font-medium">Price Type</label>
                          <Field
                            as={Select}
                            name={`markup_tool_price[${index}].type_price`}
                            placeholder="Select Price Type"
                            className="w-full"
                            value={values.markup_tool_price[index].type_price}
                            onChange={(priceType) => setFieldValue(`markup_tool_price[${index}].type_price`, priceType)}
                          >
                            {markupTypeData?.map((val, idx) => (
                              <Option key={idx} value={val}>
                                {val}
                              </Option>
                            ))}
                          </Field>
                          {touched?.markup_tool_price?.[index]?.type_price &&
                          errors?.markup_tool_price?.[index]?.type_price ? (
                            <div className="text-xs text-red-500">{errors?.markup_tool_price?.[index]?.type_price}</div>
                          ) : null}
                        </div>
                        <div className="mb-4 input-chil">
                          <label className="block text-sm font-medium">Market</label>
                          <Field
                            as={Select}
                            name={`markup_tool_price[${index}].market`}
                            placeholder="Select Market"
                            className="w-full"
                            value={values.markup_tool_price[index].market}
                            onChange={(market) => setFieldValue(`markup_tool_price[${index}].market`, market)}
                          >
                            {marketPriceData?.map((val, idx) => (
                              <Option key={idx} value={val.id}>
                                {val?.name}
                              </Option>
                            ))}
                          </Field>
                          {touched?.markup_tool_price?.[index]?.market && errors?.markup_tool_price?.[index]?.market ? (
                            <div className="text-xs text-red-500">{errors?.markup_tool_price?.[index]?.market}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="input-par">
                        <div className="mb-4 input-chil">
                          <label className="block text-sm font-medium">User Category</label>
                          <Field
                            as={Select}
                            name={`markup_tool_price[${index}].user_category`}
                            placeholder="Select User Category"
                            className="w-full"
                            value={values.markup_tool_price[index].user_category}
                            onChange={(category) =>
                              setFieldValue(`markup_tool_price[${index}].user_category`, category)
                            }
                          >
                            {userGroupData?.map((val, idx) => (
                              <Option key={idx} value={val.id}>
                                {val?.name}
                              </Option>
                            ))}
                          </Field>
                          {touched?.markup_tool_price?.[index]?.user_category &&
                          errors?.markup_tool_price?.[index]?.user_category ? (
                            <div className="text-xs text-red-500">
                              {errors?.markup_tool_price?.[index]?.user_category}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-4 input-chil">
                          <label className="block text-sm font-medium">Min Nights</label>
                          <Field
                            type="number"
                            name={`markup_tool_price[${index}].min_nights`}
                            placeholder="Min Nights"
                            className="w-full p-1 rounded-md border px-2"
                            onChange={(e) => setFieldValue(`markup_tool_price[${index}].min_nights`, e.target.value)}
                            onBlur={() => setFieldTouched(`markup_tool_price[${index}].min_nights`, true)}
                          />
                          {touched?.markup_tool_price?.[index]?.min_nights &&
                          errors?.markup_tool_price?.[index]?.min_nights ? (
                            <div className="text-xs text-red-500">{errors?.markup_tool_price?.[index]?.min_nights}</div>
                          ) : null}
                        </div>
                      </div>
                      <FieldArray
                        name={`markup_tool_price[${index}].options`}
                        render={(arrayHelpers) => (
                          <div>
                            {form?.options?.map((option, idx) => {
                              const selectedType = option?.type || "";
                              const availableOptions = ["all", "country", "city", "hotels", "rooms"].filter((type) => {
                                return (
                                  !form?.options?.some((opt, optIdx) => opt.type === type && optIdx !== idx) ||
                                  selectedType === type
                                );
                              });

                              const onRemoveOption = (removeIndex) => {
                                const removedOption = form.options[removeIndex];

                                // Remove option from the array
                                arrayHelpers.remove(removeIndex);

                                // Update options without the removed option
                                const updatedOptions = form.options.filter((_, i) => i !== removeIndex);
                                setFieldValue(`markup_tool_price[${index}].options`, updatedOptions);

                                // If 'all' option is removed, reset price field
                                if (removedOption.type === "all") {
                                  setFieldValue(`markup_tool_price[${index}].price`, "");
                                }
                                if (removedOption.type === "city") {
                                  setFieldValue(`markup_tool_price[${index}].city_price_markup_tool`, []);
                                }
                                if (removedOption.type === "country") {
                                  setFieldValue(`markup_tool_price[${index}].country_price_markup_tool`, []);
                                }
                                if (removedOption.type === "hotel") {
                                  setFieldValue(`markup_tool_price[${index}].hotel_price_markup_tool`, []);
                                }
                                if (removedOption.type === "rooms") {
                                  setFieldValue(`markup_tool_price[${index}].rooms`, []);
                                }

                                // If an option type is removed, also reset the 'name' field
                                setFieldValue(`markup_tool_price[${index}].name`, ""); // Reset name field outside the options
                              };

                              return (
                                <div key={idx} className="bg-gray-50 border p-2 my-1 rounded-md">
                                  <Field
                                    as={Select}
                                    name={`markup_tool_price[${index}].options[${idx}].type`}
                                    placeholder="Select Option Type"
                                    className="w-full"
                                    value={selectedType}
                                    onChange={(value) => {
                                      setFieldValue(`markup_tool_price[${index}].options[${idx}].type`, value);
                                      const resetFields = {
                                        country: `markup_tool_price[${index}].options[${idx}].country_price_markup_tool`,
                                        city: `markup_tool_price[${index}].options[${idx}].city_price_markup_tool`,
                                        hotels: `markup_tool_price[${index}].options[${idx}].hotel_price_markup_tool`,
                                        rooms: `markup_tool_price[${index}].options[${idx}].rooms`,
                                      };
                                      Object.keys(resetFields).forEach((key) => {
                                        if (key === value) {
                                          setFieldValue(resetFields[key], []);
                                        }
                                      });
                                    }}
                                  >
                                    {availableOptions.map((type) => (
                                      <Option key={type} value={type}>
                                        {type.charAt(0).toUpperCase() + type.slice(1)}
                                      </Option>
                                    ))}
                                  </Field>
                                  {selectedType === "country" && (
                                    <CountryPriceSelector index={index} countryOptions={countryOptions} />
                                  )}
                                  {selectedType === "city" && (
                                    <CityPriceSelector index={index} cityOptions={cityOptions} />
                                  )}
                                  {selectedType === "hotels" && (
                                    <HotelPriceSelector
                                      index={index}
                                      cityOptions={cityOptions}
                                      hotelData={hotelData}
                                      setHotelData={setHotelData}
                                      setChosenCity={setChosenCity}
                                    />
                                  )}
                                  {selectedType === "rooms" && (
                                    <RoomPriceSelector
                                      index={index}
                                      roomData={roomData}
                                      setFieldValue={setFieldValue}
                                    />
                                  )}
                                  {selectedType === "all" && (
                                    <div className="mb-4">
                                      <label className="block text-sm font-medium">Price</label>
                                      <Field
                                        type="number"
                                        name={`markup_tool_price[${index}].price`}
                                        placeholder="Price"
                                        className="w-full p-1 rounded-md border px-2"
                                      />
                                    </div>
                                  )}
                                  <div className="text-right my-2">
                                    {form?.options?.length > 1 && (
                                      <button
                                        type="button"
                                        className="text-white bg-red-500 px-2 py-1 rounded-md"
                                        onClick={() => onRemoveOption(idx)}
                                      >
                                        Remove Option
                                      </button>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="bg-green-500 px-2 py-1 rounded-md text-white mt-4"
                                onClick={() => arrayHelpers.push({ type: "" })}
                              >
                                Add New Option
                              </button>
                            </div>
                          </div>
                        )}
                      />

                      {values.markup_tool_price?.length > 1 && (
                        <button
                          type="button"
                          className="bg-red-500 w-max px-1.5 py-1 rounded-md text-white"
                          onClick={() => remove(index)}
                        >
                          Remove Form
                        </button>
                      )}
                    </div>
                  ))}
                  {/* Add New Form Button */}
                  <button
                    type="button"
                    className="bg-teal-600 text-white px-2 flex gap-2 items-center py-1.5 rounded-md mt-4"
                    onClick={() => push({ ...initialRow })}
                  >
                    <FaPlus className="mr-2" /> Add New Form
                  </button>
                </>
              )}
            </FieldArray>
            <div className="flex justify-center items-center mt-6">
              {update ? (
                <button disabled={isPending} type="submit" className="updateButton">
                  {isPending ? "Updating..." : "Update Markup"}
                </button>
              ) : (
                <button disabled={isPending} type="submit" className="addButton">
                  {isPending ? "Creating..." : "Create Markup"}
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    )
  );
};
export default FormComponent;
