import { Field, useFormikContext, ErrorMessage } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Select, Input } from "antd";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";

const { Option } = Select;

const fetchCities = () => fetchDataQuery("/hotels/api/v1/get-hotel-cities/");
const fetchHotels = (city) => city && fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${city}]`);
const fetchRooms = (hotel) =>
  hotel && fetchDataQuery(`/en/rooms/api/v1/get-rooms-in-hotel/${hotel ? `${hotel}/` : ""}`);

const RoomPriceSelector = ({ index }) => {
  const [chosenCity, setChosenCity] = useState(null);
  const [chosenHotel, setChosenHotel] = useState(null);
  const [allSelected, setAllSelected] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [hotelId, setHotelId] = useState();
  const [hotelIdVal, setHotelIdVal] = useState();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const RoomsIDS = values?.markup_tool_price[index]?.hotel
    ? values?.markup_tool_price[index]?.hotel
    : values?.markup_tool_price[index]?.rooms;

  // Set initial values if data exists
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      const currentData = values.markup_tool_price[index]?.rooms || [];
      if (currentData.length > 0) {
        const hotelOptions = currentData.map((i) => ({
          label: i?.hotel_name || "Unknown Hotel",
          value: i?.hotel_id || null,
        }));

        setChosenHotel(hotelOptions);
        setHotelId(currentData.map((i) => i?.hotel_id));
      }
      setInitialized(true);
    }
  }, [index, initialized, values.markup_tool_price]);

  // Fetch cities
  const {
    data: cities,
    isSuccess: citySuccess,
    isLoading: loadingCities,
    error: citiesError,
  } = useQuery({
    queryKey: ["cities"],
    queryFn: fetchCities,
    onSuccess: (data) => setCityData(data || []),
  });

  // Fetch hotels
  const {
    data: hotels,
    isSuccess: hotelSuccess,
    isLoading: loadingHotels,
    error: hotelsError,
  } = useQuery({
    queryKey: ["hotels", chosenCity],
    queryFn: () => fetchHotels(chosenCity?.value ? chosenCity?.value : chosenCity),
    enabled: !!chosenCity,
  });

  const HotelID = hotelId && hotelId?.length > 0 ? hotelId[0] : chosenHotel;

  // Fetch rooms
  const {
    data: rooms,
    isSuccess: roomSuccess,
    isLoading: loadingRooms,
    error: roomError,
  } = useQuery({
    queryKey: ["rooms", (chosenHotel?.length > 0 && chosenHotel[0]?.value) || chosenHotel],
    queryFn: () => fetchRooms((chosenHotel?.length > 0 && chosenHotel[0]?.value) || chosenHotel),
    enabled: !chosenHotel?.length > 0 || !!chosenHotel[0]?.value || !!chosenHotel,
  });

  const fetchDefaultCity = (hotels) =>
    hotels?.length > 0 && fetchDataQuery(`/hotels/api/v1/get-hotel-cities/?hotels=${hotels}`);
  const fetchHotelRoom = (hotel) =>
    hotel && hotel.length > 0 && fetchDataQuery(`/en/rooms/api/v1/room-detail-id/${hotel ? `${hotel}/` : ""}`);

  const {
    data: defaultCity,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ["defaultCity", hotelId?.length > 0 ? hotelId : HotelID],
    queryFn: () => fetchDefaultCity(hotelId?.length > 0 ? hotelId : HotelID),
    enabled: !hotelId?.length > 0 || !!HotelID,
  });

  const {
    data: hotelRoom,
    isSuccess: hotelRoomSuccess,
    isLoading: hotelRoomLoading,
  } = useQuery({
    queryKey: ["roomHotel", RoomsIDS[0]?.room_id || RoomsIDS[0] || RoomsIDS?.value || RoomsIDS],
    queryFn: () => fetchHotelRoom(RoomsIDS[0]?.room_id || RoomsIDS[0] || RoomsIDS?.value || RoomsIDS),
    enabled: !!RoomsIDS[0]?.room_id || !!RoomsIDS[0] || !!RoomsIDS?.value || !!RoomsIDS,
  });
  useEffect(() => {}, [chosenHotel]);
  const hotelOptions = useMemo(() => hotels?.map((hotel) => ({ label: hotel.name, value: hotel.id })), [hotels]);

  const hotelName = hotelRoom ? hotelRoom.hotel_name : null;
  const hotelVal = hotelOptions?.find((option) => option.label === hotelName);

  const findHotelIdByName = (hotelName) => {
    const hotel = hotelOptions?.find((option) => option.label === hotelName);
    if (hotel) {
      setHotelIdVal(hotel.value); // Assign the hotel ID to hotel_idVal
    } else {
      setHotelIdVal(null); // Handle case where hotel is not found
    }
  };

  useEffect(() => {
    if (hotelName) {
      findHotelIdByName(hotelName); // Call the function with the correct hotel name
    }
  }, [hotelName, findHotelIdByName]);

  useEffect(() => {
    if (isSuccess && defaultCity) {
      setChosenCity({ label: defaultCity.map((i) => i.name), value: defaultCity.map((i) => i.id) });
    }
  }, [defaultCity, isSuccess, setChosenCity]);

  // Memoized options
  const cityOptions = useMemo(() => cities?.map((city) => ({ label: city.name, value: city.id })), [cities]);

  const roomOptions = useMemo(() => {
    if (!rooms) return [];
    return [{ label: "All", value: "all" }, ...rooms.map((room) => ({ label: room.room_name, value: room.id }))];
  }, [rooms]);

  // Handlers for resetting values
  const handleCityChange = (value, form) => {
    setChosenCity(value);
    setFieldValue(`markup_tool_price[${index}].city`, value);
    if (value !== chosenCity) {
      setFieldValue(`markup_tool_price[${index}].hotel`, null);
      setFieldValue(`markup_tool_price[${index}].rooms`, []);
      setFieldValue(`markup_tool_price[${index}].price_room`, null);
    }
    setAllSelected(false);
  };

  const handleHotelChange = (value, form) => {
    const selectedHotel = hotelOptions?.find((option) => option.value === value);

    setChosenHotel(selectedHotel.value); // Update chosenHotel with the selected option
    setFieldValue(`markup_tool_price[${index}].hotel`, selectedHotel); // Set Formik's value

    if (value !== chosenHotel?.value) {
      setFieldValue(`markup_tool_price[${index}].rooms`, []); // Clear rooms on hotel change
      setFieldValue(`markup_tool_price[${index}].price_room`, null); // Clear room price
    }

    setAllSelected(false); // Reset 'all selected' state
  };

  const handleRoomChange = (selectedValues, form) => {
    if (selectedValues.includes("all")) {
      if (allSelected) {
        form.setFieldValue(`markup_tool_price[${index}].rooms`, []);
        setAllSelected(false);
      } else {
        const allRoomData = rooms.map((room) => ({
          room_id: room.id,
          room_name: room.room_name,
          hotel_id: chosenHotel.value,
          hotel_name: chosenHotel.label,
        }));
        form.setFieldValue(`markup_tool_price[${index}].rooms`, allRoomData);
        setAllSelected(true);
      }
    } else {
      const updatedRooms = selectedValues?.map((roomId) => {
        const room = rooms?.find((r) => r?.id === roomId);
        return {
          room_id: room?.id,
          room_name: room?.room_name,
          hotel_id: chosenHotel?.value,
          hotel_name: chosenHotel?.label,
        };
      });
      form.setFieldValue(`markup_tool_price[${index}].rooms`, updatedRooms);
      setAllSelected(false);
    }
  };

  return (
    <>
      {/* City Selector */}
      <div className="mb-4">
        <label htmlFor={`markup_tool_price[${index}].city`} className="block text-sm font-medium">
          Select City
        </label>
        <Field name={`markup_tool_price[${index}].city`}>
          {({ field, form }) => (
            <Select
              id={`markup_tool_price[${index}].city`}
              placeholder="Select city"
              className="w-full"
              value={field.value || chosenCity}
              onChange={(value) => handleCityChange(value, form)}
              onBlur={() => form.setFieldTouched(field.name, true)}
              loading={loadingCities}
              options={cityOptions}
            />
          )}
        </Field>
        <ErrorMessage name={`markup_tool_price[${index}].city`} component="div" className="text-xs text-red-500" />
      </div>

      {/* Hotel Selector */}
      <div className="mb-4">
        <label htmlFor={`markup_tool_price[${index}].hotel`} className="block text-sm font-medium">
          Select Hotel
        </label>
        <Field name={`markup_tool_price[${index}].hotel`}>
          {({ field, form }) => (
            <Select
              id={`markup_tool_price[${index}].hotel`}
              placeholder="Select hotel"
              className="w-full"
              value={field.value || chosenHotel}
              onChange={(value) => handleHotelChange(value, form)}
              onBlur={() => form.setFieldTouched(field.name, true)}
              loading={loadingHotels}
              options={hotelOptions}
            />
          )}
        </Field>
        <ErrorMessage name={`markup_tool_price[${index}].hotel`} component="div" className="text-xs text-red-500" />
      </div>

      {/* Room Selector */}
      <div className="mb-4">
        <label htmlFor={`markup_tool_price[${index}].rooms`} className="block text-sm font-medium">
          Select Room(s)
        </label>
        <Field name={`markup_tool_price[${index}].rooms`}>
          {({ field, form }) => {
            const initialValue = field.value?.map((room) => ({ label: room.room_name, value: room.room_id }));

            return (
              <Select
                id={`markup_tool_price[${index}].rooms`}
                mode="multiple"
                placeholder="Select rooms"
                className="w-full"
                value={initialValue ? initialValue : field.value || []}
                onChange={(value) => handleRoomChange(value, form)}
                onBlur={() => form.setFieldTouched(field.name, true)}
                loading={loadingRooms}
                options={roomOptions}
              />
            );
          }}
        </Field>
        <ErrorMessage name={`markup_tool_price[${index}].rooms`} component="div" className="text-xs text-red-500" />
      </div>

      {/* Price Input for Rooms */}
      <div className="mb-4">
        <label htmlFor={`markup_tool_price[${index}].price_room`} className="block text-sm font-medium">
          Price per Room
        </label>
        <Field name={`markup_tool_price[${index}].price_room`}>
          {({ field, form }) => (
            <Input
              {...field}
              type="number"
              id={`markup_tool_price[${index}].price_room`}
              placeholder="Enter price"
              className="w-full"
              onBlur={() => form.setFieldTouched(field.name, true)}
            />
          )}
        </Field>
        <ErrorMessage
          name={`markup_tool_price[${index}].price_room`}
          component="div"
          className="text-xs text-red-500"
        />
      </div>
    </>
  );
};

export default RoomPriceSelector;
