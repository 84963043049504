import React, { useEffect, useState } from "react";
import "./MultiDynamicInputs.scss";
import { BiMinusCircle } from "react-icons/bi";
import { MdAddCircleOutline } from "react-icons/md";

export default function MultiDynamicInputs({
  heading,
  inputsList,
  inputsNum,
  className = "",
  extraInputsLengthList = [],
  extraInputsComponent,
}) {
  const [input, setInput] = useState(Array.from({ length: inputsNum }, () => 1));
  const [extraInputsLengths, setExtraInputsLengths] = useState(() =>
    extraInputsLengthList.length ? [...extraInputsLengthList] : Array(inputsNum).fill(1),
  );

  useEffect(() => {
    const newInputArray = Array.from({ length: inputsNum }, () => 1);
    setInput(newInputArray);

    if (extraInputsLengthList.length > 0) {
      setExtraInputsLengths([...extraInputsLengthList]);
    } else {
      setExtraInputsLengths(Array(inputsNum).fill(1));
    }
  }, [inputsNum]);

  function addInput() {
    if (input.length < 100) {
      setInput([...input, 1]);
      setExtraInputsLengths([...extraInputsLengths, 1]); // Add one child input by default
    }
  }

  function removeInput() {
    if (input.length > 0) {
      setInput(input.slice(0, -1));
      setExtraInputsLengths(extraInputsLengths.slice(0, -1));
    }
  }

  function updateExtraInputsLength(index, newLength) {
    const updatedLengths = [...extraInputsLengths];
    updatedLengths[index] = newLength;
    setExtraInputsLengths(updatedLengths);
  }

  return (
    <div className="w-full flex flex-col gap-y-2">
      <div className="w-full flex items-center justify-start gap-x-3">
        <p className="text-slate-800 font-semibold">{heading}</p>
        <div className="flex items-center gap-x-1">
          <button
            type="button"
            onClick={addInput}
            className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800"
          >
            <MdAddCircleOutline className="w-5 h-5" />
          </button>
          <button
            type="button"
            onClick={removeInput}
            className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800"
          >
            <BiMinusCircle className="w-5 h-5" />
          </button>
        </div>
      </div>
      {input.map((_, index) => (
        <React.Fragment key={index}>
          {/* Parent row */}
          <div className={`w-full flex items-center justify-between border rounded-md py-3 px-10 ${className}`}>
            {inputsList.map((inputComponent, i) => (
              <React.Fragment key={i}>{inputComponent}</React.Fragment>
            ))}
            {/* Child rows */}
            {extraInputsComponent && (
              <div className="w-full flex flex-col gap-y-2 pl-10">
                {extraInputsComponent(extraInputsLengths[index], (newLength) =>
                  updateExtraInputsLength(index, newLength),
                )}
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
