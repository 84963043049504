import React from "react";
import CreateMarkupForm from "./CreateMarkupForm";
import { toast } from "react-toastify";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const initialFormValues = {
  market: null,
  user_category: null,
  rooms: [],
  all: false,
  optionType: "",
  type_price: "",
  price: "",
  price_room: "",
  min_nights: null,
  from_date: null,
  to_date: null,
  hotel_price_markup_tool: [],
  country_price_markup_tool: [],
  city_price_markup_tool: [],
};

const CreateMarkup = () => {
  const navigate = useNavigate();

  const {
    mutate: createMarkup,
    isLoading,
    isPending,
  } = useMutation({
    mutationFn: (data) => {
      return mutateDataQuery("/markup/api/", "post", data, {
        "Content-Type": "application/json",
      });
    },
    mutationKey: "markup",
    networkMode: "always",
    retry: false,
    onSuccess: (res) => {
      toast.success("Markup Created Successfully");
      navigate(`/dashboard/markup-control/markups-list`);
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
        });
        return;
      }
      toast.error(error.message || "Failed to Create Markup");
    },
  });
  const handleSubmit = async (values) => {
    try {
      const formattedValues = {
        ...values,
        markup_tool_price: values.markup_tool_price?.map((item) => ({
          ...item,
          hotel_price_markup_tool: item?.hotel_price_markup_tool.map((entry) => ({
            ...entry,
            hotel: entry.hotel.id ? entry.hotel.id : entry.hotel,
          })),
          rooms: item?.rooms?.map((entry) => entry.room_id),
          city_price_markup_tool: item.city_price_markup_tool.map((entry) => ({
            ...entry,
            city: entry.city.id ? entry.city.id : entry.city, // Ensure country is always an ID
          })), // Ensure country is always an ID
        })),
      };
      // Send the data to the API
      await createMarkup(formattedValues);
    } catch (error) {
      toast.error(error.message || "Failed to create Markup");
    }
  };

  return <CreateMarkupForm initialValues={initialFormValues} handleSubmit={handleSubmit} isPending={isPending} />;
};

export default CreateMarkup;
