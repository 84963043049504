import React, { useEffect, useState } from "react";
import "../AddHotelForm/AddHotel.scss";
import { useNavigate, useParams } from "react-router";
import useData from "../../../customHooks/useData";
import { useFormik } from "formik";
import { FaStar, FaUndoAlt, FaWpforms } from "react-icons/fa";
import Select from "react-select";
import RepeatComponent from "../../molecules/RepeatComponent/RepeatComponent";
import { editHotelValidationSchema } from "./editHotelValidationSchema";
import { Rating } from "react-simple-star-rating";
import GoogleMap from "../../molecules/GoogleMap/GoogleMap";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { IoAdd } from "react-icons/io5";
import { editFormData, getData } from "../../../redux/actions/sendDataAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import TopBarProgress from "react-topbar-progress-indicator";
import Loading from "../Loading/Loading";
import { returnSVG } from "./EditHotelForm";
import { TiDelete } from "react-icons/ti";
import { ImVideoCamera } from "react-icons/im";
import PopUp from "../PopUp/PopUp";
import FileUploader from "../FileUploader/FileUploader";
import { Checkbox } from "antd";

export default function EditHotel() {
  const { id } = useParams();
  const hotel = useData(`/hotels/api/v1/get-put-delete-hotels/${id}`);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // fetched data to be used in the form
  const features = useData("/hotels/api/v1/get-features/");
  const countries = useData("site-settings/api/countries-flags");
  const extraInfo = useData("/hotels/api/v1/hotel-extra-info");
  const extraInfArr = extraInfo ? extraInfo.map((info) => ({ label: info.name, value: info.id })) : [];

  //states
  const [cities, setCities] = useState([]);
  const [citiesLoad, setCitiesLoad] = useState(false);
  const [areas, setAreas] = useState([]);
  const [areasLoad, setAreasLoad] = useState(false);
  const [loadingArea, setLoadingArea] = useState(false);
  const [chosenCountry, setChosenCountry] = useState(null);
  const [chosenCity, setChosenCity] = useState(null);
  const [chosenArea, setChosenArea] = useState(null);
  const [chosenFeatures, setChosenFeatures] = useState(null);
  const [chosenExtra, setChosenExtra] = useState(null);
  const [loading, setLoading] = useState(false);

  const countriesArr = countries
    ? countries.map((country) => ({ label: country.name, value: country.id, countryId: country.country }))
    : [];
  const citiesArr = cities.map((city) => ({ label: city.name, value: city.id }));
  const areasArr = areas.map((area) => ({ label: area.name, value: area.id }));
  const featuresArr = features ? features.map((feature) => ({ label: feature.feature, value: feature.id })) : [];
  const [deletedImgs, setDeletedImgs] = useState([]);
  const [deletedVideos, setDeletedVideos] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [header, setHeader] = useState();
  const [imgToggle, setImgToggle] = useState();
  const [videoToggle, setVideoToggle] = useState();

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (hotel) {
      dispatch(
        getData(
          `/en/hotels/api/v1/get-cities/${hotel._country}/`,
          (resp) => {
            if (resp.status === 200) {
              setCities(resp.data);
              setCitiesLoad(true);
            }
          },
          (err) => {
            toast.error("there was an error");
          },
        ),
      );
      dispatch(
        getData(
          `/en/hotels/api/v1/city-areas/${hotel.city}/`,
          (resp) => {
            if (resp.status === 200) {
              setAreas(resp.data);
              setAreasLoad(true);
            }
          },
          (err) => {
            toast.error("there was an error");
          },
        ),
      );
    }
  }, [hotel]);

  useEffect(() => {
    if (hotel && cities.length > 0 && areas.length > 0) {
      setFieldValue("name", hotel.name);
      setFieldValue("name_ar", hotel.name_ar);
      setFieldValue("_country", hotel._country);
      setFieldValue("city", hotel.city_id);
      setFieldValue("area", hotel.area_id);
      setChosenCountry({ label: hotel._country_name, value: hotel._country });
      setChosenCity({ label: hotel.city, value: hotel.city_id });
      setChosenArea({ label: hotel.area, value: hotel.area_id });
      setFieldValue("address", hotel.address);
      setFieldValue("address_ar", hotel.address_ar);
      setFieldValue("internet", hotel.internet);
      setFieldValue("payment_type", hotel.payment_type);
      setFieldValue("description", hotel.description);
      setFieldValue("description_ar", hotel.description_ar);
      setFieldValue("rate", Number(hotel.rate));
      setFieldValue("telephone", Object.values(hotel.telephone));
      setFieldValue("reservationTelephone", Object.values(hotel.reservation_telephone));
      setFieldValue("reservationEmail", Object.values(hotel.reservation_email));
      setFieldValue("receptionEmail", Object.values(hotel.reception_email));
      setFieldValue("restaurants", Object.values(hotel.restaurants));
      setFieldValue("sendPriceInMail", hotel.send_price_in_mail);
      setFieldValue("sendPriceInVoucher", hotel.send_price_in_voucher);
      setFieldValue(
        "features",
        hotel.features.map((feature) => feature.feature_name),
      );
      setChosenFeatures(hotel.features.map((feature) => ({ label: feature.feature_name, value: feature.feature_id })));
      setFieldValue(
        "extra_info",
        hotel.extra_info.map((info) => info.id),
      );
      setChosenExtra(hotel.extra_info.map((info) => ({ label: info.name, value: info.id })));
      setMapData({ lat: Number(hotel.latitude), lng: Number(hotel.longitude) });
      setFieldValue("hotelMapAddress", hotel.hotel_map_address);
    }
  }, [hotel, citiesLoad, areasLoad]);

  const [mapData, setMapData] = useState({ lat: 30.0444, lng: 31.2357 });
  const [cityTimeZone, setCityTimeZone] = useState(null);

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
    setFieldValue,
    setSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
      name_ar: "",
      _country: "",
      city: "",
      area: "",
      address: "",
      address_ar: "",
      hotelMapAddress: "",
      payment_type: "",
      internet: "",
      description: "",
      description_ar: "",
      telephone: [""],
      reservationTelephone: [""],
      reservationEmail: [""],
      receptionEmail: [""],
      restaurants: [""],
      images: [],
      videos: [],
      rate: null,
      features: [],
      sendPriceInMail: true,
      sendPriceInVoucher: true,
    },
    validationSchema: editHotelValidationSchema,
    onSubmit: submit,
  });

  function submit(values) {
    const {
      images,
      videos,
      features,
      extra_info,
      telephone,
      reservationTelephone,
      reservationEmail,
      receptionEmail,
      restaurants,
      hotelMapAddress,
      sendPriceInVoucher,
      sendPriceInMail,
      ...restOfValues
    } = values;

    if (images.length > 0) {
      for (const image of images) {
        if (!image.valid) {
          toast.error(`Image ${image.name} is not valid because ${image.errors[0]}`);
          setSubmitting(false);
          return;
        }
      }
    }

    if (videos.length > 0) {
      for (const video of videos) {
        if (!video.valid) {
          toast.error(`Video ${video.name} is not valid because ${video.errors[0]}`);
          setSubmitting(false);
          return;
        }
      }
    }

    const formData = new FormData();
    features.forEach((feature) => formData.append("features", feature));
    extra_info.forEach((info) => formData.append("extra_info", info));
    images.forEach((image) => formData.append("images", image.file));
    videos.forEach((video) => formData.append("videos", video.file));
    Object.keys(restOfValues).forEach((item) => formData.append(`${item}`, restOfValues[item]));
    formData.append("latitude", mapData.lat);
    formData.append("longitude", mapData.lng);
    formData.append("hotel_map_address", hotelMapAddress);
    formData.append("telephone", JSON.stringify(Object.assign({}, telephone)));
    formData.append("reservation_telephone", JSON.stringify(Object.assign({}, reservationTelephone)));
    formData.append("reservation_email", JSON.stringify(Object.assign({}, reservationEmail)));
    formData.append("reception_email", JSON.stringify(Object.assign({}, receptionEmail)));
    formData.append("restaurants", JSON.stringify(Object.assign({}, restaurants)));
    formData.append("send_price_in_voucher", sendPriceInVoucher);
    formData.append("send_price_in_mail", sendPriceInMail);
    deletedImgs.forEach((img) => formData.append("delete_images", img));
    deletedVideos.forEach((video) => formData.append("delete_videos", video));

    dispatch(
      editFormData(
        `/en/hotels/api/v1/get-put-delete-hotels/${id}/`,
        formData,
        (resp) => {
          toast.success("Your Hotel was edited successfully");
          setSubmitting(false);
          navigate(`/dashboard/hotel-list/hotel-details/${resp.data.id}`);
        },
        (error) => {
          toast.error("There was an error In your Hotel Please try again later");
          setSubmitting(false);
        },
      ),
    );
  }

  function getCities(country) {
    setLoading(true);
    setChosenCity(null);
    setChosenArea(null);
    setFieldValue("city", "");
    setFieldValue("area", "");
    dispatch(
      getData(
        `/en/hotels/api/v1/get-cities/${country}/`,
        (resp) => {
          if (resp.data.length === 0) {
            toast.error("This Country has no Cities in the system Please choose another one");
          }
          setCities(resp.data);
          setLoading(false);
        },
        (err) => {
          toast.error("there was an error");
          setLoading(false);
        },
      ),
    );
  }

  function getAreas(city) {
    setLoadingArea(true);
    setChosenArea(null);
    setFieldValue("area", "");
    dispatch(
      getData(
        `/en/hotels/api/v1/city-areas/${city}/`,
        (resp) => {
          if (resp.data.length === 0) {
            toast.error("This City has no Areas Please choose another one");
          }
          setAreas(resp.data);
          setLoadingArea(false);
        },
        (err) => {
          toast.error("there was an error");
          setLoadingArea(false);
        },
      ),
    );
  }

  function removeImg(e, imgObj) {
    // check if the user deleted all images to make the image input required
    // check if the user deleted all images to make the image haninput required
    // the state here will reflect the state right before changing, so we must add 1
    if (deletedImgs.length + 1 === hotel.images.length && (!values.images || values.images.length === 0)) {
      toast.error("you can't delete all images unless you append an image first");
    } else {
      const svg = returnSVG(e.target);
      svg.style.display = "none";
      svg.nextSibling.style.display = "block";
      setDeletedImgs([...deletedImgs, imgObj.image_id]);
    }
  }

  function undoRemoveImg(e, imgObj) {
    const svg = returnSVG(e.target);
    svg.style.display = "none";
    svg.previousSibling.style.display = "block";
    const deletedImgsArr = deletedImgs.slice(0);
    setDeletedImgs(
      deletedImgsArr.filter((id) => {
        return id !== imgObj.image_id;
      }),
    );
  }

  function removeVideo(e, videoObj) {
    // check if the user deleted all images to make the image input required
    // the state here will reflect the state right before changing, so we must add 1
    if (deletedImgs.length + 1 === hotel.videos.length && (!values.videos || values.videos.length === 0)) {
      toast.error("you can't delete all videos unless you append a video first");
    } else {
      const svg = returnSVG(e.target);
      svg.style.display = "none";
      svg.nextSibling.style.display = "block";
      setDeletedVideos([...deletedVideos, videoObj.video_id]);
    }
  }

  function undoRemoveVideo(e, videoObj) {
    const svg = returnSVG(e.target);
    svg.style.display = "none";
    svg.previousSibling.style.display = "block";
    const deletedVideosArr = deletedVideos.slice(0);
    setDeletedVideos(
      deletedVideosArr.filter((id) => {
        return id !== videoObj.video_id;
      }),
    );
  }

  return (
    <div className="formPage-wrap">
      {(!features || !countries || !hotel) && (
        <div className="w-full flex items-center justify-center h-screen">
          <TopBarProgress /> <Loading />
        </div>
      )}
      {features && countries && hotel && extraInfArr && (
        <div className=" w-full ">
          <div className=" flex justify-center ">
            <h2 className="header-h2">
              <FaWpforms className="w-5 h-5" />
              Edit Hotel
            </h2>
          </div>
          <form onSubmit={handleSubmit} className="flex flex-col gap-y-2 items-center justify-center form-wrap">
            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Hotel Name
            </label>
            <input
              placeholder="Hotel Name"
              name="name"
              type="text"
              value={values.name}
              className={`w-full px-3 h-10  border rounded-lg bg-white ${
                errors.name && touched.name && "border-red-900"
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.name && touched.name && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.name}</div>
            )}

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Hotel Arabic Name
            </label>
            <input
              placeholder="Hotel Arabic Name"
              name="name_ar"
              type="text"
              value={values.name_ar}
              className={`w-full px-3 h-10  border rounded-lg bg-white ${
                errors.name_ar && touched.name_ar && "border-red-900"
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.name_ar && touched.name_ar && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.name_ar}</div>
            )}

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Country
            </label>
            <Select
              name="_country"
              options={countriesArr}
              isSearchable
              value={chosenCountry}
              className={`w-full  h-10  rounded-lg  bg-white ${
                errors._country && touched._country && "border border-red-900"
              }`}
              placeholder="Country"
              onChange={(e) => {
                setChosenCountry(e);
                getCities(e.value);
                setFieldValue("_country", e.countryId);
              }}
              onBlur={handleBlur}
            />
            {errors._country && touched._country && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors._country}</div>
            )}
            {loading ? (
              <div className="w-full flex items-center  justify-center">
                <ButtonLoading />
              </div>
            ) : (
              values._country && (
                <>
                  <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                    City
                  </label>
                  <Select
                    name="city"
                    options={citiesArr}
                    isSearchable
                    value={chosenCity}
                    className={`w-full  h-10  rounded-lg  bg-white ${
                      errors.city && touched.city && "border border-red-900"
                    }`}
                    placeholder="City"
                    onChange={(e) => {
                      const chosenCity = cities.find((city) => city.id === e.value);
                      const timezone = chosenCity.timezone;
                      setCityTimeZone(timezone);

                      if (timezone) {
                        getAreas(e.label);
                      } else {
                        setChosenArea(null);
                        setFieldValue("area", null);
                        setAreas([]);
                      }

                      setChosenCity(e);
                      setFieldValue("city", e.value);
                    }}
                    onBlur={handleBlur}
                  />
                  {errors.city && touched.city && (
                    <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.city}</div>
                  )}
                </>
              )
            )}
            {loadingArea ? (
              <div className="w-full flex items-center  justify-center">
                <ButtonLoading />
              </div>
            ) : values.city && areasArr.length > 0 ? (
              <>
                <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
                  Area
                </label>
                <Select
                  name="area"
                  options={areasArr}
                  isSearchable
                  value={chosenArea}
                  className={`w-full  h-10  rounded-lg  bg-white ${
                    errors.area && touched.area && "border border-red-900"
                  }`}
                  placeholder="Area"
                  onChange={(e) => {
                    setChosenArea(e);
                    setFieldValue("area", e.value);
                  }}
                  onBlur={handleBlur}
                />
                {errors.area && touched.area && (
                  <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.area}</div>
                )}
              </>
            ) : (
              values.city &&
              (cityTimeZone ? (
                <div className="input-chil text-center flex justify-center items-center rounded-md md:px-4 p-2 gap-x-2 text-gray-700 bg-gray-300 h-10 mt-6 ">
                  <p className="font-semibold text-center ">No Area For This City , Please Select Another City</p>
                </div>
              ) : (
                <div className="input-chil text-center flex justify-center items-center rounded-md md:px-4 p-2 gap-x-2 text-gray-700 bg-gray-300 h-10 mt-6 ">
                  <p className="font-semibold text-center ">No Timezone For This City , Please Add it</p>
                </div>
              ))
            )}

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Address
            </label>
            <input
              placeholder="Address"
              name="address"
              type="text"
              value={values.address}
              className={`w-full px-3 h-10  border rounded-lg bg-white ${
                errors.address && touched.address && "border-red-900"
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.address && touched.address && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.address}</div>
            )}

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Arabic Address
            </label>
            <input
              placeholder="Arabic Address"
              name="address_ar"
              value={values.address_ar}
              type="text"
              className={`w-full px-3 h-10  border rounded-lg bg-white ${
                errors.address_ar && touched.address_ar && "border-red-900"
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.address_ar && touched.address_ar && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.address_ar}</div>
            )}

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Internet
            </label>
            <input
              placeholder="Internet"
              name="internet"
              value={values.internet}
              type="text"
              className={`w-full px-3 h-10  border rounded-lg bg-white ${
                errors.internet && touched.internet && "border-red-900"
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.internet && touched.internet && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.internet}</div>
            )}

            <label className="text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Payment Type
            </label>
            <div className="flex items-center justify-center gap-x-10 w-full">
              <div className="flex items-center justify-center gap-x-3 w-[50%]">
                <label className="text-gray-600 text-lg">Cash</label>
                <input
                  placeholder="Employee Name"
                  name="payment_type"
                  type="radio"
                  value="cash"
                  checked={values.payment_type === "cash"}
                  className=" w-5 h-5  border rounded-lg bg-white "
                  onChange={(e) => setFieldValue("payment_type", e.target.value)}
                  onBlur={handleBlur}
                />
              </div>
              <div className="flex items-center justify-center gap-x-3 w-[50%]">
                <label className="text-gray-600 text-lg">Credit</label>
                <input
                  placeholder="Total Amount"
                  name="payment_type"
                  type="radio"
                  value="credit"
                  checked={values.payment_type === "credit"}
                  className=" w-5 h-5  border rounded-lg bg-white font-semibold"
                  onChange={(e) => setFieldValue("payment_type", e.target.value)}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            {errors.payment_type && touched.payment_type && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.payment_type}</div>
            )}

            <div className="w-full">
              <label className="text-center w-full text-gray-600 font-semibold flex items-center  ">Send Price</label>
              <div className={`flex items-center justify-center gap-x-10 w-full border rounded-md h-10 bg-white`}>
                <div className="flex items-center justify-center gap-x-3 w-[50%]">
                  <Checkbox
                    checked={values.sendPriceInMail}
                    onChange={(e) => {
                      setFieldValue("sendPriceInMail", e.target.checked);
                    }}
                  />
                  <label className="text-gray-600 text-lg">Email</label>
                </div>
                <div className="flex items-center justify-center gap-x-3 w-[50%]">
                  <Checkbox
                    checked={values.sendPriceInVoucher}
                    onChange={(e) => {
                      setFieldValue("sendPriceInVoucher", e.target.checked);
                    }}
                  />
                  <label className="text-gray-600 text-lg">Voucher</label>
                </div>
              </div>
            </div>

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Telephone
            </label>
            <RepeatComponent
              state={values.telephone}
              setState={setFieldValue}
              name="telephone"
              errors={touched.telephone && errors.telephone}
              number={values.telephone.length}
            >
              {(index) => (
                <input
                  placeholder="Telephone"
                  name="telephone"
                  type="text"
                  value={values.telephone[index]}
                  className={`w-full px-3 h-10  border rounded-lg bg-white ${
                    errors.telephone && touched.telephone && "border-red-900"
                  }`}
                  onChange={(e) => {
                    let oldState = [...values.telephone];
                    oldState[index] = e.target.value;
                    setFieldValue("telephone", oldState);
                  }}
                  onBlur={handleBlur}
                />
              )}
            </RepeatComponent>

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Reservation Telephone
            </label>
            <RepeatComponent
              state={values.reservationTelephone}
              setState={setFieldValue}
              name="reservationTelephone"
              errors={touched.reservationTelephone && errors.reservationTelephone}
              number={values.reservationTelephone.length}
            >
              {(index) => (
                <input
                  placeholder="Reservation Telephone"
                  name="reservationTelephone"
                  type="text"
                  className={`w-full px-3 h-10  border rounded-lg bg-white ${
                    errors.reservationTelephone && touched.reservationTelephone && "border-red-900"
                  }`}
                  value={values.reservationTelephone[index]}
                  onChange={(e) => {
                    let oldState = [...values.reservationTelephone];
                    oldState[index] = e.target.value;
                    setFieldValue("reservationTelephone", oldState);
                  }}
                  onBlur={handleBlur}
                />
              )}
            </RepeatComponent>

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Reservation Email
            </label>
            <RepeatComponent
              state={values.reservationEmail}
              setState={setFieldValue}
              name="reservationEmail"
              errors={touched.reservationEmail && errors.reservationEmail}
              number={values.reservationEmail.length}
            >
              {(index) => (
                <input
                  placeholder="Reservation Email"
                  name="reservationEmail"
                  type="text"
                  value={values.reservationEmail[index]}
                  className={`w-full px-3 h-10  border rounded-lg bg-white ${
                    errors.reservationEmail && touched.reservationEmail && "border-red-900"
                  }`}
                  onChange={(e) => {
                    let oldState = [...values.reservationEmail];
                    oldState[index] = e.target.value;
                    setFieldValue("reservationEmail", oldState);
                  }}
                  onBlur={handleBlur}
                />
              )}
            </RepeatComponent>

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Reception Email
            </label>
            <RepeatComponent
              state={values.receptionEmail}
              setState={setFieldValue}
              name="receptionEmail"
              errors={touched.receptionEmail && errors.receptionEmail}
              number={values.receptionEmail.length}
            >
              {(index) => (
                <input
                  placeholder="Reception Email"
                  name="receptionEmail"
                  type="text"
                  value={values.receptionEmail[index]}
                  className={`w-full px-3 h-10  border rounded-lg bg-white ${
                    errors.receptionEmail && touched.receptionEmail && "border-red-900"
                  }`}
                  onChange={(e) => {
                    let oldState = [...values.receptionEmail];
                    oldState[index] = e.target.value;
                    setFieldValue("receptionEmail", oldState);
                  }}
                  onBlur={handleBlur}
                />
              )}
            </RepeatComponent>

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Restaurants
            </label>
            <RepeatComponent
              state={values.restaurants}
              setState={setFieldValue}
              name="restaurants"
              errors={touched.restaurants && errors.restaurants}
              number={values.restaurants.length}
            >
              {(index) => (
                <input
                  placeholder="Restaurants"
                  name="restaurants"
                  type="text"
                  value={values.restaurants[index]}
                  className={`w-full px-3 h-10  border rounded-lg bg-white ${
                    errors.restaurants && touched.restaurants && "border-red-900"
                  }`}
                  onChange={(e) => {
                    let oldState = [...values.restaurants];
                    oldState[index] = e.target.value;
                    setFieldValue("restaurants", oldState);
                  }}
                  onBlur={handleBlur}
                />
              )}
            </RepeatComponent>

            <label className="flex text-gray-600 font-semibold">Description</label>
            <textarea
              name="description"
              className={`w-full p-3 border rounded-lg bg-white ${
                errors.description && touched.description && "border-red-900"
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
            />
            {errors.description && touched.description && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.description}</div>
            )}

            <label className="flex text-gray-600 font-semibold">Arabic Description</label>
            <textarea
              name="description_ar"
              className={`w-full p-3 border rounded-lg bg-white ${
                errors.description_ar && touched.description_ar && "border-red-900"
              }`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description_ar}
            />
            {errors.description_ar && touched.description_ar && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">
                {errors.description_ar}
              </div>
            )}

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Features
            </label>
            <Select
              name="features"
              options={featuresArr}
              isSearchable
              className={`w-full  h-10  rounded-lg  bg-white ${
                errors.features && touched.features && "border border-red-900"
              }`}
              isMulti
              placeholder="Features"
              value={chosenFeatures}
              onChange={(selectedOptions) => {
                setChosenFeatures(selectedOptions);
                const updatedFeatures = selectedOptions.map((option) => option.label);

                setFieldValue("features", updatedFeatures);
              }}
              onBlur={handleBlur}
            />
            {errors.features && touched.features && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.features}</div>
            )}
            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Extra Info
            </label>
            <Select
              name="extra_info"
              options={extraInfArr}
              value={chosenExtra}
              isSearchable
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderColor: `${errors.extra_info && touched.extra_info && "rgb(135 27 27)"}`,
                }),
              }}
              className={`w-full  h-10  rounded-lg  bg-white capitalize `}
              isMulti
              placeholder="Hotel Extra Info"
              onChange={(selectedOptions) => {
                setChosenExtra(selectedOptions);
                const updatedExtra = selectedOptions.map((option) => option.value);
                setFieldValue("extra_info", updatedExtra);
              }}
              onBlur={handleBlur}
            />
            {errors.extra_info && touched.extra_info && (
              <div className="w-full flex justify-start text-xs capitalize text-red-800 font-semibold">
                {errors.extra_info}
              </div>
            )}

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Upload Images
            </label>
            <div
              className={` w-full  border rounded-md   bg-white ${
                errors.images && touched.images && "border border-red-900"
              }`}
            >
              <FileUploader
                accept={"image/*"}
                label={"Drag and drop hotel images here or click to browse"}
                files={values.images}
                setFiles={(files) => {
                  setFieldValue("images", files);
                }}
              />
            </div>
            {errors.images && touched.images && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.images}</div>
            )}

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Upload Videos
            </label>
            <div className="w-full mb-5 border rounded-md">
              <FileUploader
                accept={"video/*"}
                label={"Drag and drop hotel videos here or click to browse"}
                maxFileSize={50}
                files={values.videos}
                setFiles={(files) => {
                  setFieldValue("videos", files);
                }}
              />
            </div>
            {errors.videos && touched.videos && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.videos}</div>
            )}

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Rating
            </label>
            <div className="w-full flex items-center rating border rounded-md h-10 p-4">
              <Rating
                onClick={(rate) => setFieldValue("rate", rate)}
                iconsCount={5}
                size={10}
                fillIcon={<FaStar />}
                emptyIcon={<FaStar />}
                initialValue={values.rate}
              />
            </div>
            {errors.rate && touched.rate && (
              <div className="w-full flex justify-start text-xs text-red-800 font-semibold">{errors.rate}</div>
            )}
            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Old Images
            </label>
            <div className="w-full grid grid-cols-5 gap-2 border rounded-md overflow-auto py-4">
              {hotel.images.map((imgObj, index) => {
                return (
                  <div key={index} className="w-full p-4">
                    <TiDelete className="w-5 h-5 text-red-800 cursor-pointer" onClick={(e) => removeImg(e, imgObj)} />
                    <FaUndoAlt style={{ display: "none" }} onClick={(e) => undoRemoveImg(e, imgObj)} />
                    <img
                      src={`${imgObj.image_url}`}
                      className="w-24 h-24 cursor-pointer"
                      onClick={() => {
                        setIsOpen(true);
                        setHeader("Hotel Image");
                        setImgToggle(imgObj.image_url);
                        setVideoToggle();
                      }}
                    />
                  </div>
                );
              })}
            </div>

            <label className=" text-center w-full text-gray-600 font-semibold flex items-center justify-center">
              Old Videos
            </label>
            <div className="w-full flex gap-2 border rounded-md p-4">
              {hotel.videos.length > 0 ? (
                hotel.videos.map((videoObj, index) => {
                  return (
                    <div key={index}>
                      <TiDelete
                        className="w-5 h-5 text-red-800 cursor-pointer"
                        onClick={(e) => removeVideo(e, videoObj)}
                      />
                      <FaUndoAlt style={{ display: "none" }} onClick={(e) => undoRemoveVideo(e, videoObj)} />
                      <video
                        className="cursor-pointer"
                        src={videoObj.video_url}
                        width={90}
                        onClick={() => {
                          setIsOpen(true);
                          setHeader("Hotel Video");
                          setVideoToggle(videoObj.video_url);
                          setImgToggle();
                        }}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="w-full flex items-center justify-center gap-x-2 text-gray-700 bg-gray-300 h-10 mt-3 ">
                  <p className="font-semibold  ">No Videos For This Hotel , Please Select Videos For This Hotel</p>
                  <ImVideoCamera className="w-5 h-5" />
                </div>
              )}
            </div>
            <div className="w-full">
              <GoogleMap
                getLocationData={(data) => setMapData(data)}
                defaultProps={{
                  center: mapData,
                  zoom: 15,
                }}
                formikSet={setFieldValue}
                searchValue={values.hotelMapAddress}
              />
            </div>

            <div className="flex justify-center mt-6">
              <button type="Submit" disabled={isSubmitting} className="updateButton">
                Edit Hotel{" "}
                {isSubmitting ? (
                  <>
                    <ButtonLoading /> <TopBarProgress />
                  </>
                ) : (
                  <IoAdd className="w-5 h-5" />
                )}
              </button>
            </div>
          </form>
          {loading && (
            <>
              <TopBarProgress />
            </>
          )}
        </div>
      )}
      <PopUp close={closeModal} isOpen={isOpen} image={imgToggle} video={videoToggle && videoToggle} header={header} />
    </div>
  );
}
