import React from "react";
import { Select } from "antd";
import { ErrorMessage, Field, useFormikContext } from "formik";
const { Option } = Select;

const CountryPriceSelector = ({ countryOptions, index }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium">Select Country</label>
      <Field
        name={`markup_tool_price[${index}].country_price_markup_tool`}
        render={({ field, form }) => {
          const normalizeCountry = (country) =>
            typeof country === "object" && country !== null ? country.id : country;

          // Extract selected countries as an array of IDs
          const selectedCountries = (field.value || []).map((item) => normalizeCountry(item.country));
          // Ensure each entry in `countryPrices` has `id` and `price`
          const countryPrices = (field.value || []).map((item) => ({
            country: normalizeCountry(item.country),
            price: item.price || "",
          }));

          // Handle price change for a specific country
          const handlePriceChange = (countryId, price) => {
            const updatedPrices = countryPrices.map((item) => (item.country === countryId ? { ...item, price } : item));
            setFieldValue(field.name, updatedPrices);
          };

          // Set the same price for all selected countries
          const setPriceForAll = (price) => {
            const updatedPrices = countryPrices.map((item) => ({
              ...item,
              price,
            }));
            setFieldValue(field.name, updatedPrices);
          };

          return (
            <>
              <Select
                mode="multiple"
                placeholder="Select Country"
                className="w-full"
                value={selectedCountries} // Always an array of IDs
                onChange={(value) => {
                  let updatedValue;
                  if (value.includes("all")) {
                    if (selectedCountries.includes("all") || selectedCountries.length === countryOptions.length) {
                      // Deselect "All"
                      updatedValue = [];
                    } else {
                      // Select all countries
                      updatedValue = countryOptions.map((country) => country.id);
                    }
                  } else {
                    updatedValue = value;
                  }

                  const newCountryPrices = updatedValue.map((countryId) => {
                    const existingEntry = countryPrices.find((item) => item.country === countryId);
                    return existingEntry || { country: countryId, price: "" };
                  });

                  setFieldValue(field.name, newCountryPrices);
                }}
                onBlur={() => form.setFieldTouched(field.name, true)}
              >
                <Option key="all" value="all">
                  All
                </Option>
                {countryOptions.map((val) => (
                  <Option key={val.id} value={val.id}>
                    {val.name}
                  </Option>
                ))}
              </Select>

              <div className="mt-4  flex flex-col gap-2">
                {countryPrices.map(({ country, price }) => {
                  const selectedCountry = countryOptions.find((option) => option.id === country);

                  if (!selectedCountry) return null;

                  return (
                    <div key={country} className="flex input-par items-center bg-gray-50 p-2 rounded-md">
                      <span className="input-chil">{selectedCountry.name}</span>
                      <div className="input-chil gap-2 flex">
                        <input
                          type="number"
                          className="border rounded-md p-1 flex-1"
                          placeholder="Price"
                          value={price}
                          onChange={(e) => handlePriceChange(country, e.target.value)}
                        />
                        <button
                          type="button"
                          className="px-2 py-1 md:text-md text-sm w-max bg-blue-500 text-white rounded"
                          onClick={() => setPriceForAll(price)}
                        >
                          Fixed Price
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        }}
      />
      <ErrorMessage
        name={`markup_tool_price[${index}].country_price_markup_tool`}
        component="div"
        className="text-xs text-red-500"
      />
    </div>
  );
};

export default CountryPriceSelector;
