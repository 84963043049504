import React from "react";
import { Collapse } from "antd";
import { Link } from "react-router-dom";
import { IoEye } from "react-icons/io5";
import BookingEmailDetails from "./BookingEmailDetails";
import { useTranslation } from "react-i18next";

function BookingEmails({ bookings }) {
  let bookingDetails = bookings?.bookings;
  const shortId = bookings?.short_id;
  const [t] = useTranslation();
  const localeCode = t("localeCode");
  const direction = localeCode.startsWith("ar") ? "rtl" : "ltr";

  if (bookings) {
    if (typeof bookings.bookings === "object" && typeof bookings.bookings.length === "undefined") {
      bookingDetails = [bookings.bookings];
    }
  }

  const items = bookingDetails?.map((booking, index) => {
    return {
      forceRender: true,
      key: index,
      label: (
        <div dir={direction} className="flex justify-between items-center">
          <p>
            <strong>{t("booking")}:</strong> {booking?.id}
          </p>
          <div>
            <Link to={`/dashboard/booking-list-single-id/${shortId}/${booking.id}`} target={"_blank"}>
              <IoEye className="w-6 h-6" />
            </Link>
          </div>
        </div>
      ),
      children: <BookingEmailDetails booking={booking} />,
      panelStyle: {
        marginBottom: "24px",
      },
    };
  });

  return bookingDetails?.length > 0 ? (
    <Collapse
      direction={direction}
      className={" max-w-[1200px] mx-auto "}
      items={items}
      defaultActiveKey={bookingDetails?.map((booking, index) => index)}
    />
  ) : null;
}

export default BookingEmails;
