import React from "react";
import "./DashClockCard.scss";
import Clock from "react-live-clock";
import { BsClockHistory } from "react-icons/bs";

export default function DashClockCard(props) {
  const { country, timezone, bg } = props.props;
  return (
    <div className={`flex flex-col justify-center items-center px-2 gap-2 py-3  shadow-md rounded-md w-full`}>
      <div className=" flex items-center justify-start gap-x-1 text-sm ">
        <BsClockHistory className="md:w-6 md:h-6 w-5 h-5" />
        <span className=" font-bold text-gray-600">{country}</span>
      </div>

      <Clock
        format={"HH:mm:ss"}
        ticking={true}
        timezone={timezone}
        className="md:text-3xl text-2xl font-semibold text-gray-800 "
      />
    </div>
  );
}
