import React, { useEffect, useState } from "react";
import MarkupForm from "./MarkupForm";
import { toast } from "react-toastify";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Loading from "../Loading/Loading";

const UpdateMarkup = () => {
  const [markupData, setMarkupData] = useState(null); // Start with null to show loading state
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: markup,
    error,
    isSuccess,
    isLoading: markupLoading,
  } = useQuery({
    queryKey: ["markupdata", id],
    queryFn: () => fetchDataQuery(`/markup/api/${id}/`),
  });

  // Set markupData once the query is successful
  useEffect(() => {
    if (isSuccess && markup) {
      setMarkupData(markup);
    }
  }, [markup, isSuccess]);

  const {
    mutate: updateMarkup,
    isLoading,
    isPending,
  } = useMutation({
    mutationFn: async (data) => {
      return await mutateDataQuery(`/markup/api/${id}/`, "patch", data, {
        "Content-Type": "application/json",
      });
    },
    mutationKey: ["markup", id],
    networkMode: "always",
    retry: false,
    onSuccess: (res) => {
      toast.success("Markup Updated Successfully");
      navigate(`/dashboard/markup-control/markups-list`);
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
        });
      } else {
        const fallbackMessage = error.message || "Something went wrong while updating the markup.";
        toast.error(fallbackMessage);
      }
    },
  });
  // Format initial form values once the markupData is available
  const initialFormValues = markupData
    ? {
        name: markupData?.name,
        name_ar: markupData?.name_ar,
        markup_tool_price: markupData?.markup_tool_price?.map((price) => ({
          id: price?.id,
          market_id: price.market?.id,
          market: price.market?.name,
          user_category_id: price.user_category?.id,
          user_category: price.user_category?.name,
          price: price.price,
          rooms:
            price.rooms.length > 0
              ? price.rooms?.map((room) => ({
                  room_id: room.id,
                  room_name: room.room_name,
                  hotel_id: room.hotel_name.id,
                  hotel_name: room.hotel_name.name,
                }))
              : [],
          country_price_markup_tool:
            price.country_price_markup_tool?.length > 0 ? price?.country_price_markup_tool?.map((price) => price) : [],
          hotel_price_markup_tool:
            price.hotel_price_markup_tool?.length > 0 ? price.hotel_price_markup_tool?.map((price) => price) : [],
          city_price_markup_tool:
            price.city_price_markup_tool?.length > 0 ? price.city_price_markup_tool?.map((price) => price) : [],
          all: price.price ? true : false,
          type_price: price.type_price,
          price_room: price.price_room,
          min_nights: price.min_nights,
          from_date: price.from_date,
          to_date: price.to_date,
        })),
      }
    : null; // If markupData is not available, set initialValues to null

  const handleSubmit = async (values) => {
    try {
      // Map over the markup_tool_price to replace country and other nested objects with their IDs
      const formattedValues = {
        ...values,
        markup_tool_price: values.markup_tool_price?.map((item) => ({
          ...item,
          hotel_price_markup_tool: item?.hotel_price_markup_tool.map((entry) => ({
            ...entry,
            hotel: entry.hotel.id ? entry.hotel.id : entry.hotel,
          })),
          city_price_markup_tool: item?.city_price_markup_tool.map((entry) => ({
            ...entry,
            city: entry.city.id ? entry.city.id : entry.city,
          })),
          rooms: item?.rooms?.map((entry) => (entry?.room_id ? entry?.room_id : entry)),
          country_price_markup_tool: item.country_price_markup_tool.map((entry) => ({
            ...entry,
            country: entry.country.id ? entry.country.id : entry.country, // Ensure country is always an ID
          })), // Ensure country is always an ID
          market: item.market, // Handle market ID transformation
          user_category: item.user_category, // Replace user_category with its ID
        })),
      };

      // Send the data to the API
      await updateMarkup(formattedValues);
    } catch (error) {
      toast.error(error.message || "Failed to update Markup");
    }
  };

  // Render the form only when markupData is available and loading is complete
  if (markupLoading || !markupData) {
    return <Loading />; // Show a loading indicator while data is being fetched
  }
  return (
    <MarkupForm initialValues={initialFormValues} handleSubmit={handleSubmit} isPending={isPending} update={"true"} />
  );
};

export default UpdateMarkup;
