import React from "react";
import { Card } from "antd";
import { GiNightSleep, GiPerson } from "react-icons/gi";
import { CgBoy } from "react-icons/cg";
import { FaCalendarCheck, FaHotel } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { convertNumbersByLocal } from "../../../../utils/numberUtils";

const { Meta } = Card;
const RoomInfoCard = ({ roomInfo, values }) => {
  const [t] = useTranslation();
  const localeCode = t("localeCode");
  const {
    roomImage,
    room_description: roomDescription,
    selectedroom: selectedRoom,
    price,
    currency_type: currencyType,
    nights_whats_app: nights,
    formCheckIn_whats_app: checkIn,
    formCheckOut_whats_app: checkOut,
    hotel, // hotel name,
    persons_data: personsData,
  } = roomInfo;

  return (
    <Card
      className="flex-1"
      cover={<img className="w-full shadow-md rounded-md lg:h-[300px]  " alt={roomDescription} src={roomImage} />}
    >
      <Meta
        className="flex-row-reverse"
        title={<h2 className="flex text-sm lg:text-2xl  font-serif font-bold text-[#002244] ">{selectedRoom} </h2>}
        description={roomDescription}
        avatar={
          <p className="flex items-center  text-md md:text-3xl font-semibold text-[#002244]">
            {convertNumbersByLocal(price + (values.addToPrice || 0), localeCode)}{" "}
            <span className="text-sm text-yellow-600 mt-2">{currencyType}</span>{" "}
          </p>
        }
      />

      <div className=" mx-auto mt-4">
        <div className="w-full h-full flex flex-col items-start text-start  text-black">
          <p className="flex items-center text-xs gap-x-1 lg:text-md font-serif font-semibold">
            <FaHotel className="w-5 h-5 text-yellow-600 font-bold  " />
            {t("Hotels")}: {hotel}
          </p>
          <div className="flex justify-between w-full mt-2">
            <div className=" grid items-center gap-y-3    ">
              <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                <FaCalendarCheck className="w-5 h-5 text-yellow-600 font-bold  " />
                {t("checkIn")}: {convertNumbersByLocal(checkIn, localeCode)}
              </p>
              <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                <FaCalendarCheck className="w-5 h-5 text-yellow-600 font-bold  " />
                {t("checkOut")}: {convertNumbersByLocal(checkOut, localeCode)}
              </p>
              <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                <GiNightSleep className="w-5 h-5 text-yellow-600 " />
                {t("nights")}: {convertNumbersByLocal(nights, localeCode)}
              </p>
              <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                <GiPerson className="w-5 h-5 text-yellow-600 " />
                {t("persons")}: {convertNumbersByLocal(personsData.adults, localeCode)}
              </p>
              <p className="flex items-center gap-x-1 text-xs lg:text-md  font-semibold ">
                <CgBoy className="w-5 h-5 text-yellow-600 " />
                {t("children")}: {convertNumbersByLocal(personsData.children.length, localeCode)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default RoomInfoCard;
