import React from "react";
import { formatDate } from "../../../../../utils/dateUtils";
import AttachmentsIcons from "./AttachementsIcons";
import jwtDecode from "jwt-decode";
import cookie from "react-cookies";

function TicketReply({ reply }) {
  const user = jwtDecode(cookie.load("access_token"));
  const userID = user.user_id;

  const isReplyFromUser = reply?.userId === userID;

  return (
    <div className="px-5">
      <div
        className={`flex gap-4 justify-between items-center  w-full py-6 px-4 shadow-md rounded-2xl ${isReplyFromUser && "bg-[#F3F4F6]"}`}
      >
        <div className="flex gap-4 w-full">
          {reply?.avatar ? (
            <img className="w-12 shrink-0 h-12 rounded-full" src={reply?.avatar} alt="" />
          ) : (
            <img
              className={"w-12 h-12 shrink-0 rounded-full bg-gray-600 object-cover"}
              src="https://image.pngaaa.com/13/1887013-middle.png"
              alt="profile"
            />
          )}
          <div className=" w-full flex flex-col gap-2">
            <div className="space-y-1">
              <h4 className="text-xl font-semibold text-[#0B1931]">{reply?.username}</h4>
              <span className="text-sm text-gray-400"> {formatDate(reply?.createdAt)}</span>
            </div>
            <p dir="rtl" className="text-cyan-950 font-medium text-base">
              <div dangerouslySetInnerHTML={{ __html: reply?.reply }} />
            </p>
          </div>
        </div>
        {reply?.attachments?.length > 0 && (
          <div className="flex flex-col gap-1 justify-center items-center">
            <p className="text-base">Attachments</p>
            <AttachmentsIcons attachments={reply?.attachments} />
          </div>
        )}
      </div>
    </div>
  );
}

export default TicketReply;
