import React, { useCallback, useEffect, useRef, useState } from "react";
import { Timeline } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import { VscLoading } from "react-icons/vsc";
import mutateDataQuery from "../../../../react_query/mutateDataQuery";
import { base64ToFile, getCurrentUserId } from "../../../../utils/utils";
import { toast } from "react-toastify";
import VoucherModal from "./VoucherModal";
import BookingEmailModal from "./BookingEmailModal";
import { useTranslation } from "react-i18next";
import { BsArrowCounterclockwise } from "react-icons/bs";

const BookingEmailDetails = ({ booking }) => {
  const emailAttachmentRef = useRef(null);
  const [isVoucherCreated, setIsVoucherCreated] = useState(false);
  const [t] = useTranslation();
  const localeCode = t("localeCode");
  const direction = localeCode.startsWith("ar") ? "rtl" : "ltr";

  const {
    isLoading: isFetchingEmail,
    data: emailData,
    isError: isFetchingEmailError,
  } = useQuery({
    queryKey: ["booking-email-details", booking?.id],
    queryFn: () => {
      return fetchDataQuery(`/booking/api/v1/email-data/${booking?.id}/`);
    },
  });

  const {
    message,
    reservation_email: reservationEmails,
    reception_email: receptionEmails,
    from_email: fromEmail,
  } = emailData || {};

  const {
    isPending: isSendingEmail,
    mutate: sendEmail,
    isSuccess: isEmailSentSuccess,
    isError: isSendingEmailError,
  } = useMutation({
    mutationKey: ["send-email", booking?.id],
    mutationFn: (emailData) => {
      return mutateDataQuery("/booking/api/v1/send-email/", "post", emailData, {
        "Content-Type": "multipart/form-data",
      });
    },
  });

  const {
    mutate: createVoucher,
    data: voucher,
    isPending: isCreatingVoucher,
    isError: isCreatingVoucherError,
  } = useMutation({
    mutationKey: ["create-voucher", booking?.id],
    mutationFn: (newVoucher) => {
      return mutateDataQuery("/booking/api/v1/e-vouchers/", "post", newVoucher);
    },
  });

  const handleSendEmail = useCallback(() => {
    const recipientEmails = [
      ...reservationEmails.map((email) => Object.values(email)),
      ...receptionEmails.map((email) => Object.values(email)),
    ];

    if (recipientEmails.length === 0) {
      toast.error(`Please add at least one email to reception or reservation of ${booking?.hotel_name} hotel `);
      return;
    }

    let imageFile;
    if (booking?.voucher) {
      const htmlImageSrc = emailAttachmentRef.current.querySelector("img").src;
      imageFile = base64ToFile(htmlImageSrc, "image.png");
    }
    const formData = new FormData();

    formData.append("subject", `Room Reservation ${booking?.client_names[0].english_name}`);
    formData.append("from_email", fromEmail);
    formData.append("sent_by_email", fromEmail);
    formData.append("message", message);
    formData.append("recipient_list", JSON.stringify(...recipientEmails));
    formData.append("hotel_name", booking?.hotel_details?.name);
    formData.append("check_in", booking?.from_date);
    formData.append("check_out", booking?.to_date);
    formData.append("sent_by_username", getCurrentUserId());
    formData.append("booked_room_id", booking?.id);
    formData.append("reason", "");

    if (booking?.voucher) {
      formData.append("attachment", imageFile);
    }
    sendEmail(formData);
  }, [
    booking?.client_names,
    booking?.from_date,
    booking?.hotel_details?.name,
    booking?.hotel_name,
    booking?.id,
    booking?.to_date,
    booking?.voucher,
    fromEmail,
    message,
    receptionEmails,
    reservationEmails,
    sendEmail,
  ]);

  const handleCreatingVoucher = useCallback(() => {
    createVoucher({
      voucher_type: "New Reservation",
      voucher_form: "Auto Voucher",
      hotel_confirmation_number: booking?.hotel_reservation_id,
      system_confirmation_number: booking?.id,
      date: new Date().toISOString().split("T")[0],
      hotel_name: booking?.hotel,
      guest_name: booking?.client_names?.map((name) => name?.english_name),
      number_of_persons: booking?.persons_data?.adults,
      number_of_children: booking?.persons_data?.children?.length,
      children_age: booking?.persons_data?.children || [],
      room_type: booking?.room_name,
      meal_plan: booking?.contract_meal,
      check_in_date: booking?.from_date,
      check_out_date: booking?.to_date,
      currency: booking?.currency_type,
      nationality: booking?.nationality,
      remarks: "",
      booked_room_id: booking?.id,
      issued_by_id: getCurrentUserId(),
      created_by_id: getCurrentUserId(),
      net_price: booking?.net_price,
      total_price: booking?.total_price,
      net_price_json: booking.additional_data.map(({ date, net_price }) => ({
        date,
        price: Number(net_price),
      })),
    });
  }, [
    booking.additional_data,
    booking?.client_names,
    booking?.contract_meal,
    booking?.currency_type,
    booking?.from_date,
    booking?.hotel,
    booking?.hotel_reservation_id,
    booking?.id,
    booking?.nationality,
    booking?.net_price,
    booking?.persons_data?.adults,
    booking?.persons_data?.children,
    booking?.room_name,
    booking?.to_date,
    booking?.total_price,
    createVoucher,
  ]);

  useEffect(() => {
    if (!isFetchingEmail && emailData && !isFetchingEmailError && !voucher && booking.voucher) {
      handleCreatingVoucher();
    }
  }, [
    booking.additional_data,
    booking.client_names,
    booking.contract_meal,
    booking.currency_type,
    booking.from_date,
    booking.hotel,
    booking.hotel_reservation_id,
    booking.id,
    booking.nationality,
    booking.net_price,
    booking.persons_data.adults,
    booking.persons_data.children,
    booking.room,
    booking.room_name,
    booking.to_date,
    booking.total_price,
    booking.voucher,
    createVoucher,
    emailData,
    handleCreatingVoucher,
    isFetchingEmailError,
    isFetchingEmail,
    voucher,
  ]);

  useEffect(() => {
    if (voucher) {
      if (isVoucherCreated) {
        handleSendEmail();
      }
    }
  }, [handleSendEmail, isVoucherCreated, voucher]);

  useEffect(() => {
    if (!isFetchingEmail && emailData && !isFetchingEmailError && !voucher && !booking.voucher) {
      handleSendEmail();
    }
  }, [booking.voucher, emailData, handleSendEmail, isFetchingEmailError, isFetchingEmail, voucher]);

  const STATUS_COLORS = {
    success: "green",
    error: "red",
    loading: "gray",
    default: "gray",
  };

  const items = [
    {
      color: STATUS_COLORS.success,
      children: `${t("bookingCreated")} ` + booking?.id,
    },
    {
      dot: isFetchingEmail ? <VscLoading className={"animate-spin"} /> : null,
      color: isFetchingEmail ? STATUS_COLORS.loading : STATUS_COLORS.success,
      children: t("fetchingEmailData"),
    },
    booking?.voucher && {
      dot: isCreatingVoucher ? <VscLoading className={"animate-spin"} /> : null,
      color: isCreatingVoucher
        ? STATUS_COLORS.loading
        : isCreatingVoucherError
          ? STATUS_COLORS.error
          : STATUS_COLORS.success,
      children: (
        <div className="flex gap-4 items-center">
          <p>{t("creatingVoucher")}</p>
          {isCreatingVoucherError && (
            <BsArrowCounterclockwise className={"w-5 h-5 cursor-pointer"} onClick={handleCreatingVoucher} />
          )}
        </div>
      ),
    },
    booking?.voucher && {
      dot: isCreatingVoucher ? <VscLoading className={"animate-spin"} /> : null,
      color: isCreatingVoucher
        ? STATUS_COLORS.loading
        : isCreatingVoucherError
          ? STATUS_COLORS.error
          : STATUS_COLORS.success,
      children: (
        <div className="flex gap-4 items-center">
          <p>{t("voucherCreated")}</p>
          {voucher && (
            <VoucherModal
              modalFirstOpen={true}
              voucher={
                booking?.hotel_details?.sendPriceInVoucher
                  ? voucher
                  : {
                      ...voucher,
                      net_price_json: [],
                      total_price: 0,
                    }
              }
              isVoucherCreated={isVoucherCreated}
              setIsVoucherCreated={setIsVoucherCreated}
              voucherRef={emailAttachmentRef}
            />
          )}
        </div>
      ),
    },
    {
      color: isSendingEmail
        ? STATUS_COLORS.loading
        : isSendingEmailError
          ? STATUS_COLORS.error
          : isEmailSentSuccess
            ? STATUS_COLORS.success
            : STATUS_COLORS.default,
      dot: isSendingEmail ? <VscLoading className={"animate-spin"} /> : null,
      children: (
        <div className="flex gap-4 items-center">
          <p>{t("sendingEmail")}</p>
          {isSendingEmailError && (
            <BsArrowCounterclockwise className={"w-5 h-5 cursor-pointer"} onClick={handleSendEmail} />
          )}
        </div>
      ),
    },
    {
      color: isSendingEmail
        ? STATUS_COLORS.loading
        : isSendingEmailError
          ? STATUS_COLORS.error
          : isEmailSentSuccess
            ? STATUS_COLORS.success
            : STATUS_COLORS.default,
      children: (
        <div className="flex gap-4 items-center">
          <p>{t("emailSentSuccessfully")}</p>
          {isEmailSentSuccess && <BookingEmailModal email={message} />}
        </div>
      ),
    },
  ];
  return (
    <div dir={direction}>
      <Timeline items={items} />
    </div>
  );
};

export default BookingEmailDetails;
