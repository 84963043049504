import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import Loading from "../Loading/Loading.js";
import { QueryClient, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ChangeSTatus from "./ChangeSTatus";
import usePermissions from "../../../customHooks/usePermissions.js";
import TicketReply from "../ITDepartment/Tickets/ITTicketDetails/TicketReply.js";
import TaskReplayForm from "./TaskReplyForm.jsx";
import { Modal, Button, Select } from "antd";
import AttachmentsIcons from "../ITDepartment/Tickets/ITTicketDetails/AttachementsIcons.js";
import { useFormik } from "formik"; // Assuming you're using Formik for form handling
import UsersSearchTextInputTasks from "./userDepartment/UserDepartment"; // Assuming this component is already implemented
import "./button.css";
import { toast } from "react-toastify";
import mutateDataQuery from "../../../react_query/mutateDataQuery.js";
function stripHTMLTags(input) {
  // Use a regular expression to remove any HTML tags
  return input.replace(/<\/?[^>]+(>|$)/g, "");
}
const ViewTask = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const queryClient = useQueryClient();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [taskData, setTaskData] = useState("");
  const allUserIdsRef = useRef([]);
  useEffect(() => {}, [taskData]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [groupData, setGroupData] = useState([]);
  const {
    data: Task,
    error,
    isSuccess,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["Task", id],
    queryFn: () => fetchDataQuery(`/tasks-management/api/v1/task-assignments/${id}/`),
  });

  useEffect(() => {
    if (isSuccess && Task) {
      setTaskData(Task);
    }
  }, [Task, isSuccess]);

  const {
    data: department,
    error: errorDepartment,
    isSuccess: departmentSuccess,
    isLoadingTask: departmentLoading,
  } = useQuery({
    queryKey: ["department"],
    queryFn: () => fetchDataQuery("/department/api/v1/department"),
  });

  useEffect(() => {
    if (departmentSuccess && department) {
      setGroupData(department.results);
    }
  }, [department, departmentSuccess]);
  const grouOptions = useMemo(() => groupData?.map((user) => ({ label: user.name, value: user.id })), [groupData]);
  const userIds = taskData?.user?.map((i) => i.id);
  const {
    mutate: taskManager,
    isLoading: loading,
    isPending,
  } = useMutation({
    mutationFn: (data, isLoading, isPending) => {
      return mutateDataQuery(`/tasks-management/api/v1/task-assignments/${id}/`, "patch", data, {
        "Content-Type": "multipart/form-data",
      });
    },
    mutationKey: "taskManager",
    networkMode: "always",
    retry: false,
    onSuccess: (res) => {
      toast.success("Added New user to task Successfully");
      queryClient.invalidateQueries(["Task", id]);
      refetch(); // Optional manual refetch
      // navigate(`/dashboard/view-task/${id}`);
      // window.location.reload(); // Refreshes the current page
    },
    onMutate: () => {
      toast.warn("Adding new user...");
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
        });
        return;
      }
      toast.error(error.message || "Failed to add user to this task");
    },
  });

  const formik = useFormik({
    initialValues: {
      department: [],
      user: [],
    },
    // validate: (values) => {
    //   const errors = {};
    //   if (!values.department) {
    //     errors.department = "Department is required";
    //   }
    //   if (!values.user || values.user.length === 0) {
    //     errors.user = "At least one user is required";
    //   }
    //   return errors;
    // },

    onSubmit: (values) => {
      const userIdsToAdd = values.user.map((user) => user);
      allUserIdsRef.current = [...userIds, ...userIdsToAdd];

      const formData = new FormData();
      formData.append("user", JSON.stringify(allUserIdsRef.current));

      taskManager(formData);
      setIsModalVisible(false); // Close modal after submission
    },
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const {
    data: taskComment,
    error: errortaskComment,
    isSuccess: successTaskComment,
    isLoading: taskCommentLoading,
    refetch: refetchRateDetails,
  } = useQuery({
    queryKey: ["taskComment", id],
    queryFn: () => fetchDataQuery(`/tasks-management/api/v1/task-assignments/${id}/comments/`),
  });
  const openModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsModalOpen(true);
  };
  const replies = taskComment?.map((com) => {
    return {
      username: com?.user,
      userId: com?.id,
      avatar: com?.avatar,
      createdAt: com?.created_at,
      reply: com?.comment,
      attachments: com?.attachments && com?.attachments?.map((attachment) => attachment?.file),
    };
  });
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "tasks_management.view_taskassignment",
    "tasks_management.delete_taskassignment",
    "tasks_management.change_taskassignment",
  ]);
  const isEditingOfferAllowed =
    permissions?.find((permission) => permission.name === "tasks_management.change_taskassignment")?.value === true;

  // const {
  //   data: departmentData,
  //   error: departmentErr,
  //   isSuccess: departmentDataSuccess,
  //   isLoadingTask: deparmentLoading,
  // } = useQuery({
  //   queryKey: ["departmentData"],
  //   queryFn: () => fetchDataQuery(`/department/api/v1/department?pagination=false&users=[${userIds}]`),
  //   enabled: userIds?.length > 0,
  // });
  // const departmentOptions = useMemo(
  //   () => departmentData?.map((departmen) => ({ label: departmen.name, value: departmen.id })),
  //   [departmentData],
  // );

  return taskData ? (
    <section className="formPage-wrap flex flex-col gap-8 ">
      <div className=" flex sm:flex-row flex-col sm:justify-between justify-center items-center w-full ">
        <h2 className="text-2xl font-bold text-gray-800">Task #{id} Details</h2>
        {isEditingOfferAllowed && (
          <Link className="updateButton w-max " to={`/dashboard/Edit-task/${id}/`}>
            Edit
          </Link>
        )}
      </div>
      {true && (
        <div className="w-full justify-center items-center flex">
          <ChangeSTatus />
        </div>
      )}
      <div className="w-full mx-auto bg-white py-6 px-4 shadow-md rounded ">
        <div className="w-full flex  md:items-center gap-1">
          <div className="flex flex-col gap-1 w-full">
            {/* <h2 className="text-3xl text-[#0B1931] capitalize">Task #{id} Details</h2> */}
            <p className="text-base font-medium">
              Task Name:
              <span className="text-sm text-gray-400 capitalize"> {taskData?.name}</span>
            </p>
            <p className="text-base font-medium capitalize ">
              Task Type: <span className="text-sm text-gray-400  capitalize">{taskData?.task_type?.name}</span>{" "}
            </p>

            <p className="text-base font-medium capitalize flex items-start gap-2 flex-wrap">
              {taskData.department?.length > 1 ? "Departments:" : "Department:"}{" "}
              <span className="text-sm text-gray-400 flex gap-2  capitalize">
                {taskData?.department?.map((d, idx) => (
                  <span key={idx} className=" flex gap-2">
                    {d?.name}
                  </span>
                ))}
              </span>
            </p>
            <div className="flex gap-2">
              <p className="text-base font-medium capitalize items-start flex gap-2 ">
                {taskData?.user.length > 1 ? "Users:" : "User:"}{" "}
                <span className="text-sm text-gray-400 flex flex-wrap gap-2 capitalize">
                  {taskData?.user?.map((u, idx) => (
                    <p key={idx} className="flex   gap-2 ">
                      {" "}
                      {u?.first_name + u?.last_name + "(" + u?.username + ")"}
                    </p>
                  ))}
                </span>
              </p>
              <button onClick={showModal} className="px-1.5 py-.8 rounded-md text-white h-max bg-green-500">
                Add user
              </button>
              <Modal
                title="Add User"
                visible={isModalVisible}
                onOk={() => {
                  // Trigger form submission via Formik's handleSubmit
                  formik.handleSubmit();
                }}
                onCancel={handleCancel}
              >
                <form onSubmit={formik.handleSubmit}>
                  <div className="flex flex-col">
                    <label htmlFor="department">Department</label>
                    <Select
                      options={grouOptions} // Options passed as props
                      placeholder="Department"
                      onChange={(selectedOptions) => {
                        formik.setFieldValue("department", selectedOptions);
                      }}
                      value={formik.values.department || null}
                      isMulti
                    />
                    {formik.touched.department && formik.errors.department ? (
                      <div className="text-red-500">{formik.errors.department}</div>
                    ) : null}

                    <label htmlFor="user">Users</label>

                    <UsersSearchTextInputTasks
                      values={formik.values}
                      setFieldValue={formik.setFieldValue}
                      department={formik.values.department}
                    />

                    {/* Validation Error Message */}

                    {formik.touched.user && formik.errors.user ? (
                      <div className="text-red-500 text-center text-xs">{formik.errors.user}</div>
                    ) : null}
                  </div>
                </form>
              </Modal>
            </div>
            <p className="text-base font-medium capitalize">
              Task Status:{" "}
              <span
                className={`text-sm ${taskData?.status === "closed" && "text-black-400"} ${taskData?.status === "resolved" && "text-green-400"}  ${taskData?.status === "open" && "text-green-400"} ${taskData?.status === "in_progress" && "text-yellow-400"} `}
              >
                {" "}
                {taskData?.status
                  ?.replace(/_/g, " ")
                  ?.split(" ")
                  ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  ?.join(" ")}
              </span>
            </p>
            <p className="text-base font-medium flex gap-4 items-center">
              Attachments:{" "}
              {taskData?.attachment?.length > 0 ? (
                <AttachmentsIcons attachments={[taskData?.attachment]} />
              ) : (
                <span className="text-sm text-gray-400"> No attachments.</span>
              )}
            </p>
            <p className="text-base font-medium capitalize">
              Created at:{" "}
              <span className="text-sm text-gray-400  capitalize">{taskData?.created_at?.slice(0, 10)}</span>{" "}
            </p>
          </div>
        </div>

        <p className="text-center  capitalize text-cyan-900  font-medium text-lg">Details</p>
        <div className=" w-full border-dashed border border-[#002244] rounded-lg p-5">
          <p dir="rtl" className="text-cyan-950 font-medium text-base">
            <div dangerouslySetInnerHTML={{ __html: taskData?.task_details }} />
          </p>
        </div>
      </div>
      <div className="min-h-max">
        <div className="space-y-2 mt-4 mb-4">
          {replies?.map((reply, idx) => {
            return <TicketReply key={idx} reply={reply} />;
          })}
          <TaskReplayForm refetchRateDetails={refetchRateDetails} />
        </div>
      </div>
    </section>
  ) : (
    <Loading />
  );
};

export default ViewTask;
