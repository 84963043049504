import {
  CARDSTATUS,
  CLEARBOOKINGORDER,
  DATAOFBOOKINGORDER,
  DECREASEQTY,
  DELETEDATAOFBOOKINGORDER,
  INCREASEQTY,
  TOTALPRICE,
  TOTOALAMOUNT,
} from "../types/types";

const initialState = {
  cardStatus: false,
  cardData: [],
  cardTotalAmount: 0,
  cardTotalPrice: 0,
};

export const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CARDSTATUS:
      return { ...state, cardStatus: action.payload };
    case DATAOFBOOKINGORDER:
      return { ...state, cardData: action.payload };
    case TOTOALAMOUNT:
      return { ...state, cardTotalAmount: action.payload };
    case DELETEDATAOFBOOKINGORDER:
      return { ...state, cardData: action.payload };
    case INCREASEQTY:
      return { ...state, cardData: action.payload };
    case DECREASEQTY:
      return { ...state, cardData: action.payload };
    case CLEARBOOKINGORDER:
      return { ...state, cardData: action.payload };
    case TOTALPRICE:
      return { ...state, cardTotalPrice: action.payload };
    default:
      return state;
  }
};
