import React, { useEffect, useMemo, useRef, useState } from "react";
import "./../AddHotelForm/DashboardForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DatePicker } from "antd";
// import jwtDecode from "jwt-decode";
// actions imports
import requestHotelNames from "../../../redux/actions/requestHotelNames";
import {
  addContract,
  requestCurrencyTypes,
  requestMarketTypes,
  requestMealsIncluded,
  requestOfferTypes,
} from "../../../redux/actions/addContract";
import { clearHotelRooms, getHotelRooms } from "../../../redux/actions/getRooms";
import { clearOldValues, fillContractValiditiesFromDraft } from "../../../redux/actions/contractValidity";
// components imports
import StaticFormSelect from "../../molecules/StaticFormSelect/StaticFormSelect";
import StaticFromInput from "../../molecules/StaticFormInput/StaticFromInput";
import DynamicCheckbox from "../../molecules/DynamicCheckbox/DynamicCheckbox";
import MultiDynamicInputs from "../../molecules/MultiDynamicInputs/MultiDynamicInputs";
import ContractFormDynamicComponentParent from "../../molecules/ContractFormRoomDynamicComponentParent/ContractFormDynamicComponentParent";
import "react-dates/lib/css/_datepicker.css";
import Timer from "../../atoms/Timer/Timer";
import TopBarProgress from "react-topbar-progress-indicator";
import useRemoveScroll from "../../../customHooks/useRemoveScroll";
import { RiRestaurantFill, RiTimerLine } from "react-icons/ri";
import { LiaFileContractSolid } from "react-icons/lia";
import { GiMeal } from "react-icons/gi";
import { FaLocationCrosshairs, FaTreeCity } from "react-icons/fa6";
import { BsCurrencyExchange } from "react-icons/bs";
import { BiMinusCircle, BiSolidOffer } from "react-icons/bi";
import { FaHotel, FaPercent } from "react-icons/fa";
import { IoCalendarSharp, IoDocumentTextSharp, IoNewspaper } from "react-icons/io5";
import {
  MdAddCircleOutline,
  MdDateRange,
  MdDescription,
  MdNightsStay,
  MdOutlineBedroomChild,
  MdOutlineDescription,
  MdOutlinePostAdd,
  MdOutlinePriceChange,
} from "react-icons/md";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { TbBrandDaysCounter } from "react-icons/tb";
import { FcCancel } from "react-icons/fc";
import { HiClipboardDocumentCheck } from "react-icons/hi2";
import { CgNotes } from "react-icons/cg";
import Select from "react-select";
import { useMutation, useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { toast } from "react-toastify";
import {
  addChildMeal,
  addMealChildEndAgeValue,
  addMealChildOrder,
  addMealChildStartAgeValue,
  addNewChildMeal,
  clearChildrenMealOldValues,
  deleteMealByName,
  fillChildrenMealsFromExistingValues,
  removeChildMeal,
} from "../../../redux/actions/contractChildrenMeals";
import FileUploader from "../FileUploader/FileUploader";
import Loading from "../Loading/Loading";
import usePermissions from "../../../customHooks/usePermissions";
import Errorfetch from "../Errorfetch/Errorfetch";
import NoPermission from "../NoPermission/NoPermission";
import ChildrenPolicyAssistantTool from "./AssistanceTools/ChildrenPolicyAssistantTool/ChildrenPolicyAssistantTool";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { Button, Modal } from "flowbite-react";
import ContractAssistanceTool from "./AssistanceTools/ContractAssistantTool/ContractAssistanceTool";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const { RangePicker } = DatePicker;

let childRoomTypes = [];

for (let i = 1; i < 5; i++) {
  childRoomTypes.push({ id: i, name: i });
}

const countUniqueAdults = (arr) => {
  const uniqueAdults = new Set();
  let counter = 0;

  arr.forEach((item) => {
    const { num_adults } = item;
    if (!uniqueAdults.has(num_adults)) {
      uniqueAdults.add(num_adults);
      counter++;
    }
  });

  return counter;
};

const groupByNumAdults = (weekendSupplementsPerPerson) => {
  return weekendSupplementsPerPerson.reduce((acc, current) => {
    const { num_adults, day, price } = current;

    // Check if there's already an entry for this number of adults
    if (!acc[num_adults]) {
      acc[num_adults] = [];
    }

    // Add the day-price pair to the array
    acc[num_adults].push({ day, price });

    return acc;
  }, {});
};

export default function AddContractForm() {
  const [contractDocuments, setContractDocuments] = useState([]);

  const [breakfastChecked, setBreakfastChecked] = useState(false);
  const [lunchChecked, setLunchChecked] = useState(false);
  const [dinnerChecked, setDinnerChecked] = useState(false);
  const [softAllChecked, setSoftAllChecked] = useState(false);
  const [mealIncluded, setMealIncluded] = useState("");
  const [childrenMealsInput, setChildrenMealsInput] = useState(0);

  const [contractType, setContractType] = useState("per_person");
  const contractChildrenMeals = useSelector((state) => state.contractChildrenMeals);
  const [isDraft, setIsDraft] = useState(false);
  const [weekendSupplementsPerPersonAdults, setWeekendSupplementsPerPersonAdults] = useState([]);
  // function constants
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef();
  const form = formRef.current;
  useRemoveScroll();
  // local states and redux
  const hotelNames = useSelector((state) => state.hotelNames);
  const contractValidity = useSelector((state) => state.contractValidity);

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [addFlag, setaddFlag] = useState(false);
  const [roomsHotel, setroomsHotel] = useState();
  const [roomFlag, setroomFlag] = useState(false);

  const { marketTypes, offerTypes, mealsIncluded, currencyTypes } = useSelector((state) => state.addContract);
  const hotelRooms = useSelector((state) => state.hotelRooms);
  const [isAmountReq, setIsAmountReq] = useState(false);
  const ageSelectOptions = Array.from({ length: 12 }, (_, i) => ({ id: i, name: i }));

  // convert state arrays to compatible data with the staticFormSelect component
  const mealsIncludedArr = mealsIncluded.map((elem) => {
    return { id: elem, name: elem };
  });
  const currencyTypesArr = currencyTypes.map((elem) => {
    return { id: elem, name: elem };
  });

  const hotelRoomsArr = hotelRooms.map((roomObj) => {
    return {
      id: roomObj.id,
      name: roomObj.room_name,
      maxAdults: roomObj.max_adults,
      maxChildren: roomObj.max_children,
      maxOccupancy: roomObj.max_occupancy,
    };
  });

  useEffect(() => {
    if (!breakfastChecked) {
      dispatch(deleteMealByName("breakfast"));
    }
  }, [breakfastChecked, dispatch]);

  useEffect(() => {
    if (!lunchChecked) {
      dispatch(deleteMealByName("lunch"));
    }
  }, [lunchChecked, dispatch]);

  useEffect(() => {
    if (!dinnerChecked) {
      dispatch(deleteMealByName("dinner"));
    }
  }, [dinnerChecked, dispatch]);

  useEffect(() => {
    if (!softAllChecked) {
      dispatch(deleteMealByName("soft_all"));
    }
  }, [softAllChecked, dispatch]);

  useEffect(() => {
    if (!breakfastChecked && !dinnerChecked && !lunchChecked && !softAllChecked) {
      setChildrenMealsInput(0);
      dispatch(clearChildrenMealOldValues());
    }
  }, [breakfastChecked, dinnerChecked, dispatch, lunchChecked, softAllChecked]);

  // get data for the form
  useEffect(() => {
    dispatch(clearHotelRooms());
    dispatch(requestHotelNames());
    dispatch(requestMarketTypes());
    dispatch(requestOfferTypes());
    dispatch(requestMealsIncluded());
    dispatch(requestCurrencyTypes());
    dispatch(clearOldValues());
    dispatch(clearChildrenMealOldValues());
  }, []);

  // functions
  function setRooms(datahotel) {
    if (datahotel?.value) {
      setroomFlag(true);
      dispatch(
        getHotelRooms(datahotel?.value, (result) => {
          if (result.status === 200) {
            setroomFlag(false);
            setroomsHotel(result.data);
          }
        }),
      );
      // set the values of rooms to empty strings if the user chose a hotel so that if the user changed
      // the hotel he will have to set the rooms again
      formRef.current?.room?.value
        ? (formRef.current.room.value = "")
        : Array.from(formRef.current.room).forEach((elem) => (elem.value = ""));
    }
  }

  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
  };

  // variable to hold the total time to get it on submit
  const timerTotalSecondsRef = useRef(0);
  const [timerAutoStart, setTimerAutoStart] = useState(false);
  const [timerOffsetTimestamp, setTimerOffsetTimestamp] = useState(0);

  function makeAmountRequired(data) {
    if (data) {
      setIsAmountReq(true);
    }
  }

  function getMaxOrderingChild(contractValidity) {
    let maxOrderingChild = 0;

    contractValidity.forEach((room) => {
      room.date_range.forEach((dateRange) => {
        dateRange.validity_children_price.forEach((childPrice) => {
          if (childPrice.ordering_child > maxOrderingChild) {
            maxOrderingChild = childPrice.ordering_child;
          }
        });
      });
    });

    return maxOrderingChild;
  }

  function checkChildrenMealsCoverage(contractChildrenMeals, minStartAge, maxEndAge, maxOrderingChild) {
    for (let order = 1; order <= maxOrderingChild; order++) {
      let coveredAges = new Set();

      contractChildrenMeals.forEach((meal) => {
        if (meal.ordering_child === order) {
          for (let age = meal.start_age; age <= meal.end_age; age++) {
            coveredAges.add(age);
          }
        }
      });

      for (let age = minStartAge; age <= maxEndAge; age++) {
        if (!coveredAges.has(age)) {
          toast.error(`Missing period for child order ${order} at age ${age} in children meals`);
          return false;
        }
      }
    }

    return true;
  }

  function generateContractFormData(e) {
    const finalData = new FormData();
    // variables to hold the checkboxes of meal supplements
    const breakfast = e.target.breakfast?.checked;
    const lunch = e.target.lunch?.checked;
    const dinner = e.target.dinner?.checked;
    const softAll = e.target.softAll?.checked;
    const data = {
      name: e.target.name.value,
      hotel: e.target.hotel.value,
      market: e.target.market.value,
      offer_type: e.target.offer_type.value,
      meal_included: e.target.meal_included.value,
      currency_type: e.target.currency_type.value,
      created_time: timerTotalSecondsRef.current,
    };
    // put basic data inside th finalData
    for (let elem in data) {
      finalData.append(`${elem}`, data[elem]);
    }

    if (contractType === "per_person") {
      // check meal supplements and put them in finalData if they exist
      breakfast
        ? finalData.append(
            "meal_supplements",
            JSON.stringify({ name: "breakfast", price: e.target.breakfastPrice.value }),
          )
        : void 0;
      lunch
        ? finalData.append("meal_supplements", JSON.stringify({ name: "lunch", price: e.target.lunchPrice.value }))
        : void 0;
      dinner
        ? finalData.append("meal_supplements", JSON.stringify({ name: "dinner", price: e.target.dinnerPrice.value }))
        : void 0;
      softAll
        ? finalData.append("meal_supplements", JSON.stringify({ name: "soft_all", price: e.target.softAllPrice.value }))
        : void 0;
    }

    // Check oneDaySupplement and add it
    // Check if supplements exist
    if (e.target.supplement) {
      if (e.target.supplement instanceof NodeList) {
        Array.from(e.target.supplement).forEach((elem, index) => {
          const childrenPrices = [];

          // Fetch child selects specific to this supplement
          const startAgeInputs = elem.parentNode.parentNode.querySelectorAll(
            'select[name="supplement_child_start_age"]',
          );
          const endAgeInputs = elem.parentNode.parentNode.querySelectorAll('select[name="supplement_child_end_age"]');
          const priceInputs = elem.parentNode.parentNode.querySelectorAll('input[name="supplement_child_price"]');

          // Convert NodeLists to arrays for easier iteration
          const startAgeArray = Array.from(startAgeInputs);
          const endAgeArray = Array.from(endAgeInputs);
          const priceArray = Array.from(priceInputs);

          startAgeArray.forEach((_, childIndex) => {
            const startAgeValue = startAgeArray[childIndex]?.value;
            const endAgeValue = endAgeArray[childIndex]?.value;
            const priceValue = priceArray[childIndex]?.value;

            // Only add a child if all values are provided
            if (startAgeValue && endAgeValue && priceValue) {
              childrenPrices.push({
                start_age: Number(startAgeValue),
                end_age: Number(endAgeValue),
                price: Number(priceValue),
              });
            }
          });

          // Append supplement data to FormData
          finalData.append(
            "one_day_supplements",
            JSON.stringify({
              name: elem.value,
              price: Number(e.target.supplement_price[index].value),
              date: e.target.supplement_date[index].value,
              one_day_supplements_children_price: childrenPrices,
            }),
          );
        });
      } else {
        const elem = e.target.supplement;
        const childrenPrices = [];

        // Fetch child selects specific to the single supplement
        const startAgeInputs = elem.parentNode.parentNode.querySelectorAll('select[name="supplement_child_start_age"]');
        const endAgeInputs = elem.parentNode.parentNode.querySelectorAll('select[name="supplement_child_end_age"]');
        const priceInputs = elem.parentNode.parentNode.querySelectorAll('input[name="supplement_child_price"]');

        // Convert NodeLists to arrays for easier iteration
        const startAgeArray = Array.from(startAgeInputs);
        const endAgeArray = Array.from(endAgeInputs);
        const priceArray = Array.from(priceInputs);

        startAgeArray.forEach((_, childIndex) => {
          const startAgeValue = startAgeArray[childIndex]?.value;
          const endAgeValue = endAgeArray[childIndex]?.value;
          const priceValue = priceArray[childIndex]?.value;

          // Only add a child if all values are provided
          if (startAgeValue && endAgeValue && priceValue) {
            childrenPrices.push({
              start_age: Number(startAgeValue),
              end_age: Number(endAgeValue),
              price: Number(priceValue),
            });
          }
        });

        // Append single supplement data to FormData
        finalData.append(
          "one_day_supplements",
          JSON.stringify({
            name: elem.value,
            price: Number(e.target.supplement_price.value),
            date: e.target.supplement_date.value,
            one_day_supplements_children_price: childrenPrices,
          }),
        );
      }
    }

    // Check oneDaySupplement and add it
    // Check if supplements exist
    if (e.target.period_supplement) {
      if (e.target.period_supplement instanceof NodeList) {
        Array.from(e.target.period_supplement).forEach((elem, index) => {
          const childrenPrices = [];

          // Fetch child selects specific to this supplement
          const startAgeInputs = elem.parentNode.parentNode.querySelectorAll(
            'select[name="period_supplement_child_start_age"]',
          );
          const endAgeInputs = elem.parentNode.parentNode.querySelectorAll(
            'select[name="period_supplement_child_end_age"]',
          );
          const priceInputs = elem.parentNode.parentNode.querySelectorAll(
            'input[name="period_supplement_child_price"]',
          );

          // Convert NodeLists to arrays for easier iteration
          const startAgeArray = Array.from(startAgeInputs);
          const endAgeArray = Array.from(endAgeInputs);
          const priceArray = Array.from(priceInputs);

          startAgeArray.forEach((_, childIndex) => {
            const startAgeValue = startAgeArray[childIndex]?.value;
            const endAgeValue = endAgeArray[childIndex]?.value;
            const priceValue = priceArray[childIndex]?.value;

            // Only add a child if all values are provided
            if (startAgeValue && endAgeValue && priceValue) {
              childrenPrices.push({
                start_age: Number(startAgeValue),
                end_age: Number(endAgeValue),
                price: Number(priceValue),
              });
            }
          });

          // Append supplement data to FormData
          finalData.append(
            "period_supplements",
            JSON.stringify({
              name: elem.value,
              price: Number(e.target.period_supplement_price[index].value),
              start_date: e.target.period_supplement_start_date[index].value,
              end_date: e.target.period_supplement_end_date[index].value,
              period_supplements_children_price: childrenPrices,
            }),
          );
        });
      } else {
        const elem = e.target.period_supplement;
        const childrenPrices = [];

        // Fetch child selects specific to the single supplement
        const startAgeInputs = elem.parentNode.parentNode.querySelectorAll(
          'select[name="period_supplement_child_start_age"]',
        );
        const endAgeInputs = elem.parentNode.parentNode.querySelectorAll(
          'select[name="period_supplement_child_end_age"]',
        );
        const priceInputs = elem.parentNode.parentNode.querySelectorAll('input[name="period_supplement_child_price"]');

        // Convert NodeLists to arrays for easier iteration
        const startAgeArray = Array.from(startAgeInputs);
        const endAgeArray = Array.from(endAgeInputs);
        const priceArray = Array.from(priceInputs);

        startAgeArray.forEach((_, childIndex) => {
          const startAgeValue = startAgeArray[childIndex]?.value;
          const endAgeValue = endAgeArray[childIndex]?.value;
          const priceValue = priceArray[childIndex]?.value;

          // Only add a child if all values are provided
          if (startAgeValue && endAgeValue && priceValue) {
            childrenPrices.push({
              start_age: Number(startAgeValue),
              end_age: Number(endAgeValue),
              price: Number(priceValue),
            });
          }
        });

        // Append single supplement data to FormData
        finalData.append(
          "period_supplements",
          JSON.stringify({
            name: elem.value,
            price: Number(e.target.period_supplement_price.value),
            start_date: e.target.period_supplement_start_date.value,
            end_date: e.target.period_supplement_end_date.value,
            period_supplements_children_price: childrenPrices,
          }),
        );
      }
    }

    // // Check for specific day supplement for children
    //
    // if (e.target.period_supplement) {
    //   if (e.target.period_supplement[0]) {
    //     Array.from(e.target.period_supplement).map((elem, index) => {
    //       finalData.append(
    //         "period_supplements",
    //         JSON.stringify({
    //           name: elem.value,
    //           price: e.target.period_supplement_price[index].value,
    //           start_date: e.target.period_supplement_start_date[index].value,
    //           end_date: e.target.period_supplement_end_date[index].value,
    //         }),
    //       );
    //     });
    //   } else {
    //     finalData.append(
    //       "period_supplements",
    //       JSON.stringify({
    //         name: e.target.period_supplement.value,
    //         price: e.target.period_supplement_price.value,
    //         start_date: e.target.period_supplement_start_date.value,
    //         end_date: e.target.period_supplement_end_date.value,
    //       }),
    //     );
    //   }
    // }

    // check weekend supplements and add them
    e.target.weekend_supplement_mon.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "mon",
            price: Number(e.target.weekend_supplement_mon.value),
          }),
        )
      : void 0;
    e.target.weekend_supplement_tue.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "tue",
            price: Number(e.target.weekend_supplement_tue.value),
          }),
        )
      : void 0;
    e.target.weekend_supplement_wed.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "wed",
            price: Number(e.target.weekend_supplement_wed.value),
          }),
        )
      : void 0;
    e.target.weekend_supplement_thu.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "thu",
            price: Number(e.target.weekend_supplement_thu.value),
          }),
        )
      : void 0;
    e.target.weekend_supplement_fri.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "fri",
            price: Number(e.target.weekend_supplement_fri.value),
          }),
        )
      : void 0;
    e.target.weekend_supplement_sun.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "sun",
            price: Number(e.target.weekend_supplement_sun.value),
          }),
        )
      : void 0;
    e.target.weekend_supplement_sat.value
      ? finalData.append(
          "weekend_supplements",
          JSON.stringify({
            name: "sat",
            price: Number(e.target.weekend_supplement_sat.value),
          }),
        )
      : void 0;

    // check period weekend supplements and add them
    if (e.target.period_weekend_supplement_start_date) {
      if (e.target.period_weekend_supplement_start_date[0]) {
        Array.from(e.target.period_weekend_supplement_start_date).map((elem, index) => {
          finalData.append(
            "period_weekend_supplements",
            JSON.stringify({
              start_date: elem.value,
              end_date: e.target.period_weekend_supplement_end_date[index].value,
              day_prices: [
                e.target.period_weekend_supplement_sat[index].value
                  ? { day: "sat", price: e.target.period_weekend_supplement_sat[index].value }
                  : void 0,
                e.target.period_weekend_supplement_sun[index].value
                  ? { day: "sun", price: e.target.period_weekend_supplement_sun[index].value }
                  : void 0,
                e.target.period_weekend_supplement_mon[index].value
                  ? { day: "mon", price: e.target.period_weekend_supplement_mon[index].value }
                  : void 0,
                e.target.period_weekend_supplement_tue[index].value
                  ? { day: "tue", price: e.target.period_weekend_supplement_tue[index].value }
                  : void 0,
                e.target.period_weekend_supplement_wed[index].value
                  ? { day: "wed", price: e.target.period_weekend_supplement_wed[index].value }
                  : void 0,
                e.target.period_weekend_supplement_thu[index].value
                  ? { day: "thu", price: e.target.period_weekend_supplement_thu[index].value }
                  : void 0,
                e.target.period_weekend_supplement_fri[index].value
                  ? { day: "fri", price: e.target.period_weekend_supplement_fri[index].value }
                  : void 0,
              ].filter((elem) => {
                return elem !== void 0;
              }),
            }),
          );
        });
      } else {
        finalData.append(
          "period_weekend_supplements",
          JSON.stringify({
            start_date: e.target.period_weekend_supplement_start_date.value,
            end_date: e.target.period_weekend_supplement_end_date.value,
            day_prices: [
              e.target.period_weekend_supplement_sat.value
                ? { day: "sat", price: e.target.period_weekend_supplement_sat.value }
                : void 0,
              e.target.period_weekend_supplement_sun.value
                ? { day: "sun", price: e.target.period_weekend_supplement_sun.value }
                : void 0,
              e.target.period_weekend_supplement_mon.value
                ? { day: "mon", price: e.target.period_weekend_supplement_mon.value }
                : void 0,
              e.target.period_weekend_supplement_tue.value
                ? { day: "tue", price: e.target.period_weekend_supplement_tue.value }
                : void 0,
              e.target.period_weekend_supplement_wed.value
                ? { day: "wed", price: e.target.period_weekend_supplement_wed.value }
                : void 0,
              e.target.period_weekend_supplement_thu.value
                ? { day: "thu", price: e.target.period_weekend_supplement_thu.value }
                : void 0,
              e.target.period_weekend_supplement_fri.value
                ? { day: "fri", price: e.target.period_weekend_supplement_fri.value }
                : void 0,
            ].filter((elem) => {
              return elem !== void 0;
            }),
          }),
        );
      }
    }

    // Weekend Supplements Per Person
    if (e.target.weekend_supplements_per_person_sat) {
      if (e.target.weekend_supplements_per_person_sat[0]) {
        Array.from(e.target.weekend_supplements_per_person_sat).forEach((elem, index) => {
          e.target.weekend_supplements_per_person_sat[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "sat",
                  price: Number(e.target.weekend_supplements_per_person_sat[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;

          e.target.weekend_supplements_per_person_sun[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "sun",
                  price: Number(e.target.weekend_supplements_per_person_sun[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;

          e.target.weekend_supplements_per_person_mon[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "mon",
                  price: Number(e.target.weekend_supplements_per_person_mon[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;

          e.target.weekend_supplements_per_person_tue[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "tue",
                  price: Number(e.target.weekend_supplements_per_person_tue[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;

          e.target.weekend_supplements_per_person_wed[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "wed",
                  price: Number(e.target.weekend_supplements_per_person_wed[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;

          e.target.weekend_supplements_per_person_thu[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "thu",
                  price: Number(e.target.weekend_supplements_per_person_thu[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;

          e.target.weekend_supplements_per_person_fri[index].value
            ? finalData.append(
                "weekend_supplements_per_person",
                JSON.stringify({
                  day: "fri",
                  price: Number(e.target.weekend_supplements_per_person_fri[index].value),
                  num_adults: e.target.weekend_supplements_per_person_adults[index].value,
                }),
              )
            : void 0;
        });
      } else {
        e.target.weekend_supplements_per_person_sat.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "sat",
                price: Number(e.target.weekend_supplements_per_person_sat.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;

        e.target.weekend_supplements_per_person_sun.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "sun",
                price: Number(e.target.weekend_supplements_per_person_sun.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;

        e.target.weekend_supplements_per_person_mon.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "mon",
                price: Number(e.target.weekend_supplements_per_person_mon.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;

        e.target.weekend_supplements_per_person_tue.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "tue",
                price: Number(e.target.weekend_supplements_per_person_tue.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;

        e.target.weekend_supplements_per_person_wed.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "wed",
                price: Number(e.target.weekend_supplements_per_person_wed.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;

        e.target.weekend_supplements_per_person_thu.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "thu",
                price: Number(e.target.weekend_supplements_per_person_thu.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;

        e.target.weekend_supplements_per_person_fri.value
          ? finalData.append(
              "weekend_supplements_per_person",
              JSON.stringify({
                day: "fri",
                price: Number(e.target.weekend_supplements_per_person_fri.value),
                num_adults: e.target.weekend_supplements_per_person_adults.value,
              }),
            )
          : void 0;
      }
    }

    // Check if period weekend supplements per person are present and add them
    if (e.target.period_weekend_supplements_per_person_start_date) {
      if (e.target.period_weekend_supplements_per_person_start_date[0]) {
        Array.from(e.target.period_weekend_supplements_per_person_start_date).map((elem, index) => {
          finalData.append(
            "period_weekend_supplements_per_person",
            JSON.stringify({
              start_date: elem.value,
              end_date: e.target.period_weekend_supplements_per_person_end_date[index].value,
              day_prices_per_person: [
                e.target.period_weekend_supplements_per_person_sat[index].value
                  ? {
                      day: "sat",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_sat[index].value,
                    }
                  : void 0,
                e.target.period_weekend_supplements_per_person_sun[index].value
                  ? {
                      day: "sun",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_sun[index].value,
                    }
                  : void 0,
                e.target.period_weekend_supplements_per_person_mon[index].value
                  ? {
                      day: "mon",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_mon[index].value,
                    }
                  : void 0,
                e.target.period_weekend_supplements_per_person_tue[index].value
                  ? {
                      day: "tue",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_tue[index].value,
                    }
                  : void 0,
                e.target.period_weekend_supplements_per_person_wed[index].value
                  ? {
                      day: "wed",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_wed[index].value,
                    }
                  : void 0,
                e.target.period_weekend_supplements_per_person_thu[index].value
                  ? {
                      day: "thu",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_thu[index].value,
                    }
                  : void 0,
                e.target.period_weekend_supplements_per_person_fri[index].value
                  ? {
                      day: "fri",
                      num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                      price: e.target.period_weekend_supplements_per_person_fri[index].value,
                    }
                  : void 0,
              ].filter((elem) => {
                return elem !== void 0;
              }),
            }),
          );
        });
      } else {
        finalData.append(
          "period_weekend_supplements_per_person",
          JSON.stringify({
            start_date: e.target.period_weekend_supplements_per_person_start_date.value,
            end_date: e.target.period_weekend_supplements_per_person_end_date.value,
            day_prices_per_person: [
              e.target.period_weekend_supplements_per_person_sat.value
                ? {
                    day: "sat",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_sat.value,
                  }
                : void 0,
              e.target.period_weekend_supplements_per_person_sun.value
                ? {
                    day: "sun",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_sun.value,
                  }
                : void 0,
              e.target.period_weekend_supplements_per_person_mon.value
                ? {
                    day: "mon",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_mon.value,
                  }
                : void 0,
              e.target.period_weekend_supplements_per_person_tue.value
                ? {
                    day: "tue",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_tue.value,
                  }
                : void 0,
              e.target.period_weekend_supplements_per_person_wed.value
                ? {
                    day: "wed",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_wed.value,
                  }
                : void 0,
              e.target.period_weekend_supplements_per_person_thu.value
                ? {
                    day: "thu",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_thu.value,
                  }
                : void 0,
              e.target.period_weekend_supplements_per_person_fri.value
                ? {
                    day: "fri",
                    num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                    price: e.target.period_weekend_supplements_per_person_fri.value,
                  }
                : void 0,
            ].filter((elem) => {
              return elem !== void 0;
            }),
          }),
        );
      }
    }

    //check commission type and add them
    if (e.target.commission_type.value) {
      finalData.append("commission_type", e.target.commission_type.value);
      finalData.append("commission_price", e.target.commission_price.value);
    }

    finalData.append("booking_valid_from", dateRange.startDate);
    finalData.append("booking_valid_to", dateRange.endDate);

    // check reservation policy and add it
    e.target.reservation_policy.value
      ? finalData.append("reservation_policy", e.target.reservation_policy.value)
      : void 0;

    // add price_policy_minimum_days & price_policy_maximum_days
    finalData.append("price_policy_minimum_days", e.target.min.value);
    finalData.append("price_policy_maximum_days", e.target.max.value);

    // check cancellation policy existence
    e.target.cancellation_policy.value
      ? finalData.append("cancellation_policy", e.target.cancellation_policy.value)
      : void 0;

    // check same_day_reservation_price
    e.target.same_day_reservation_price.value
      ? finalData.append("same_day_reservation_price", e.target.same_day_reservation_price.value)
      : void 0;

    // check notes
    e.target.notes.value ? finalData.append("notes", e.target.notes.value) : void 0;

    // add documents
    if (contractDocuments.length > 0) {
      for (let doc of contractDocuments) {
        if (!doc.valid) {
          toast.error(`File ${doc.name} is not valid because ${doc.errors[0]}`);
          setaddFlag(false);
          return;
        }
        finalData.append("price_document", doc.file);
      }
    }

    contractValidity.forEach((roomValidity) => {
      if (contractType === "per_person") {
        roomValidity.date_range.forEach((date) => {
          date.validity_room_packages = [];
        });
      }

      if (contractType === "per_room") {
        roomValidity.date_range.forEach((date) => {
          date.validity_children_price = [];
          date.rooms_types = [];
        });
      }
      finalData.append("data_rooms", JSON.stringify(roomValidity));
    });

    if (contractType === "per_person") {
      contractChildrenMeals.map((childMeal) => {
        finalData.append("meal_supplements_children", JSON.stringify(childMeal));
      });
    }

    finalData.append("contract_type", contractType);

    return finalData;
  }

  function generateContractDraftData(e) {
    const finalData = {
      name: e.target.name.value,
      hotel: {
        id: chosenHotels?.value,
        name: chosenHotels?.label,
      },
      city: e.target.city.value,
      market: e.target.market.value,
      offer_type: e.target.offer_type.value,
      meal_included: e.target.meal_included.value,
      currency_type: e.target.currency_type.value,
      created_time: timerTotalSecondsRef.current,
      meal_supplements: [],
      one_day_supplements: [],
      period_supplements: [],
      weekend_supplements: [],
      period_weekend_supplements: [],
      weekend_supplements_per_person: [],
      period_weekend_supplements_per_person: [],
      commission_type: e.target.commission_type.value || "",
      commission_price: e.target.commission_price.value || "",
      booking_valid_from: dateRange.startDate,
      booking_valid_to: dateRange.endDate,
      reservation_policy: e.target.reservation_policy.value || "",
      price_policy_minimum_days: e.target.min.value,
      price_policy_maximum_days: e.target.max.value,
      cancellation_policy: e.target.cancellation_policy.value || "",
      same_day_reservation_price: e.target.same_day_reservation_price.value || "",
      notes: e.target.notes.value || "",
      validities: [],
      meal_supplements_children: [],
      contract_type: contractType,
    };

    // Add meal supplements if contract type is per person
    if (contractType === "per_person") {
      if (e.target.breakfast?.checked) {
        finalData.meal_supplements.push({ name: "breakfast", price: e.target.breakfastPrice.value });
      }
      if (e.target.lunch?.checked) {
        finalData.meal_supplements.push({ name: "lunch", price: e.target.lunchPrice.value });
      }
      if (e.target.dinner?.checked) {
        finalData.meal_supplements.push({ name: "dinner", price: e.target.dinnerPrice.value });
      }
      if (e.target.softAll?.checked) {
        finalData.meal_supplements.push({ name: "soft_all", price: e.target.softAllPrice.value });
      }
    }

    // Add one-day supplements
    if (e.target.supplement) {
      const supplements = e.target.supplement[0]
        ? Array.from(e.target.supplement).map((elem, index) => ({
            name: elem.value,
            price: e.target.supplement_price[index].value,
            date: e.target.supplement_date[index].value,
          }))
        : [
            {
              name: e.target.supplement.value,
              price: e.target.supplement_price.value,
              date: e.target.supplement_date.value,
            },
          ];
      finalData.one_day_supplements.push(...supplements);
    }

    // Add period supplements
    if (e.target.period_supplement) {
      const periodSupplements = e.target.period_supplement[0]
        ? Array.from(e.target.period_supplement).map((elem, index) => ({
            name: elem.value,
            price: e.target.period_supplement_price[index].value,
            start_date: e.target.period_supplement_start_date[index].value,
            end_date: e.target.period_supplement_end_date[index].value,
          }))
        : [
            {
              name: e.target.period_supplement.value,
              price: e.target.period_supplement_price.value,
              start_date: e.target.period_supplement_start_date.value,
              end_date: e.target.period_supplement_end_date.value,
            },
          ];
      finalData.period_supplements.push(...periodSupplements);
    }

    // Add weekend supplements
    const weekendDays = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
    weekendDays.forEach((day) => {
      const dayValue = e.target[`weekend_supplement_${day}`].value;
      if (dayValue) {
        finalData.weekend_supplements.push({ name: day, price: Number(dayValue) });
      }
    });

    const days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

    // Add weekend supplements per person
    if (e.target.weekend_supplements_per_person_sat) {
      if (e.target.weekend_supplements_per_person_sat[0]) {
        Array.from(e.target.weekend_supplements_per_person_sat).forEach((elem, index) => {
          days.forEach((day) => {
            if (e.target[`weekend_supplements_per_person_${day}`][index]?.value) {
              finalData.weekend_supplements_per_person.push({
                day,
                price: Number(e.target[`weekend_supplements_per_person_${day}`][index].value),
                num_adults: e.target.weekend_supplements_per_person_adults[index].value,
              });
            }
          });
        });
      } else {
        days.forEach((day) => {
          if (e.target[`weekend_supplements_per_person_${day}`]?.value) {
            finalData.weekend_supplements_per_person.push({
              day,
              price: Number(e.target[`weekend_supplements_per_person_${day}`].value),
              num_adults: e.target.weekend_supplements_per_person_adults.value,
            });
          }
        });
      }
    }

    // Add period weekend supplements per person
    if (e.target.period_weekend_supplements_per_person_start_date) {
      if (e.target.period_weekend_supplements_per_person_start_date[0]) {
        Array.from(e.target.period_weekend_supplements_per_person_start_date).forEach((elem, index) => {
          const day_prices_per_person = days
            .map((day) => {
              const price = e.target[`period_weekend_supplements_per_person_${day}`][index]?.value;
              return price
                ? {
                    day,
                    num_adults: e.target.period_weekend_supplements_per_person_adults[index].value,
                    price,
                  }
                : null;
            })
            .filter(Boolean);

          finalData.period_weekend_supplements_per_person.push({
            start_date: elem.value,
            end_date: e.target.period_weekend_supplements_per_person_end_date[index].value,
            day_prices_per_person,
          });
        });
      } else {
        const day_prices_per_person = days
          .map((day) => {
            const price = e.target[`period_weekend_supplements_per_person_${day}`]?.value;
            return price
              ? {
                  day,
                  num_adults: e.target.period_weekend_supplements_per_person_adults.value,
                  price,
                }
              : null;
          })
          .filter(Boolean);

        finalData.period_weekend_supplements_per_person.push({
          start_date: e.target.period_weekend_supplements_per_person_start_date.value,
          end_date: e.target.period_weekend_supplements_per_person_end_date.value,
          day_prices_per_person,
        });
      }
    }

    // Add period weekend supplements
    if (e.target.period_weekend_supplement_start_date) {
      const periodWeekendSupplements = e.target.period_weekend_supplement_start_date[0]
        ? Array.from(e.target.period_weekend_supplement_start_date).map((elem, index) => ({
            start_date: elem.value,
            end_date: e.target.period_weekend_supplement_end_date[index].value,
            day_prices: weekendDays
              .map((day) => {
                const price = e.target[`period_weekend_supplement_${day}`][index].value;
                return price ? { day, price } : null;
              })
              .filter(Boolean),
          }))
        : [
            {
              start_date: e.target.period_weekend_supplement_start_date.value,
              end_date: e.target.period_weekend_supplement_end_date.value,
              day_prices: weekendDays
                .map((day) => {
                  const price = e.target[`period_weekend_supplement_${day}`].value;
                  return price ? { day, price } : null;
                })
                .filter(Boolean),
            },
          ];
      finalData.period_weekend_supplements.push(...periodWeekendSupplements);
    }

    // Add room validity and children meals if contract type is per person
    contractValidity.forEach((roomValidity) => {
      if (contractType === "per_person") {
        roomValidity.date_range.forEach((date) => {
          date.validity_room_packages = [];
        });
      }
      if (contractType === "per_room") {
        roomValidity.date_range.forEach((date) => {
          date.validity_children_price = [];
          date.rooms_types = [];
        });
      }
      finalData.validities.push(roomValidity);
    });

    if (contractType === "per_person") {
      contractChildrenMeals.map((childMeal) => {
        finalData.meal_supplements_children.push(childMeal);
      });
    }

    return finalData;
  }

  const { mutate: sendDraft } = useMutation({
    mutationKey: ["send-draft"],
    mutationFn: (data) => {
      mutateDataQuery("/contracts/api/contract-draft/", "POST", data, {
        "content-type": "application/json",
      });
    },
    onMutate: () => {
      toast.loading("Saving draft...");
    },
    onSuccess: () => {
      toast.dismiss();
      toast.success("Draft saved successfully");
    },
  });

  const {
    isLoading: isFetchingDraftData,
    isSuccess: isDraftDataReturned,
    data: draftData,
    refetch: refetchDraftData,
  } = useQuery({
    queryKey: ["get-draft"],
    queryFn: async () => {
      const data = await fetchDataQuery(`/contracts/api/contract-draft/user/`);

      if (data) {
        if (data.contract_data) {
          setOpenDraftModal(true);
          setTimerAutoStart(false);
          setTimerOffsetTimestamp(0);
        }
      } else {
        setIsDraft(true);
        setTimerAutoStart(true);
        setTimerOffsetTimestamp(0);
      }

      return data;
    },
  });

  const { mutate: deleteDraft, isPending: isDeletingDraft } = useMutation({
    mutationKey: ["delete-draft"],
    mutationFn: () => {
      return mutateDataQuery("/contracts/api/contract-draft/user/", "DELETE");
    },
    onSuccess: () => {
      setOpenDraftModal(false);
      setIsDraft(true);
      toast.success("Draft deleted successfully");
    },
  });

  const handleDraft = (e) => {
    e.preventDefault();
    if (typeof e !== "object" || Object.keys(e).length === 0) return;

    const draftData = generateContractDraftData(e);

    const requestData = JSON.stringify({
      contract_data: draftData,
    });

    sendDraft(requestData);
  };

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (isDraft) {
        if (formRef.current) {
          formRef.current.noValidate = true;
          formRef.current.requestSubmit();
          formRef.current.noValidate = false;
        }
      }
    }, 30000);

    return () => clearInterval(intervalID);
  }, [isDraft]);

  function handleSubmit(e) {
    if (isDraft) return;

    e.preventDefault();

    if (contractType === "per_person" && (breakfastChecked || dinnerChecked || lunchChecked || softAllChecked)) {
      const maxOrderingChild = getMaxOrderingChild(contractValidity);

      if (!checkChildrenMealsCoverage(contractChildrenMeals, 0, 11, maxOrderingChild)) {
        setIsDraft(true);
        return;
      }
    }

    // Check if number of adults in weekend supplements per person or
    // period weekend supplements per person equal max adults in contract validity
    if (contractType === "per_person") {
      const adultsOrdersInContractValidity = Array.from(
        new Set(
          contractValidity.flatMap((room) =>
            room.date_range.flatMap((date) => date.rooms_types.map((roomType) => roomType.type)),
          ),
        ),
      );

      const getNumericValuesFromElements = (selector) =>
        Array.from(document.querySelectorAll(selector)).map((elem) => Number(elem.value));

      const weekendSupplementsPerPersonAdultsOrders = getNumericValuesFromElements(
        '[name^="weekend_supplements_per_person_adults"]',
      );
      const periodWeekendSupplementsPerPersonAdultsOrders = getNumericValuesFromElements(
        '[name^="period_weekend_supplements_per_person_adults"]',
      );

      if (weekendSupplementsPerPersonAdultsOrders.length > 0) {
        for (const order of adultsOrdersInContractValidity) {
          if (!weekendSupplementsPerPersonAdultsOrders.includes(order)) {
            toast.error(`Missing adult ${order} in weekend supplements per person`);
            setIsDraft(true);
            return;
          }
        }
      }

      if (periodWeekendSupplementsPerPersonAdultsOrders.length > 0) {
        for (const order of adultsOrdersInContractValidity) {
          if (!periodWeekendSupplementsPerPersonAdultsOrders.includes(order)) {
            toast.error(`Missing adult ${order} in period weekend supplements per person`);
            setIsDraft(true);
            return;
          }
        }
      }
    }

    setaddFlag(true);

    setaddFlag(false);
    const finalData = generateContractFormData(e);

    dispatch(
      addContract(
        finalData,
        (id) => {
          setaddFlag(false);
          toast.dismiss();
          // deleteDraft();
          navigate(`/dashboard/contract-details/${id}`);
        },
        (error) => {
          setaddFlag(false);
          setIsDraft(true);
          // NotificationManager.error(error.response?.data[0]?.message);
        },
      ),
    );
  }

  /////permission/////////
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "contracts_management.add_contract",
    "rooms_management.add_roompackageoccupancy",
  ]);

  const isAddingPackageAllowed =
    permissions?.find((permission) => permission.name === "rooms_management.add_roompackageoccupancy")?.value === true;

  const isAddingContractAllowed =
    permissions?.find((permission) => permission.name === "contracts_management.add_contract")?.value === true;

  //filter hotel
  const [chosenHotels, setChosenHotels] = useState([]);
  const [CitiesChoice, setCitiesChoice] = useState("");

  const [openDraftModal, setOpenDraftModal] = useState(false);

  const [breakfastInitialValue, setBreakfastInitialValue] = useState("");
  const [lunchInitialValue, setLunchInitialValue] = useState("");
  const [dinnerInitialValue, setDinnerInitialValue] = useState("");
  const [softAllInitialValue, setSoftAllInitialValue] = useState("");

  const {
    data: hotelfilter,
    error: hotelfilterError,
    isLoading: hotelfilterLoading,
    refetch: hotelfilterFun,
  } = useQuery({
    queryKey: ["hotel-filter", CitiesChoice],
    queryFn: () => fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=${CitiesChoice + "," || ""}`),
    enabled: false, // Start with disabled query
  });

  //fetch data
  const {
    data: Cities,
    error: errorCities,
    isLoading: loadCities,
    refetch,
  } = useQuery({
    queryKey: ["get-cities"],
    queryFn: () => fetchDataQuery(`/search/api/v1/get-cities/`),
  });

  const CitiesArr = Cities?.map((city) => {
    return { label: city.name, value: city.id };
  });

  const hotelfiltersArr = useMemo(() => {
    return hotelfilter?.map((hotel) => {
      return { label: hotel.name, value: hotel.id };
    });
  }, [hotelfilter]);

  useEffect(() => {
    if (CitiesChoice) {
      hotelfilterFun();
    }
  }, [CitiesChoice]);

  const selectedCityTimeZone = Cities?.find((city) => {
    return city?.id === Number(CitiesChoice);
  })?.timezone;

  const [periodWeekendSupplementInputsNum, setPeriodWeekendSupplementInputsNum] = useState(0);
  const [periodSupplementInputsNum, setPeriodSupplementInputsNum] = useState(0);
  const [oneDaySupplementsInput, setOneDaySupplementsInput] = useState(0);

  const [periodWeekendSupplementPerPersonInputsNum, setPeriodWeekendSupplementPerPersonInputsNum] = useState(0);
  const [weekendSupplementsPerPersonInputsNum, setWeekendSupplementsPerPersonInputsNum] = useState(0);

  const contractDraft = draftData?.contract_data;

  useEffect(() => {
    setOneDaySupplementsInput(draftData?.contract_data?.one_day_supplements?.length || 0);
    setPeriodSupplementInputsNum(draftData?.contract_data?.period_supplements?.length || 0);
    setPeriodWeekendSupplementInputsNum(draftData?.contract_data?.period_weekend_supplements?.length || 0);
    setWeekendSupplementsPerPersonInputsNum(
      Math.max(countUniqueAdults(contractDraft?.weekend_supplements_per_person || []), 0),
    );
    setPeriodWeekendSupplementPerPersonInputsNum(
      draftData?.contract_data?.period_weekend_supplements_per_person?.length || 0,
    );
  }, [
    draftData?.contract_data?.one_day_supplements?.length,
    draftData?.contract_data?.period_supplements?.length,
    draftData?.contract_data?.period_weekend_supplements?.length,
    draftData?.contract_data?.period_weekend_supplements_per_person?.length,
  ]);

  // useEffect(() => {
  //   if (contractDraft?.city) {
  //     setCitiesChoice(Number(contractDraft?.city) + ",");
  //   }
  // }, [contractDraft?.city]);
  //
  // useEffect(() => {
  //   if (contractDraft?.hotel) {
  //     setChosenHotels(hotelfiltersArr?.find((hotel) => hotel?.value === Number(contractDraft?.hotel)));
  //     setRooms(hotelfiltersArr?.filter((hotel) => contractDraft?.hotel?.includes(hotel?.value)));
  //   }
  // }, [contractDraft?.hotel, hotelfiltersArr, setRooms]);

  const loadDraft = () => {
    setCitiesChoice(Number(contractDraft?.city));
    setChosenHotels({ label: contractDraft?.hotel?.name, value: contractDraft?.hotel?.id });
    setRooms({ label: contractDraft?.hotel?.name, value: contractDraft?.hotel?.id });

    setContractType(contractDraft?.contract_type);
    setTimerAutoStart(true);
    setTimerOffsetTimestamp(contractDraft?.created_time);

    // Validities
    dispatch(fillContractValiditiesFromDraft(contractDraft.validities));

    form.name.value = contractDraft.name;
    form.meal_included.value = contractDraft.meal_included;
    form.name.value = contractDraft.name;

    form.market.value = contractDraft.market;

    form.offer_type.value = contractDraft.offer_type;

    form.meal_included.value = contractDraft.meal_included;
    setMealIncluded(contractDraft.meal_included);

    form.currency_type.value = contractDraft.currency_type;

    setContractType(contractDraft.contract_type);

    // Prices for meals
    if (contractDraft.meal_supplements) {
      for (let sup of contractDraft.meal_supplements) {
        switch (sup.name) {
          case "breakfast":
            setBreakfastChecked(true);
            setBreakfastInitialValue(Number(sup.price));
            break;
          case "lunch":
            setLunchChecked(true);
            setLunchInitialValue(Number(sup.price));
            break;
          case "dinner":
            setDinnerChecked(true);
            setDinnerInitialValue(Number(sup.price));
            break;
          case "soft_all":
            setSoftAllChecked(true);
            setSoftAllInitialValue(Number(sup.price));
            break;
        }
      }
    }

    // Prices for children meals
    if (contractDraft && contractDraft.meal_supplements_children) {
      setChildrenMealsInput(contractDraft?.meal_supplements_children?.length);
      dispatch(fillChildrenMealsFromExistingValues(contractDraft.meal_supplements_children));
    }

    // Specific day supplement
    if (form.supplement) {
      let supplement, supPrice, supDate;
      if (form.supplement[0]) {
        supplement = Array.from(form.supplement);
        supPrice = Array.from(form.supplement_price);
        supDate = Array.from(form.supplement_date);
      } else {
        supplement = [form.supplement];
        supPrice = [form.supplement_price];
        supDate = [form.supplement_date];
      }
      supplement.forEach((elem, index) => {
        contractDraft.one_day_supplements[index]
          ? (elem.value = contractDraft.one_day_supplements[index].name)
          : void 0;
      });
      supPrice.forEach((elem, index) => {
        contractDraft.one_day_supplements[index]
          ? (elem.value = Number(contractDraft.one_day_supplements[index].price))
          : void 0;
      });
      supDate.forEach((elem, index) => {
        contractDraft.one_day_supplements[index]
          ? (elem.value = contractDraft.one_day_supplements[index].date)
          : void 0;
      });
    }

    // Period supplement
    if (form.period_supplement) {
      let supplement, supPrice, startDate, endDate;
      if (form.period_supplement[0]) {
        supplement = Array.from(form.period_supplement);
        supPrice = Array.from(form.period_supplement_price);
        startDate = Array.from(form.period_supplement_start_date);
        endDate = Array.from(form.period_supplement_end_date);
      } else {
        supplement = [form.period_supplement];
        supPrice = [form.period_supplement_price];
        startDate = [form.period_supplement_start_date];
        endDate = [form.period_supplement_end_date];
      }
      supplement.forEach((elem, index) => {
        contractDraft.period_supplements[index] ? (elem.value = contractDraft.period_supplements[index].name) : void 0;
      });
      supPrice.forEach((elem, index) => {
        contractDraft.period_supplements[index]
          ? (elem.value = Number(contractDraft.period_supplements[index].price))
          : void 0;
      });
      startDate.forEach((elem, index) => {
        contractDraft.period_supplements[index]
          ? (elem.value = contractDraft.period_supplements[index].start_date)
          : void 0;
      });

      endDate.forEach((elem, index) => {
        contractDraft.period_supplements[index]
          ? (elem.value = contractDraft.period_supplements[index].end_date)
          : void 0;
      });
    }

    //  Weekend Supplements by person
    if (form.weekend_supplements_per_person_sat) {
      const groupedData = groupByNumAdults(contractDraft.weekend_supplements_per_person);

      Object.keys(groupedData).forEach((numAdults, index) => {
        if (form[`weekend_supplements_per_person_sat`][index]) {
          form[`weekend_supplements_per_person_adults`][index].value = numAdults;

          groupedData[numAdults].forEach((entry) => {
            switch (entry.day) {
              case "sat":
                form[`weekend_supplements_per_person_sat`][index].value = entry.price;
                break;
              case "sun":
                form[`weekend_supplements_per_person_sun`][index].value = entry.price;
                break;
              case "mon":
                form[`weekend_supplements_per_person_mon`][index].value = entry.price;
                break;
              case "tue":
                form[`weekend_supplements_per_person_tue`][index].value = entry.price;
                break;
              case "wed":
                form[`weekend_supplements_per_person_wed`][index].value = entry.price;
                break;
              case "thu":
                form[`weekend_supplements_per_person_thu`][index].value = entry.price;
                break;
              case "fri":
                form[`weekend_supplements_per_person_fri`][index].value = entry.price;
                break;
              default:
                break;
            }
          });
        } else {
          // Handle case when there's no initial data (empty form)
          form[`weekend_supplements_per_person_adults`].value = numAdults;

          groupedData[numAdults].forEach((entry) => {
            form[`weekend_supplements_per_person_${entry.day}`].value = entry.price;
          });
        }
      });
    }

    // Weekend Supplement per person
    if (form.period_weekend_supplements_per_person_start_date) {
      if (form.period_weekend_supplements_per_person_start_date[0]) {
        contractDraft.period_weekend_supplements_per_person.forEach((entry, index) => {
          form.period_weekend_supplements_per_person_start_date[index].value = entry.start_date;
          form.period_weekend_supplements_per_person_end_date[index].value = entry.end_date;

          entry.day_prices_per_person.forEach((dayPrice) => {
            form["period_weekend_supplements_per_person_adults"][index].value = dayPrice.num_adults;

            switch (dayPrice.day) {
              case "sat":
                form[`period_weekend_supplements_per_person_sat`][index].value = dayPrice.price;
                break;
              case "sun":
                form[`period_weekend_supplements_per_person_sun`][index].value = dayPrice.price;
                break;
              case "mon":
                form[`period_weekend_supplements_per_person_mon`][index].value = dayPrice.price;
                break;
              case "tue":
                form[`period_weekend_supplements_per_person_tue`][index].value = dayPrice.price;
                break;
              case "wed":
                form[`period_weekend_supplements_per_person_wed`][index].value = dayPrice.price;
                break;
              case "thu":
                form[`period_weekend_supplements_per_person_thu`][index].value = dayPrice.price;
                break;
              case "fri":
                form[`period_weekend_supplements_per_person_fri`][index].value = dayPrice.price;
                break;
              default:
                break;
            }
          });
        });
      } else {
        const entry = contractDraft.period_weekend_supplements_per_person[0];
        form.period_weekend_supplements_per_person_start_date.value = entry.start_date;
        form.period_weekend_supplements_per_person_end_date.value = entry.end_date;

        entry.day_prices_per_person.forEach((dayPrice) => {
          form["period_weekend_supplements_per_person_adults"].value = dayPrice.num_adults;

          switch (dayPrice.day) {
            case "sat":
              form[`period_weekend_supplements_per_person_sat`].value = dayPrice.price;
              break;
            case "sun":
              form[`period_weekend_supplements_per_person_sun`].value = dayPrice.price;
              break;
            case "mon":
              form[`period_weekend_supplements_per_person_mon`].value = dayPrice.price;
              break;
            case "tue":
              form[`period_weekend_supplements_per_person_tue`].value = dayPrice.price;
              break;
            case "wed":
              form[`period_weekend_supplements_per_person_wed`].value = dayPrice.price;
              break;
            case "thu":
              form[`period_weekend_supplements_per_person_thu`].value = dayPrice.price;
              break;
            case "fri":
              form[`period_weekend_supplements_per_person_fri`].value = dayPrice.price;
              break;
            default:
              break;
          }
        });
      }
    }

    // Weekend supplements
    if (contractDraft.weekend_supplements) {
      for (let weekSup of contractDraft.weekend_supplements) {
        form[`weekend_supplement_${weekSup.name.toLowerCase()}`].value = Number(weekSup.price);
      }
    }

    // Period Weekend supplements
    if (form.period_weekend_supplement_start_date) {
      let startDates, endDates, satPrices, sunPrices, monPrices, tuePrices, wedPrices, thuPrices, friPrices;
      if (form.period_weekend_supplement_start_date[0]) {
        startDates = Array.from(form.period_weekend_supplement_start_date);
        endDates = Array.from(form.period_weekend_supplement_end_date);
        satPrices = Array.from(form.period_weekend_supplement_sat);
        sunPrices = Array.from(form.period_weekend_supplement_sun);
        monPrices = Array.from(form.period_weekend_supplement_mon);
        tuePrices = Array.from(form.period_weekend_supplement_tue);
        wedPrices = Array.from(form.period_weekend_supplement_wed);
        thuPrices = Array.from(form.period_weekend_supplement_thu);
        friPrices = Array.from(form.period_weekend_supplement_fri);
      } else {
        startDates = [form.period_weekend_supplement_start_date];
        endDates = [form.period_weekend_supplement_end_date];
        satPrices = [form.period_weekend_supplement_sat];
        sunPrices = [form.period_weekend_supplement_sun];
        monPrices = [form.period_weekend_supplement_mon];
        tuePrices = [form.period_weekend_supplement_tue];
        wedPrices = [form.period_weekend_supplement_wed];
        thuPrices = [form.period_weekend_supplement_thu];
        friPrices = [form.period_weekend_supplement_fri];
      }

      startDates.forEach((elem, index) => {
        if (contractDraft.period_weekend_supplements[index]) {
          elem.value = contractDraft.period_weekend_supplements[index].start_date;
        }
      });

      endDates.forEach((elem, index) => {
        if (contractDraft.period_weekend_supplements[index]) {
          elem.value = contractDraft.period_weekend_supplements[index].end_date;
        }
      });

      const dayMapping = {
        sat: satPrices,
        sun: sunPrices,
        mon: monPrices,
        tue: tuePrices,
        wed: wedPrices,
        thu: thuPrices,
        fri: friPrices,
      };

      const daysOfWeek = ["sat", "sun", "mon", "tue", "wed", "thu", "fri"];

      daysOfWeek.forEach((day) => {
        const priceArray = dayMapping[day];
        priceArray.forEach((elem, index) => {
          if (contractDraft.period_weekend_supplements[index]) {
            const dayPrice = contractDraft.period_weekend_supplements[index].day_prices.find(
              (price) => price.day === day,
            );
            if (dayPrice) {
              elem.value = dayPrice.price;
            }
          }
        });
      });
    }

    form.commission_type.value = contractDraft.commission_type;
    contractDraft.commission_price ? (form.commission_price.value = Number(contractDraft.commission_price)) : void 0;

    form.cancellation_policy.value = contractDraft.cancellation_policy;
    form.notes.value = contractDraft.notes;

    if (contractDraft && contractDraft.reservation_policy) {
      form.reservation_policy.value = contractDraft.reservation_policy;
    }

    if (contractDraft && contractDraft.same_day_reservation_price) {
      form.same_day_reservation_price.value = Number(contractDraft.same_day_reservation_price);
    }

    // Draft
    setDateRange({
      startDate: contractDraft.booking_valid_from,
      endDate: contractDraft.booking_valid_to,
    });

    form.min.value = contractDraft?.price_policy_minimum_days;
    form.max.value = contractDraft?.price_policy_maximum_days;

    setIsDraft(true);
    setOpenDraftModal(false);
  };

  if (!permissionFlag || isFetchingDraftData) {
    return <Loading />;
  }

  if (permissionsError) {
    return <Errorfetch Error={"Error fetching permissions"} />;
  }

  return isAddingContractAllowed ? (
    <>
      <Modal
        theme={{
          footer: {
            base: "flex items-center space-x-2 rounded-b border-gray-200 p-6 justify-center",
          },
        }}
        show={openDraftModal}
        onClose={() => setOpenDraftModal(false)}
      >
        {/*<Modal.Header>What to do with contract draft ?</Modal.Header>*/}
        <Modal.Body>
          <p className="text-xl font-semibold text-center leading-relaxed">
            You have a draft of a contract that you can load or delete.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isDeletingDraft} isProcessing={isDeletingDraft} color={"success"} onClick={loadDraft}>
            Load Draft
          </Button>
          <Button
            disabled={isDeletingDraft}
            isProcessing={isDeletingDraft}
            color="failure"
            onClick={() => {
              deleteDraft();
              setTimerOffsetTimestamp(0);
              setTimerAutoStart(true);
              setIsDraft(true);
              setOneDaySupplementsInput(0);
              setPeriodSupplementInputsNum(0);
              setPeriodWeekendSupplementInputsNum(0);
              setPeriodWeekendSupplementPerPersonInputsNum(0);
              dispatch(clearOldValues());
            }}
          >
            Delete Draft
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="formPage-wrap">
        <div className="w-full ">
          <div className="w-full flex items-center justify-between h-14  bg-[#002244] rounded-md md:px-10 px-4">
            <div className=" flex items-center justify-start gap-x-1 px-1 text-white ">
              <p className="flex items-center gap-x-1  md:text-2xl text-xl font-normal ">
                {" "}
                <LiaFileContractSolid className="md:text-2xl text-lg" />
                Add Contract
              </p>
            </div>
            <div className="flex items-center text-2xl justify-start gap-x-2">
              <RiTimerLine className="w-5 h-5 text-white" />
              <Timer
                onTick={(totalSeconds) => {
                  timerTotalSecondsRef.current = totalSeconds;
                }}
                offsetTimestamp={timerOffsetTimestamp}
                autoStart={timerAutoStart}
              />
            </div>
          </div>

          <form
            onInvalid={(e) => {
              setIsDraft(true);
            }}
            method={"post"}
            className="w-full mt-1 flex flex-col gap-y-10 py-3 px-5 border bg-gray-50 p-2 rounded-md"
            onSubmit={(e) => {
              if (isDraft) {
                handleDraft(e);
                return;
              }
              handleSubmit(e);
            }}
            ref={formRef}
          >
            <div className="w-full flex flex-col gap-y-4">
              <div className="w-full flex lg:flex-row flex-col items-center justify-center gap-x-10">
                <div className="lg:w-[50%] w-full">
                  <StaticFromInput
                    name="name"
                    text="Contract Name"
                    type="text"
                    placeholder="Enter contract name"
                    icon={<IoNewspaper className="w-5 h-5" />}
                  />
                </div>
                <div className="lg:w-[50%] w-full">
                  <label className=" font-semibold flex items-center text-center gap-1 text-[#002244]">
                    <FaTreeCity className="w-4 h-4" /> Select City
                  </label>
                  <Select
                    name="city"
                    options={CitiesArr}
                    className="w-full h-10 bg-white capitalize"
                    onChange={(e) => {
                      const selectedCityTimeZone = Cities?.find((city) => {
                        const chosenCityId = e.value;

                        return city?.id === Number(chosenCityId);
                      })?.timezone;

                      setDateRange((dateRange) => {
                        return {
                          startDate: dateRange.startDate
                            ? dayjs(dateRange.startDate).tz(selectedCityTimeZone).format()
                            : null,
                          endDate: dateRange.endDate
                            ? dayjs(dateRange.endDate).tz(selectedCityTimeZone).format()
                            : null,
                        };
                      });
                      setCitiesChoice(e.value);
                    }}
                    value={CitiesArr?.find((city) => city?.value === CitiesChoice)}
                    required
                    placeholder="Select Option"
                  />
                </div>

                <div className="lg:w-[50%] w-full">
                  <label className=" font-semibold flex items-center text-center gap-1 text-[#002244]">
                    <FaHotel className="w-4 h-4" /> Hotels
                  </label>

                  {/* <Resizable height={height} onResize={(e, data) => setHeight(data.size.height)}> */}
                  <Select
                    options={
                      !hotelfiltersArr ? [] : hotelfiltersArr
                      // if select all , options will be hidden
                    }
                    className={`w-full ${chosenHotels?.length >= 7 ? "h-24" : "h-10"}  resize-y bg-white capitalize `}
                    required
                    value={chosenHotels}
                    onChange={(e) => {
                      setChosenHotels(e);
                      setRooms(e);
                    }}
                    // isClearable
                    placeholder="Choose Hotel name"
                    icon={<FaHotel className="w-5 h-5" />}
                    name="hotel"
                    text="Hotel name"
                    isLoading={hotelfilterLoading}
                  />
                </div>
              </div>
              <div className="w-full flex lg:flex-row flex-col items-center justify-center gap-x-10">
                <div className="lg:w-[50%] w-full">
                  <StaticFormSelect
                    name="market"
                    text="Market Type"
                    options={marketTypes}
                    placeholder="Choose Market Type"
                    icon={<FaLocationCrosshairs className="w-5 h-5" />}
                  />
                </div>
                <div className="lg:w-[50%] w-full">
                  <StaticFormSelect
                    name="offer_type"
                    text="Offer Type"
                    options={offerTypes}
                    placeholder="Choose Offer Type"
                    icon={<BiSolidOffer className="w-5 h-5" />}
                  />
                </div>
              </div>
              <div className="w-full flex lg:flex-row flex-col items-center justify-center gap-x-10">
                <div className="lg:w-[50%] w-full">
                  <StaticFormSelect
                    contractCallBack={(e, meal) => {
                      setMealIncluded(meal);
                      if (meal === "b.b") {
                        setBreakfastChecked(false);
                      }

                      if (meal === "h.b") {
                        setBreakfastChecked(false);
                        setDinnerChecked(false);
                      }

                      if (meal === "f.b") {
                        setBreakfastChecked(false);
                        setLunchChecked(false);
                        setDinnerChecked(false);
                      }

                      if (meal === "s.a") {
                        setBreakfastChecked(false);
                        setLunchChecked(false);
                        setDinnerChecked(false);
                        setSoftAllChecked(false);
                      }
                    }}
                    name="meal_included"
                    text="Meal Included"
                    options={mealsIncludedArr}
                    placeholder="Choose included meal"
                    icon={<GiMeal className="w-5 h-5 mb-1" />}
                  />
                </div>
                <div className="lg:w-[50%] w-full">
                  <StaticFormSelect
                    name="currency_type"
                    text="Currency Type"
                    options={currencyTypesArr}
                    placeholder="Choose currency type"
                    icon={<BsCurrencyExchange className="w-5 h-5" />}
                  />
                </div>
              </div>
            </div>
            <div>
              <StaticFormSelect
                contractCallBack={(e, contractType) => {
                  setContractType(contractType);
                  dispatch(clearOldValues());
                  dispatch(clearChildrenMealOldValues());
                }}
                defaultValue={contractType}
                value={contractType}
                name={"contract_type"}
                text={"Contract Type"}
                options={[
                  {
                    id: "per_person",
                    name: "Per Person",
                  },
                  {
                    id: "per_room",
                    name: "Per Room",
                  },
                ]}
                placeholder="Choose contract type"
                icon={<IoDocumentTextSharp className="w-5 h-5" />}
              />
            </div>
            {contractType === "per_person" && (
              <>
                <div className="w-full flex flex-col gap-y-1 ">
                  <div className="w-full flex  items-center gap-x-1 text-slate-900 font-semibold">
                    <RiRestaurantFill className="w-5 h-5 text-slate-800" /> <p>Prices For Meals</p>
                  </div>
                  <div className="w-full grid md:grid-cols-4 grid-cols-2 items-center justify-around  border rounded-md p-3">
                    <DynamicCheckbox
                      disabled={
                        mealIncluded === "b.b" ||
                        mealIncluded === "h.b" ||
                        mealIncluded === "f.b" ||
                        mealIncluded === "s.a" ||
                        mealIncluded === ""
                      }
                      setCheckedByParent={setBreakfastChecked}
                      checkedByParent={breakfastChecked}
                      name="breakfast"
                      text="Breakfast"
                      value={breakfastInitialValue}
                    />
                    <DynamicCheckbox
                      disabled={mealIncluded === "f.b" || mealIncluded === "s.a" || mealIncluded === ""}
                      setCheckedByParent={setLunchChecked}
                      checkedByParent={lunchChecked}
                      name="lunch"
                      text="Lunch"
                      value={lunchInitialValue}
                    />
                    <DynamicCheckbox
                      disabled={
                        mealIncluded === "h.b" ||
                        mealIncluded === "f.b" ||
                        mealIncluded === "s.a" ||
                        mealIncluded === ""
                      }
                      setCheckedByParent={setDinnerChecked}
                      checkedByParent={dinnerChecked}
                      name="dinner"
                      text="Dinner"
                      value={dinnerInitialValue}
                    />
                    <DynamicCheckbox
                      disabled={mealIncluded === "s.a" || mealIncluded === ""}
                      setCheckedByParent={setSoftAllChecked}
                      checkedByParent={softAllChecked}
                      name="softAll"
                      text="Soft All"
                      value={softAllInitialValue}
                    />
                  </div>
                </div>

                <div className="w-full flex flex-col gap-y-2">
                  <div className="w-full flex flex-col items-start gap-x-1 ">
                    <div className="flex gap-1">
                      <MdDescription className="w-5 h-5 text-slate-900" />
                      <p className="text-slate-800 font-semibold">Prices for Children Meals</p>
                      <div className="flex items-center gap-x-1 ">
                        <button
                          type="button"
                          onClick={() => {
                            if (!breakfastChecked && !lunchChecked && !dinnerChecked && !softAllChecked) {
                              toast.error("Please select at least one meal in order to add children meals");
                              return;
                            }

                            setChildrenMealsInput((c) => c + 1);
                            dispatch(addNewChildMeal());
                          }}
                          className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800 "
                        >
                          <MdAddCircleOutline className="w-5 h-5 " />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setChildrenMealsInput((c) => {
                              if (c === 0) return;
                              return c - 1;
                            });
                            dispatch(removeChildMeal());
                          }}
                          className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800 "
                        >
                          <BiMinusCircle className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                    {Array.from({ length: childrenMealsInput }, (_, i) => i).map((elem, index) => {
                      return (
                        <div className="mt-4 w-full flex flex-col gap-4 ">
                          <div className=" w-full flex justify-between items-center ">
                            <StaticFormSelect
                              defaultValue={contractChildrenMeals[index]?.ordering_child}
                              name="child_order"
                              text="Child Order"
                              options={childRoomTypes}
                              placeholder="Select Child Order"
                              contractCallBack={(e, order) => {
                                // Clear the input fields if the user tries to change the order
                                e.target.parentElement.parentElement.querySelector("[name='child_start_age']").value =
                                  null;
                                e.target.parentElement.parentElement.querySelector("[name='child_end_age']").value =
                                  null;

                                dispatch(addMealChildStartAgeValue(index, ""));
                                dispatch(addMealChildEndAgeValue(index, ""));

                                const parentElement = e.target.parentElement.parentElement.parentElement.parentElement;
                                const orders = parentElement.querySelectorAll("[name='child_order']");
                                const ordersArray = Array.from(orders)
                                  .map((orderElement) => Number(orderElement.value))
                                  .filter((o) => o !== 0);

                                const orderNum = Number(order);

                                if (orderNum !== 1 && !ordersArray.includes(orderNum - 1)) {
                                  toast.error(`You must add the previous child order first`);
                                  e.target.value = null;
                                  e.target.parentElement.parentElement.querySelector("[name='child_start_age']").value =
                                    null;
                                  e.target.parentElement.parentElement.querySelector("[name='child_end_age']").value =
                                    null;
                                  dispatch(addMealChildStartAgeValue(index, ""));
                                  dispatch(addMealChildEndAgeValue(index, ""));
                                  dispatch(addMealChildOrder(index, ""));

                                  return;
                                }

                                dispatch(addMealChildOrder(index, Number(order)));
                              }}
                            />

                            <StaticFormSelect
                              defaultValue={contractChildrenMeals[index]?.start_age}
                              options={ageSelectOptions}
                              name="child_start_age"
                              disabled={!contractChildrenMeals[index]?.ordering_child}
                              text="Min age"
                              type="number"
                              canDefaultValueZero={true}
                              placeholder="Min age"
                              contractCallBack={(e, age) => {
                                const maxAge = contractChildrenMeals[index].end_age;

                                // 1) Validation num 1
                                // Min age must be less than max age

                                if (age >= maxAge && maxAge !== null && maxAge !== "") {
                                  toast.error("The minimum age must be less than the maximum age.");
                                  e.target.value = null;
                                  dispatch(addMealChildStartAgeValue(index, ""));

                                  return;
                                }

                                // 2) Validation num 2
                                // No conflict with the same child order
                                const order = contractChildrenMeals[index].ordering_child;
                                const conflictingAge = contractChildrenMeals.find(
                                  (meal) =>
                                    meal.ordering_child === order && age >= meal.start_age && age <= meal.end_age,
                                );

                                if (conflictingAge) {
                                  toast.error(
                                    "The minimum age conflicts with an existing range for the same child order.",
                                  );
                                  // TODO: Reset the max age as well
                                  e.target.value = null;
                                  // e.target.parentElement.querySelector("[name='child_end_age']");

                                  dispatch(addMealChildStartAgeValue(index, ""));
                                  dispatch(addMealChildEndAgeValue(index, ""));

                                  const parentElement = e.target.parentElement.parentElement;
                                  const maxAgeInput = parentElement.querySelector("[name='child_end_age']");

                                  if (maxAgeInput) {
                                    maxAgeInput.value = null;
                                  }

                                  return;
                                }

                                dispatch(addMealChildStartAgeValue(index, Number(age)));
                              }}
                            />

                            <StaticFormSelect
                              defaultValue={contractChildrenMeals[index]?.end_age}
                              options={ageSelectOptions}
                              disabled={
                                !contractChildrenMeals[index]?.ordering_child ||
                                contractChildrenMeals[index]?.start_age === ""
                              }
                              name="child_end_age"
                              text="Max age"
                              type="number"
                              placeholder="Max age"
                              contractCallBack={(e, age) => {
                                const order = contractChildrenMeals[index].ordering_child;

                                // 1) Validation num 1
                                // Max age must be greater than min age
                                const minAge = contractChildrenMeals[index].start_age;

                                if (age <= minAge && minAge !== null && minAge !== "") {
                                  toast.error("The maximum age must be greater than the minimum age.");
                                  e.target.value = null;
                                  dispatch(addMealChildEndAgeValue(index, ""));

                                  return;
                                }

                                // 2) Validation num 2
                                // No conflict with the same child order
                                const conflictingAge = contractChildrenMeals.find(
                                  (child) =>
                                    child.ordering_child === order && age >= child.start_age && age <= child.end_age,
                                );

                                if (conflictingAge) {
                                  toast.error(
                                    "The maximum age conflicts with an existing range for the same child order.",
                                  );
                                  e.target.value = null;
                                  dispatch(addMealChildEndAgeValue(index, ""));
                                  dispatch(addMealChildStartAgeValue(index, ""));
                                  const parentElement = e.target.parentElement.parentElement;
                                  const maxAgeInput = parentElement.querySelector("[name='child_start_age']");
                                  if (maxAgeInput) {
                                    maxAgeInput.value = null;
                                  }
                                  return;
                                }
                                dispatch(addMealChildEndAgeValue(index, Number(age)));
                              }}
                              canDefaultValueZero={true}
                            />
                          </div>
                          <div className="w-full gap-2 grid md:grid-cols-4 grid-cols-2 items-center justify-around  border rounded-md p-3">
                            <StaticFromInput
                              defaultValue={
                                contractChildrenMeals[index]?.meal_supplements_children_period?.find(
                                  (meal) => meal.name === "breakfast",
                                )?.price
                              }
                              placeholder="Breakfast"
                              name="child_breakfast"
                              text="Child Breakfast"
                              type="number"
                              disabled={!breakfastChecked}
                              contractCallBack={(e, price) => {
                                dispatch(addChildMeal(index, "breakfast", Number(price)));
                              }}
                            />

                            <StaticFromInput
                              defaultValue={
                                contractChildrenMeals[index]?.meal_supplements_children_period?.find(
                                  (meal) => meal.name === "lunch",
                                )?.price
                              }
                              placeholder="Lunch"
                              name="child_lunch"
                              text="Child Lunch"
                              type="number"
                              disabled={!lunchChecked}
                              contractCallBack={(e, price) => {
                                dispatch(addChildMeal(index, "lunch", Number(price)));
                              }}
                            />

                            <StaticFromInput
                              defaultValue={
                                contractChildrenMeals[index]?.meal_supplements_children_period?.find(
                                  (meal) => meal.name === "dinner",
                                )?.price
                              }
                              placeholder="Dinner"
                              name="child_dinner"
                              text="Child Dinner"
                              type="number"
                              disabled={!dinnerChecked}
                              contractCallBack={(e, price) => {
                                dispatch(addChildMeal(index, "dinner", Number(price)));
                              }}
                            />

                            <StaticFromInput
                              defaultValue={
                                contractChildrenMeals[index]?.meal_supplements_children_period?.find(
                                  (meal) => meal.name === "soft_all",
                                )?.price
                              }
                              placeholder="Soft All"
                              name="child_soft_all"
                              text="Child Soft All"
                              type="number"
                              disabled={!softAllChecked}
                              contractCallBack={(e, price) => {
                                dispatch(addChildMeal(index, "soft_all", Number(price)));
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}

            <div className="w-full flex flex-col gap-y-2">
              <div className="w-full flex items-start gap-x-1 ">
                <MdDescription className="w-5 h-5 text-slate-900" />
                <MultiDynamicInputs
                  className="flex-wrap gap-y-4"
                  inputsNum={oneDaySupplementsInput}
                  heading="Specific Day Supplement"
                  inputsList={[
                    <StaticFromInput name="supplement" text="Supplement" type="text" placeholder="Supplement" />,
                    <StaticFromInput name="supplement_price" text="Price" type="number" placeholder="Price" />,
                    <StaticFromInput name="supplement_date" text="Date" type="date" placeholder="DD/MM/YYYY" />,
                  ]}
                  extraInputsComponent={(length, updateLength) => (
                    <div className="border rounded-md py-3 px-10">
                      <div className="w-full flex items-center justify-start gap-x-3 mb-4">
                        <p className="text-slate-800 font-semibold">Specific Day Supplement for children</p>
                        <button
                          type="button"
                          onClick={() => updateLength(length + 1)}
                          className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800"
                        >
                          <MdAddCircleOutline className="w-5 h-5" />
                        </button>

                        <button
                          type="button"
                          onClick={() => length > 0 && updateLength(length - 1)}
                          className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800"
                        >
                          <BiMinusCircle className="w-5 h-5" />
                        </button>
                      </div>
                      {Array.from({ length }).map((_, childIndex) => (
                        <div
                          key={childIndex}
                          className="w-full flex items-center justify-between gap-x-3 border p-4 mb-4"
                        >
                          <StaticFormSelect
                            name={`supplement_child_start_age`}
                            text="Start Age"
                            options={Array.from({ length: 12 }, (_, i) => ({ id: i, name: i }))}
                          />
                          <StaticFormSelect
                            name={`supplement_child_end_age`}
                            text="End Age"
                            options={Array.from({ length: 12 }, (_, i) => ({ id: i, name: i }))}
                          />
                          <StaticFromInput name={`supplement_child_price`} text="Price" type="number" />
                        </div>
                      ))}
                    </div>
                  )}
                  extraInputsLengthList={[]}
                />
              </div>
            </div>

            <div className="w-full flex flex-col gap-y-2">
              <div className="w-full flex items-start gap-x-1 ">
                <MdDescription className="w-5 h-5 text-slate-900" />
                <MultiDynamicInputs
                  className="flex-wrap gap-y-4"
                  inputsNum={periodSupplementInputsNum}
                  heading="Period Supplement"
                  inputsList={[
                    <StaticFromInput name="period_supplement" text="Supplement" type="text" placeholder="Supplement" />,
                    <StaticFromInput name="period_supplement_price" text="Price" type="number" placeholder="Price" />,
                    <StaticFromInput
                      name="period_supplement_start_date"
                      text="From Date"
                      type="date"
                      placeholder="DD/MM/YYYY"
                    />,
                    <StaticFromInput
                      name="period_supplement_end_date"
                      text="To Date"
                      type="date"
                      placeholder="DD/MM/YYYY"
                    />,
                  ]}
                  icon={<MdOutlineDescription className="w-5 h-5 text-slate-800" />}
                  extraInputsComponent={(length, updateLength) => (
                    <div className="border rounded-md py-3 px-10">
                      <div className="w-full flex items-center justify-start gap-x-3 mb-4">
                        <p className="text-slate-800 font-semibold">Period Supplement for children</p>
                        <button
                          type="button"
                          onClick={() => updateLength(length + 1)}
                          className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800"
                        >
                          <MdAddCircleOutline className="w-5 h-5" />
                        </button>

                        <button
                          type="button"
                          onClick={() => length > 0 && updateLength(length - 1)}
                          className="cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-800"
                        >
                          <BiMinusCircle className="w-5 h-5" />
                        </button>
                      </div>
                      {Array.from({ length }).map((_, childIndex) => (
                        <div
                          key={childIndex}
                          className="w-full flex items-center justify-between gap-x-3 border p-4 mb-4"
                        >
                          <StaticFormSelect
                            name={`period_supplement_child_start_age`}
                            text="Start Age"
                            options={Array.from({ length: 12 }, (_, i) => ({ id: i, name: i }))}
                          />
                          <StaticFormSelect
                            name={`period_supplement_child_end_age`}
                            text="End Age"
                            options={Array.from({ length: 12 }, (_, i) => ({ id: i, name: i }))}
                          />
                          <StaticFromInput name={`period_supplement_child_price`} text="Price" type="number" />
                        </div>
                      ))}
                    </div>
                  )}
                  extraInputsLengthList={[]}
                />{" "}
              </div>
            </div>
            <div className="w-full  flex flex-col gap-y-2">
              <div className="w-full flex justify-between items-end">
                <div className="flex items-center gap-x-1 grow text-slate-900">
                  <MdOutlineBedroomChild className="w-5 h-5 text-slate-800" />
                  <h1 className="text-gray-800 font-semibold">Room Contract</h1>
                </div>

                {contractType === "per_person" && (
                  <div className="flex flex-col gap-1">
                    <ContractAssistanceTool hotelRooms={hotelRoomsArr} />
                    <ChildrenPolicyAssistantTool hotelRooms={hotelRoomsArr} />
                  </div>
                )}
              </div>

              <ContractFormDynamicComponentParent
                hotelRoomsArr={hotelRoomsArr}
                contractValidity={contractValidity}
                roomFlag={roomFlag}
                validities={contractValidity}
                contractType={contractType}
                isAddingPackageAllowed={isAddingPackageAllowed}
              />
            </div>
            <div className="w-full  flex flex-col gap-y-2 ">
              <div className="w-full flex items-center gap-x-1 text-slate-900">
                <IoCalendarSharp className="w-5 h-5 text-slate-800 " />
                <h2 className=" font-semibold">Weekend Supplements</h2>
              </div>

              <div className="grid grid-cols-7 gap-4 border rounded-md p-2">
                <StaticFromInput
                  name="weekend_supplement_sat"
                  text="Sat"
                  type="number"
                  required={false}
                  className="i15vw"
                />
                <StaticFromInput
                  name="weekend_supplement_sun"
                  text="Sun"
                  type="number"
                  required={false}
                  className="i15vw"
                />
                <StaticFromInput
                  name="weekend_supplement_mon"
                  text="Mon"
                  type="number"
                  required={false}
                  className="i15vw"
                />
                <StaticFromInput
                  name="weekend_supplement_tue"
                  text="Tue"
                  type="number"
                  required={false}
                  className="i15vw"
                />
                <StaticFromInput
                  name="weekend_supplement_wed"
                  text="Wed"
                  type="number"
                  required={false}
                  className="i15vw"
                />
                <StaticFromInput
                  name="weekend_supplement_thu"
                  text="Thu"
                  type="number"
                  required={false}
                  className="i15vw"
                />
                <StaticFromInput
                  name="weekend_supplement_fri"
                  text="Fri"
                  type="number"
                  required={false}
                  className="i15vw"
                />
              </div>
            </div>

            <div className="w-full flex flex-col gap-y-2">
              <div className="w-full flex items-start gap-x-1 ">
                <MdDescription className="w-5 h-5 text-slate-900" />
                <MultiDynamicInputs
                  inputsNum={periodWeekendSupplementInputsNum}
                  heading="Period Weekend Supplements"
                  inputsList={[
                    <div className="flex gap-3 items-center">
                      <div className="flex gap-4 items-center">
                        <StaticFromInput
                          name="period_weekend_supplement_start_date"
                          text="From Date"
                          type="date"
                          placeholder="DD/MM/YYYY"
                        />

                        <StaticFromInput
                          name="period_weekend_supplement_end_date"
                          text="To Date"
                          type="date"
                          placeholder="DD/MM/YYYY"
                        />
                      </div>

                      <div className="grid grid-cols-7 gap-4 border rounded-md p-2">
                        <StaticFromInput
                          name="period_weekend_supplement_sat"
                          text="Sat"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                        <StaticFromInput
                          name="period_weekend_supplement_sun"
                          text="Sun"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                        <StaticFromInput
                          name="period_weekend_supplement_mon"
                          text="Mon"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                        <StaticFromInput
                          name="period_weekend_supplement_tue"
                          text="Tue"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                        <StaticFromInput
                          name="period_weekend_supplement_wed"
                          text="Wed"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                        <StaticFromInput
                          name="period_weekend_supplement_thu"
                          text="Thu"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                        <StaticFromInput
                          name="period_weekend_supplement_fri"
                          text="Fri"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                      </div>
                    </div>,
                  ]}
                  icon={<MdOutlineDescription className="w-5 h-5 text-slate-800" />}
                />{" "}
              </div>
            </div>
            {contractType === "per_person" && (
              <div className="w-full flex flex-col gap-y-2">
                <div className="w-full flex items-start gap-x-1 ">
                  <MdDescription className="w-5 h-5 text-slate-900" />
                  <MultiDynamicInputs
                    inputsNum={weekendSupplementsPerPersonInputsNum}
                    heading="Weekend Supplements Per Person"
                    inputsList={[
                      <div className="flex gap-6 items-center">
                        <div className="w-[235px]">
                          <StaticFormSelect
                            name="weekend_supplements_per_person_adults"
                            text="Adults Order"
                            options={Array.from({ length: 12 }, (_, i) => ({ id: i + 1, name: i + 1 }))}
                            placeholder="Adults Order"
                            func={(selectedAdult) => {}}
                          />
                        </div>
                        <StaticFromInput
                          name="weekend_supplements_per_person_sat"
                          text="Sat"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                        <StaticFromInput
                          name="weekend_supplements_per_person_sun"
                          text="Sun"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                        <StaticFromInput
                          name="weekend_supplements_per_person_mon"
                          text="Mon"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                        <StaticFromInput
                          name="weekend_supplements_per_person_tue"
                          text="Tue"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                        <StaticFromInput
                          name="weekend_supplements_per_person_wed"
                          text="Wed"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                        <StaticFromInput
                          name="weekend_supplements_per_person_thu"
                          text="Thu"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                        <StaticFromInput
                          name="weekend_supplements_per_person_fri"
                          text="Fri"
                          type="number"
                          required={false}
                          className="i15vw"
                        />
                      </div>,
                    ]}
                    icon={<MdOutlineDescription className="w-5 h-5 text-slate-800" />}
                  />{" "}
                </div>
              </div>
            )}
            {contractType === "per_person" && (
              <div className="w-full flex flex-col gap-y-2">
                <div className="w-full flex items-start gap-x-1 ">
                  <MdDescription className="w-5 h-5 text-slate-900" />
                  <MultiDynamicInputs
                    inputsNum={periodWeekendSupplementPerPersonInputsNum}
                    heading="Period Weekend Supplements Per Person"
                    inputsList={[
                      <div className="flex gap-3 items-center">
                        <div className="flex gap-4 items-center">
                          <div className="w-[180px]">
                            <StaticFormSelect
                              name="period_weekend_supplements_per_person_adults"
                              text="Adults Order"
                              options={Array.from({ length: 12 }, (_, i) => ({ id: i + 1, name: i + 1 }))}
                              placeholder="Adults Order"
                              func={(selectedAdult) => {}}
                            />
                          </div>

                          <StaticFromInput
                            name="period_weekend_supplements_per_person_start_date"
                            text="From Date"
                            type="date"
                            placeholder="DD/MM/YYYY"
                          />

                          <StaticFromInput
                            name="period_weekend_supplements_per_person_end_date"
                            text="To Date"
                            type="date"
                            placeholder="DD/MM/YYYY"
                          />
                        </div>

                        <div className="grid grid-cols-7 gap-4 border rounded-md p-2">
                          <StaticFromInput
                            name="period_weekend_supplements_per_person_sat"
                            text="Sat"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="period_weekend_supplements_per_person_sun"
                            text="Sun"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="period_weekend_supplements_per_person_mon"
                            text="Mon"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="period_weekend_supplements_per_person_tue"
                            text="Tue"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="period_weekend_supplements_per_person_wed"
                            text="Wed"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="period_weekend_supplements_per_person_thu"
                            text="Thu"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                          <StaticFromInput
                            name="period_weekend_supplements_per_person_fri"
                            text="Fri"
                            type="number"
                            required={false}
                            className="i15vw"
                          />
                        </div>
                      </div>,
                    ]}
                    icon={<MdOutlineDescription className="w-5 h-5 text-slate-800" />}
                  />{" "}
                </div>
              </div>
            )}
            <div className="w-full  flex flex-col gap-y-2 ">
              <div className="w-full flex items-center gap-x-1 text-slate-900">
                <FaPercent className="w-4 h-4 text-slate-800" />
                <h2 className=" font-semibold">Commission</h2>
              </div>
              <div className="grid grid-cols-2 gap-4 border rounded-md p-2">
                <StaticFormSelect
                  name="commission_type"
                  text="Type"
                  options={[
                    { id: "fixed price", name: "Fixed Price" },
                    { id: "percentage", name: "Percentage" },
                  ]}
                  placeholder="Choose a commission type"
                  func={makeAmountRequired}
                  required={false}
                />
                <StaticFromInput
                  name="commission_price"
                  text="Amount"
                  type="number"
                  placeholder="Amount"
                  required={isAmountReq}
                />
              </div>
            </div>
            <div className="w-full  flex flex-col gap-y-2 ">
              <div className="w-full flex items-center gap-x-1 text-slate-900">
                <MdDateRange className="w-5 h-5 text-slate-800 " />
                <h2 className="text-slate-900 font-semibold">Booking Window Date</h2>
              </div>

              {CitiesChoice ? (
                <div className="w-[30%]" key={selectedCityTimeZone}>
                  <RangePicker
                    required
                    showTime={{
                      format: "HH",
                    }}
                    format="YYYY-MM-DD HH"
                    defaultValue={[
                      contractDraft?.booking_valid_from
                        ? dayjs(contractDraft.booking_valid_from).tz(selectedCityTimeZone)
                        : null,
                      contractDraft?.booking_valid_to
                        ? dayjs(contractDraft.booking_valid_to).tz(selectedCityTimeZone)
                        : null,
                    ]}
                    onChange={(dates) => {
                      setDateRange({
                        startDate: dates?.at(0) ? dayjs(dates.at(0)).tz(selectedCityTimeZone).format() : null,
                        endDate: dates?.at(1) ? dayjs(dates.at(1)).tz(selectedCityTimeZone).format() : null,
                      });
                    }}
                  />
                </div>
              ) : (
                <p className="font-medium text-center">Please Select a city first</p>
              )}
            </div>
            <div className="w-full  flex flex-col gap-y-2 ">
              <div className="w-full flex items-center gap-x-1 text-slate-900">
                <MdNightsStay className="w-5 h-5 " />
                <h2 className="text-slate-800 font-semibold">Reservation Before Arrival</h2>
              </div>
              <div className="border rounded-md p-3">
                <StaticFromInput name="reservation_policy" text="Number of Days" type="number" required={false} />
              </div>
            </div>
            <div className="w-full  flex flex-col gap-y-2 ">
              <div className="w-full flex items-center gap-x-1 text-slate-900">
                <TbBrandDaysCounter className="w-5 h-5  text-slate-800" />
                <h2 className="text-slate-900 font-semibold">Min & Max Days</h2>
              </div>
              <div className="grid grid-cols-2 gap-4 border rounded-md p-3">
                <StaticFromInput name="min" text="Min" type="number" required={false} />
                <StaticFromInput
                  name="max"
                  text="Max"
                  type="number"
                  defaultValue={contractDraft?.price_policy_maximum_days}
                  required={false}
                />
              </div>
            </div>
            <div className="w-full  flex flex-col gap-y-2 ">
              <div className="w-full flex items-center gap-x-1 text-slate-900">
                <FcCancel className="w-5 h-5  text-slate-800" />
                <h2 className="text-slate-900 font-semibold">Cancellation Policy</h2>
              </div>
              <textarea
                name="cancellation_policy"
                cols="30"
                rows="5"
                className="border rounded-md p-3 focus:outline-none focus:ring focus:border-blue-300"
              ></textarea>
            </div>
            <div className="w-full  flex flex-col gap-y-2 ">
              <div className="w-full flex items-center gap-x-1 text-slate-900">
                <MdOutlinePriceChange className="w-5 h-5 text-slate-800" />
                <h2 className="text-slate-900 font-semibold">Same Day Booking Supplement</h2>
              </div>
              <div className="border rounded-md p-3">
                <StaticFromInput name="same_day_reservation_price" text="Amount" type="number" required={false} />
              </div>
            </div>
            <div className="w-full  flex flex-col gap-y-2 ">
              <div className="w-full flex items-center gap-x-1 text-slate-900">
                <HiClipboardDocumentCheck className="w-5 h-5 text-slate-800" />
                <h2 className="text-slate-900 font-semibold">Contract Document</h2>
              </div>
              <FileUploader
                maxFiles={1}
                maxFileSize={2}
                accept={"image/*, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .csv"}
                setFiles={setContractDocuments}
                files={contractDocuments}
              />
            </div>
            <div className="w-full  flex flex-col gap-y-2 ">
              <div className="w-full flex items-center gap-x-1 text-slate-900">
                <CgNotes className="w-5 h-5 text-slate-800" />
                <h2 className="text-slate-900 font-semibold">Notes</h2>
              </div>
              <textarea
                name="notes"
                cols="30"
                rows="5"
                className="border rounded-md p-3 focus:outline-none focus:ring focus:border-blue-300"
              ></textarea>
            </div>
            <div className="w-full flex justify-center items-center mt-6  ">
              <button
                onClick={() => {
                  setIsDraft(false);
                  formRef.current.noValidate = false;
                  formRef.current.requestSubmit();
                  // this will trigger the form submit
                }}
                disabled={addFlag}
                className="addButton disabled:grayscale disabled:cursor-not-allowed"
              >
                Add Contract{" "}
                {addFlag ? (
                  <>
                    {" "}
                    <TopBarProgress /> <ButtonLoading />
                  </>
                ) : (
                  <MdOutlinePostAdd className="w-5 h-5" />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  ) : (
    <NoPermission />
  );
}
