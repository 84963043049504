import {
  CLEAR_SINGLE_CONTRACT,
  CONTRACT_FLAG,
  GET_ALL_CONTRACTS,
  GET_ARCHIVED_CONTRACTS,
  GET_CONTRACT_STATUSES,
  GET_HOTEL_CONTRACTS,
  GET_SINGLE_CONTRACT,
  GET_UNDER_REVIEW_CONTRACTS,
} from "../types/types";
import api_token from "../../api/UserApi";
import cookie from "react-cookies";

const token = cookie.load("access_token");

function getContractsSuccessfull(data) {
  return {
    type: GET_ALL_CONTRACTS,
    payload: data,
  };
}

function getSingleContractSuccessfull(data) {
  return {
    type: GET_SINGLE_CONTRACT,
    payload: data,
  };
}

export function getContracts(ordering, page, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/contracts/api/?ordering=${ordering}&page=${page}&page_size=20`);
      dispatch(getContractsSuccessfull(response.data));
      if (callback && response) {
        callback();
      }
    } catch (error) {
      if (error.response.status === 404) {
        const response = await api_token(token).get(
          `/contracts/api/?ordering=${ordering}&page=${page - 1}&page_size=5`,
        );
        dispatch(getContractsSuccessfull(response.data));
        if (callback && response) {
          callback();
        }
      }
    }
  };
}

// get contract based on hotels
export const getContractsHotel = (ordering, page, hotelId, callback) => {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(
        `/contracts/api/?ordering=${ordering}&page=${page}&page_size=20&hotel=${hotelId}`,
      );
      dispatch(getContractsSuccessfull(response.data));
      if (callback && response) {
        callback();
      }
    } catch (error) {
      if (error.response.status === 404) {
        const response = await api_token(token).get(
          `/contracts/api/?ordering=${ordering}&page=${page - 1}&page_size=5`,
        );
        dispatch(getContractsSuccessfull(response.data));
        if (callback && response) {
          callback();
        }
      }
    }
  };
};

// this for contract
export function getSingleContract(id, callback) {
  return async function (dispatch) {
    try {
      dispatch({
        type: CONTRACT_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`/contracts/api/${id}`);
      if (response.status === 200) {
        dispatch({
          type: CONTRACT_FLAG,
          payload: false,
        });
        if (callback) {
          callback();
        }
      }
      dispatch(getSingleContractSuccessfull(response.data));
    } catch (error) {}
  };
}

// this for deleted contract
export const getSingleDeletedContract = (id, callback) => {
  return async function (dispatch) {
    try {
      dispatch({
        type: CONTRACT_FLAG,
        payload: true,
      });
      const response = await api_token(token).get(`/contracts/api/soft-deleted/restore/${id}/`);
      if (response.status === 200) {
        dispatch({
          type: CONTRACT_FLAG,
          payload: false,
        });
        if (callback) {
          callback(response);
        }
      }
      dispatch(getSingleContractSuccessfull(response.data));
    } catch (error) {}
  };
};

export function clearSingleContract() {
  return {
    type: CLEAR_SINGLE_CONTRACT,
  };
}

function getContractStatusesSuccess(data) {
  return {
    type: GET_CONTRACT_STATUSES,
    payload: data,
  };
}

export function getContractStatuses() {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get("/contracts/api/contract-statuss/");
      dispatch(getContractStatusesSuccess(response.data));
    } catch (error) {}
  };
}

export function changeContractStatus(id, data, callback) {
  return async function () {
    try {
      const response = await api_token(token).patch(`/contracts/api/${id}/update-status`, data);
      if (response && callback) {
        callback();
      }
    } catch (error) {}
  };
}

export function getUnderReviewContracts(ordering, page, callback, pageSize) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(
        `/contracts/api/approval?ordering=${ordering}&page=${page}&page_size=${pageSize}`,
      );
      dispatch({ type: GET_UNDER_REVIEW_CONTRACTS, payload: response.data });
      if (callback && response) {
        callback();
      }
    } catch (error) {}
  };
}

export function getArchivedContracts(ordering, page, listPageSize, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(
        `/contracts/api/archive?ordering=${ordering}&page=${page}&page_size=${listPageSize}`,
      );
      dispatch({ type: GET_ARCHIVED_CONTRACTS, payload: response.data });
      if (callback && response) {
        callback();
      }
    } catch (error) {
      if (error.response.status === 404) {
        const response = await api_token(token).get(
          `/contracts/api/archive?ordering=${ordering}&page=${page - 1}&page_size=5`,
        );
        dispatch({ type: GET_ARCHIVED_CONTRACTS, payload: response.data });
        if (callback && response) {
          callback();
        }
      }
    }
  };
}

export function getHotelContracts(id, callback) {
  return async function (dispatch) {
    try {
      const response = await api_token(token).get(`/contracts/api/hotel/?ordering=created_date&hotel=${id}`);
      dispatch({ type: GET_HOTEL_CONTRACTS, payload: response.data });
      callback(response);
    } catch (error) {}
  };
}

export const getHotelCountry = (id, callback) => {
  return async function (dispatch, getState) {
    try {
      const response = await api_token(token).get(`/search/api/hotels-by-city/?city=${id}`);

      callback(response);
    } catch (error) {}
  };
};

export const archiveContract = (id, callback) => {
  return async function (dispatch, getState) {
    try {
      const response = await api_token(token).patch(`/contracts/api/archive/${id}/`);

      callback(response);
    } catch (error) {}
  };
};
