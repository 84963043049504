import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeContractStatus, getArchivedContracts } from "../../../redux/actions/getContracts";
import { useLocation, useNavigate, useParams } from "react-router";
import { getPermissionAction } from "../../../redux/actions/permissionAction";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import TopBarProgress from "react-topbar-progress-indicator";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { FaUndo } from "react-icons/fa";
import lightToast from "light-toast";
import ListFilters from "../../../utils/ListFilters";
import { splitAndCapitalize } from "../../../utils/stringUtils";

export default function ContractArchive() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [contractId, setContractId] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const { sorting, page } = useParams();
  const [columnVisibility, setColumnVisibility] = useState({});
  const [listPageSize, setListPageSize] = useState(20);
  const contracts = useSelector((state) => state.archivedContracts);

  const totalPages = Math.ceil(contracts?.count / listPageSize);
  useEffect(() => {
    dispatch(getArchivedContracts(sorting, Number(page), listPageSize, () => setIsLoading(false)));
  }, [page, location]);

  useEffect(() => {
    const currentColumnVisibility = localStorage.getItem("contract-Arch-grid-visibility");
    if (currentColumnVisibility) {
      setColumnVisibility({ ...JSON.parse(currentColumnVisibility) });
    }
  }, []);

  /// handle restore contract from archive

  function handleRestor() {
    setIsLoading(true);
    dispatch(
      changeContractStatus(contractId, { status: "under_review" }, () => {
        lightToast.success("The contract was restore successfully");
        dispatch(getArchivedContracts(sorting, Number(page), () => setIsLoading(false)));
      }),
    );
  }

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
      render: (text, params) => renderId(params),
    },
    {
      key: "status",
      title: "Status",
      //   minWidth: 180,
      dataIndex: "status",
    },
    {
      key: "hotelName",
      title: "HotelName",
      dataIndex: "hotelName",
    },
    {
      key: "name",
      title: "Contract Name",
      dataIndex: "name",
    },
    {
      key: "contractType",
      title: "Contract Type",
      dataIndex: "contractType",
    },
    {
      key: "offerType",
      title: "Offer Type",
      //   minWidth: 200,
      dataIndex: "offerType",
    },
    {
      key: "durationTime",
      title: "Duration Time",
      //   minWidth: 150,
      dataIndex: "durationTime",
    },
    {
      key: "createdBy",
      title: "Created By",
      //   minWidth: 150,
      dataIndex: "createdBy",
    },
    {
      key: "created_date",
      title: "Created Date",
      //   minWidth: 250,
      dataIndex: "created_date",
    },
    {
      key: "actions",
      filterable: false,
      sortable: false,
      title: "Restore",
      dataIndex: "actions",

      render: (text, params) => {
        return (
          <div className="w-full flex items-center justify-center gap-x-1">
            {permission.map((item) => {
              if (item.name === "contracts_management.change_contract" && item.value === true) {
                return (
                  <FaUndo
                    className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-sky-800"
                    onClick={() => {
                      setContractId(params.id);
                      setConfirm(true);
                    }}
                  />
                );
              }
            })}
          </div>
        );
      },
    },
  ];
  const rows = contracts.results.map((contract) => {
    let time, hours, mins, secs;
    if (contract.created_time) {
      time = contract.created_time;
      hours = Math.floor(time / 3600);
      mins = Math.floor((time - hours * 3600) / 60);
      secs = time - mins * 60;
    }
    return {
      id: contract.id,
      status: contract.status,
      hotelName: contract.hotel.name,
      name: contract.name,
      contractType: splitAndCapitalize(contract.contract_type, "_"),
      offerType: contract.offer_type.name,
      durationTime: time
        ? `${hours.toString().padStart(2, 0)}:${mins.toString().padStart(2, 0)}:${secs.toString().padStart(2, 0)}`
        : "----",
      createdBy: contract.created_by ? contract.created_by.username : "----",
      approvedBy: "----",
      created_date: contract.created_date.split("T")[0],
      approvedDate: "----",
    };
  });

  function renderId(params) {
    return (
      <div className="flex justify-between w-full">
        <span>{params.id}</span>
        {permission.map((item) => {
          if (item.name === "contracts_management.view_contract" && item.value === true) {
            return (
              <Link to={`/dashboard/contract-archive/${params.id}`}>
                <AiOutlineEye className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-slate-800" />
              </Link>
            );
          }
        })}
      </div>
    );
  }

  function handlePageChange(params, size) {
    setListPageSize(size);
    setCurrentPage(params);
    navigate(`/dashboard/contract-archive/${params}/${sorting}`);
    setIsLoading(true);
  }

  function comparator([params]) {
    if (params) {
      if (params.sort === "desc") {
        navigate(`/dashboard/contract-archive/${page}/-${params.key}`);
      } else {
        navigate(`/dashboard/contract-archive/${page}/${params.key}`);
      }
    } else {
      navigate(-1);
    }
  }

  /////permission/////////
  const token = cookie.load("access_token");
  const permissionData = {
    permission_codes: ["contracts_management.view_contract", "contracts_management.change_contract"],
  };
  const [permission, setpermission] = useState();
  const [permissionFlag, setpermissionFlag] = useState(false);
  useEffect(() => {
    dispatch(
      getPermissionAction(token, permissionData, (result) => {
        if (result.status === 200) {
          setpermissionFlag(true);
          setpermission(result.data);
        }
      }),
    );
  }, []);

  return (
    <div className="formPage-wrap">
      {confirm && (
        <div
          className={` fixed ${
            confirm ? "top-0 left-0 right-0 bottom-0 h-screen  " : ""
          }  blur-effect-theme   w-full flex flex-col justify-center items-center gap-y-5  opacity-100 z-[250] overflow-x-hidden `}
        >
          <div
            className={`bg-black/70  max-w-sm w-full absolute  flex flex-col justify-center items-center gap-y-5 py-4    ${
              confirm
                ? "opacity-100 visible   transition-all duration-500 transform border-gray-300 border rounded-lg "
                : "opacity-0 invisible -right-full translate-x-8 transition-all duration-500 transform "
            } `}
          >
            <p className="text-2xl font-light  text-white">Are You Sure?</p>
            <div className="w-full flex items-center justify-center gap-x-5 text-white">
              <button
                onClick={() => {
                  setConfirm(false);
                  handleRestor();
                }}
                className="bg-green-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                Yes,I'm Sure
              </button>
              <button
                onClick={() => setConfirm(false)}
                className="bg-red-800 rounded-md px-2 py-0.5 hover:scale-105 active:scale-95 duration-300 transition-all"
              >
                No,take me back
              </button>
            </div>
          </div>
        </div>
      )}
      {permissionFlag ? (
        <div className="w-full h-screen">
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-screen">
              <Loading /> <TopBarProgress />
            </div>
          ) : (
            <>
              <div className="w-full ">
                <ListFilters
                  columns={columns}
                  rows={rows}
                  title="Contract Archive List"
                  handlePagination={handlePageChange}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  listPageSize={listPageSize}
                />
                {/* <DataGrid
                columns={columns}
                rowCount={contracts.count}
                rows={rows}
                slots={{
                  Toolbar: GridToolbar,
                }}
                paginationModel={{ page: Number(page - 1), pageSize: 10 }}
                onPaginationModelChange={handlePageChange}
                paginationMode="server"
                onSortModelChange={comparator}
                columnVisibilityModel={columnVisibility}
                onColumnVisibilityModelChange={(visibility) => {
                  setColumnVisibility(visibility);
                  localStorage.setItem("contract-Arch-grid-visibility", JSON.stringify(visibility));
                }}
              /> */}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-screen">
          <Loading /> <TopBarProgress />
        </div>
      )}
    </div>
  );
}
