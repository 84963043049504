import React, { forwardRef } from "react";
import { Card, Flex, Select, TimePicker } from "antd";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import {
  FieldErrorText,
  FormLabel,
  FormPhoneInput,
  FormTextAreaInput,
  FormTextInput,
} from "../../../../customComponents/FormComponents";
import { GoPerson } from "react-icons/go";
import dayjs from "dayjs";
import { MdOutlineAttachMoney, MdOutlineMail } from "react-icons/md";
import { useQuery } from "@tanstack/react-query";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import { CiStickyNote } from "react-icons/ci";
import RoomInfoCard from "./RoomInfoCard";
import { useTranslation } from "react-i18next";
import { SiGoogletranslate } from "react-icons/si";
import { translateTextToArabic, translateTextToEnglish } from "../../../../redux/actions/translationActions";
import CPTest from "../../cancelation_policyModel/CPTest";

const BookingForm = forwardRef(({ roomInfo, initialValues, validationSchema, onSubmit }, ref) => {
  const { selectedroom: selectedRoom, persons_data } = roomInfo;
  const clientsNum = persons_data?.adults;
  const {
    isLoading: isLoadingSpecialRequests,
    data: specialRequests,
    error: specialRequestsError,
  } = useQuery({
    queryKey: ["specialRequests"],
    queryFn: async () => {
      return fetchDataQuery("/booking/api/v1/get-booking-tags/");
    },
  });

  const [t] = useTranslation();
  const localeCode = t("localeCode");
  const direction = localeCode.startsWith("ar") ? "rtl" : "ltr";

  return (
    <Formik innerRef={ref} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, errors, setFieldValue }) => {
        return (
          <div dir={direction} className="flex flex-col lg:flex-row justify-between w-full gap-24 p-8 ">
            <RoomInfoCard roomInfo={roomInfo} values={values} />
            <Card
              headStyle={{ backgroundColor: "#002244" }}
              className="flex-grow"
              title={
                <h2 className="w-full bg-[#002244]  rounded-t-md text-center py-3 text-xs md:text-lg xl:text-2xl text-white font-serif">
                  {t("Bookingrequest")} {selectedRoom}
                </h2>
              }
            >
              <Form className="space-y-4">
                {roomInfo.markupType === "agent" && (
                  <div className="flex justify-center items-center mt-4">
                    <p className="mx-auto font-bold text-base lg:text-xl ">
                      {t("agentName")}: <span className="font-normal"> {roomInfo.markup.agent_name} </span>
                    </p>
                  </div>
                )}
                <Flex gap={"small"} vertical>
                  <FieldArray name={"clientsNames"}>
                    {() => {
                      return [...Array(clientsNum)].map((_, index) => {
                        return (
                          <Flex key={index} gap={"small"}>
                            <FormTextInput
                              label={t("clientName")}
                              name={`clientsNames[${index}].english_name`}
                              type="text"
                              placeholder={t("clientName")}
                              Icon={GoPerson}
                            />
                            <SiGoogletranslate
                              className="w-16 h-16 mt-3 cursor-pointer"
                              onClick={async () => {
                                const { english_name, arabic_name } = values.clientsNames[index];
                                if (english_name.length >= 2 && !arabic_name) {
                                  const translation = await translateTextToArabic(english_name);
                                  setFieldValue(`clientsNames[${index}].arabic_name`, translation);
                                } else if (arabic_name.length >= 2 && !english_name) {
                                  const translation = await translateTextToEnglish(arabic_name);
                                  setFieldValue(`clientsNames[${index}].english_name`, translation);
                                }
                              }}
                            />

                            <FormTextInput
                              label={t("clientNameAr")}
                              name={`clientsNames[${index}].arabic_name`}
                              type="text"
                              placeholder={t("clientNameAr")}
                              Icon={GoPerson}
                            />
                          </Flex>
                        );
                      });
                    }}
                  </FieldArray>
                  {typeof errors.clientsNames === "string" && (
                    <ErrorMessage name={"clientsNames"}>{(msg) => <FieldErrorText message={msg} />}</ErrorMessage>
                  )}
                </Flex>
                <Flex gap={"small"}>
                  <Flex flex={1} gap={2} vertical>
                    <FormLabel label={t("DepartureBookingFrom")} htmlFor={"departureTime"} />
                    <TimePicker
                      direction={direction}
                      placeholder={t("DepartureBookingFrom")}
                      status={errors.departureTime ? "error" : "success"}
                      value={values.departureTime ? dayjs(values.departureTime, "HH:mm") : undefined}
                      id={"departureTime"}
                      format={"HH"}
                      allowClear={false}
                      needConfirm={false}
                      onChange={(time) => {
                        if (!time) {
                          setFieldValue("departureTime", "");
                          return;
                        }
                        setFieldValue("departureTime", time.format("HH:mm"));
                      }}
                      defaultOpenValue={dayjs("00:00", "HH:mm")}
                    />
                    <ErrorMessage name={"departureTime"}>{(msg) => <FieldErrorText message={msg} />}</ErrorMessage>
                  </Flex>

                  <Flex flex={1} gap={2} vertical>
                    <FormLabel label={t("arrivalBookingto")} htmlFor={"arrivalTime"} />
                    <TimePicker
                      direction={direction}
                      placeholder={t("DepartureBookingFrom")}
                      status={errors.arrivalTime ? "error" : "success"}
                      value={values.arrivalTime ? dayjs(values.arrivalTime, "HH:mm") : undefined}
                      id={"arrivalTime"}
                      allowClear={false}
                      format={"HH"}
                      needConfirm={false}
                      onChange={(time) => {
                        if (!time) {
                          setFieldValue("arrivalTime", "");
                          return;
                        }
                        setFieldValue("arrivalTime", time.format("HH:mm"));
                      }}
                      defaultOpenValue={dayjs("00:00", "HH:mm")}
                    />
                    <ErrorMessage name={"arrivalTime"}>{(msg) => <FieldErrorText message={msg} />}</ErrorMessage>
                  </Flex>
                </Flex>

                {roomInfo.markupType !== "agent" && (
                  <>
                    <Flex gap={"small"}>
                      <FormTextInput
                        label={t("addPrice")}
                        name={"addToPrice"}
                        type="number"
                        placeholder={t("addPrice")}
                        Icon={MdOutlineAttachMoney}
                      />
                      <FormTextInput
                        label={t("email")}
                        name={"email"}
                        type="email"
                        placeholder={t("email")}
                        Icon={MdOutlineMail}
                      />
                    </Flex>

                    <Flex gap={"small"} vertical={false}>
                      <Flex gap={2} vertical flex={1}>
                        <FormLabel label={t("phoneNumber")} htmlFor={"phoneNumber"} />
                        <FormPhoneInput direction={direction} name={"phoneNumber"} placeholder={t("phoneNumber")} />
                      </Flex>
                      <Flex gap={2} vertical flex={1}>
                        <FormLabel label={t("whatsapp")} htmlFor={"whatsappNumber"} />
                        <FormPhoneInput direction={direction} name={"whatsappNumber"} placeholder={t("whatsapp")} />
                      </Flex>
                    </Flex>
                  </>
                )}

                <Flex gap={"small"} vertical={false}>
                  <Flex gap={2} vertical flex={1}>
                    <FormLabel label={t("clientRequestTag")} htmlFor={"specialRequests"} />
                    <Select
                      direction={direction}
                      value={values.specialRequests?.map((request) => {
                        return {
                          value: request?.value,
                          label: request?.label,
                        };
                      })}
                      mode="multiple"
                      id={"specialRequests"}
                      showSearch
                      placeholder={t("clientRequestTag")}
                      optionFilterProp="label"
                      onChange={(value, option) => {
                        if (option.length === 0) {
                          setFieldValue("specialRequests", []);
                        }
                        setFieldValue(
                          "specialRequests",
                          option.map((item) => item),
                        );
                      }}
                      status={specialRequestsError ? "error" : "success"}
                      loading={isLoadingSpecialRequests}
                      options={specialRequests?.map((request) => {
                        return {
                          value: request?.id,
                          label: request?.tag,
                        };
                      })}
                    />
                  </Flex>
                  <Flex gap={2} vertical flex={1}>
                    <FormLabel label={"Booking Type"} htmlFor={"bookingType"} />
                    <Select
                      direction={direction}
                      defaultValue={{
                        value: values.bookingType?.value,
                        label: values.bookingTyp?.label,
                      }}
                      value={{
                        value: values.bookingType?.value,
                        label: values.bookingTyp?.label,
                      }}
                      id={"bookingType"}
                      status={errors.bookingType ? "error" : "success"}
                      showSearch
                      placeholder="Select Booking Type"
                      optionFilterProp="label"
                      onChange={(value, option) => {
                        setFieldValue("bookingType", option);
                      }}
                      options={
                        roomInfo.payment_type === "credit"
                          ? [
                              {
                                value: true,
                                label: t("voucher"),
                              },
                              {
                                value: false,
                                label: t("noVoucher"),
                              },
                            ]
                          : [
                              {
                                value: true,
                                label: "Pay",
                              },
                              {
                                value: false,
                                label: "Don't Pay",
                              },
                            ]
                      }
                    />
                    <ErrorMessage name={"bookingType"}>{(msg) => <FieldErrorText message={msg} />}</ErrorMessage>
                  </Flex>
                </Flex>
                <FormTextAreaInput
                  label={t("clientRequest")}
                  name={"extraRequests"}
                  placeholder={t("clientRequest")}
                  Icon={CiStickyNote}
                />
              </Form>

              <CPTest
                startDate={[roomInfo.formCheckIn_whats_app]}
                contractOfferID={roomInfo.item.contract_offer_type_id}
                hotelId={roomInfo.hotelId}
                currencySearch={roomInfo.currencySearch}
                item={roomInfo.item}
                price={roomInfo.price}
                hotel={roomInfo.cpHotel}
              />
            </Card>
          </div>
        );
      }}
    </Formik>
  );
});

export default BookingForm;
