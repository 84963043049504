import React, { useState } from "react";
import { Modal } from "antd";
import { MdEmail } from "react-icons/md";

const BookingEmailModal = ({ email }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <MdEmail className="cursor-pointer w-4 h-4" onClick={showModal} />

      <Modal
        footer={null}
        centered={true}
        open={isModalOpen}
        forceRender={true}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className={"border p-4"}>
          <div
            style={{
              listStyleType: "square", // Change bullet style
            }}
            dangerouslySetInnerHTML={{
              __html: email,
            }}
            className="mb-2 space-y-2 email-body"
          />
        </div>
      </Modal>
    </>
  );
};

export default BookingEmailModal;
