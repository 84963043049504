import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Descriptions, Flex } from "antd";
import { BiLogoWhatsapp } from "react-icons/bi";
import { MdOutlineBedroomChild, MdOutlineMailOutline, MdPhoneAndroid } from "react-icons/md";
import { BsCurrencyDollar } from "react-icons/bs";
import { LuHotel } from "react-icons/lu";
import { IoIosPeople } from "react-icons/io";
import { IoPeopleSharp, IoTimeOutline } from "react-icons/io5";
import { RiCalendarCheckLine } from "react-icons/ri";
import { GiNightSleep } from "react-icons/gi";
import { FaChildren, FaRegNoteSticky } from "react-icons/fa6";
import { FaFileAlt } from "react-icons/fa";
import VoucherModal from "./VoucherModal";
import BookingEmailModal from "./BookingEmailModal";

function ItemLabel({ title, Icon }) {
  return (
    <Flex vertical={false} gap={"4px"} align={"center"}>
      <Icon className={"w-5 h-5"} />
      <p>{title}</p>
    </Flex>
  );
}

function BookingConfirmation({ formValues, rooms }) {
  const [t] = useTranslation();
  const localeCode = t("localeCode");
  const direction = localeCode.startsWith("ar") ? "rtl" : "ltr";

  const items = rooms.map((room, index) => {
    return [
      {
        key: "1",
        label: ItemLabel({ title: t("room"), Icon: MdOutlineBedroomChild }),
        children: room.selectedroomEn,
      },
      {
        key: "7",
        label: ItemLabel({ title: t("hotel"), Icon: LuHotel }),
        children: room.hotel,
      },
      {
        key: "2",
        label: ItemLabel({ title: t("roomPrice"), Icon: BsCurrencyDollar }),
        children: `${room.price} ${room.currency_type}`,
      },
      {
        key: "9",
        label: ItemLabel({ title: t("addedPrice"), Icon: BsCurrencyDollar }),
        children: `${formValues[index].addToPrice || 0} ${room.currency_type}`,
      },
      {
        key: "10",
        label: ItemLabel({ title: t("totalPrice"), Icon: BsCurrencyDollar }),
        children: `${room.price + (formValues[index].addToPrice || 0)} ${room.currency_type}`,
      },
      {
        key: "4",
        label: ItemLabel({ title: t("clientNamesEn"), Icon: IoIosPeople }),
        children: (
          <>
            {formValues[index].clientsNames
              .map((client) => client.english_name)
              .map((name, index) => {
                return (
                  <>
                    <span key={index}>{name}</span>
                    <br />
                  </>
                );
              })}
          </>
        ),
      },
      {
        key: "4",
        label: ItemLabel({ title: t("clientNamesAr"), Icon: IoIosPeople }),
        children: (
          <>
            {formValues[index].clientsNames
              .map((client) => client.arabic_name)
              .map((name, index) => {
                return (
                  <>
                    <span key={index}>{name}</span>
                    <br />
                  </>
                );
              })}
          </>
        ),
      },
      {
        key: "11",
        label: ItemLabel({ title: t("departureTime"), Icon: IoTimeOutline }),
        children: formValues[index].departureTime,
      },
      {
        key: "12",
        label: ItemLabel({ title: t("arrivalTime"), Icon: IoTimeOutline }),
        children: formValues[index].arrivalTime,
      },
      {
        key: "13",
        label: ItemLabel({ title: t("email"), Icon: MdOutlineMailOutline }),
        children: formValues[index].email,
      },
      {
        key: "13",
        label: ItemLabel({ title: t("whatsapp"), Icon: BiLogoWhatsapp }),
        children: formValues[index].whatsappNumber,
      },
      {
        key: "14",
        label: ItemLabel({ title: t("phoneNumber"), Icon: MdPhoneAndroid }),
        children: formValues[index].phoneNumber,
      },
      {
        key: "15",
        label: ItemLabel({ title: t("extraRequests"), Icon: FaRegNoteSticky }),
        children: formValues[index].extraRequests,
      },

      {
        key: "15",
        label: ItemLabel({ title: t("specialRequests"), Icon: FaRegNoteSticky }),
        children: (
          <>
            {formValues[index].specialRequests.map((request, index) => {
              return (
                <p key={index}>
                  <span key={index}>{request.label}</span>
                  <br />
                </p>
              );
            })}
          </>
        ),
      },

      {
        key: "4",
        label: ItemLabel({ title: t("nights"), Icon: GiNightSleep }),
        children: room.nights_whats_app,
      },
      {
        key: "5",
        label: ItemLabel({ title: t("checkInDate"), Icon: RiCalendarCheckLine }),
        children: room.formCheckIn_whats_app,
      },
      {
        key: "6",
        label: ItemLabel({ title: t("checkOutDate"), Icon: RiCalendarCheckLine }),
        children: room.formCheckOut_whats_app,
      },

      {
        key: "8",
        label: ItemLabel({ title: t("adults"), Icon: IoPeopleSharp }),
        children: room.persons_data.adults,
      },
      {
        key: "9",
        label: ItemLabel({ title: t("children"), Icon: FaChildren }),
        children: room.persons_data.children.length,
      },
      {
        key: "20",
        label: ItemLabel({ title: t("bookingType"), Icon: FaFileAlt }),
        children:
          formValues[index].bookingType.label.toLowerCase() === "voucher" ? (
            <div className={"flex gap-2"}>
              <p>Voucher</p>
              <VoucherModal
                modalFirstOpen={false}
                voucher={{
                  id: "",
                  voucher_type: "New Reservation",
                  hotel_confirmation_number: "",
                  system_confirmation_number: "",
                  hotel_name: room.hotel,
                  guest_name: formValues[index].clientsNames.map((client) => client.english_name),
                  date: new Date().toISOString().split("T")[0],
                  number_of_persons: room.persons_data.adults,
                  children_age: room?.children_ages,
                  nationality: room?.nationality,
                  room_type: room?.selectedroomEn,
                  meal_plan: room?.contract_meal,
                  check_in_date: room.formCheckIn_whats_app,
                  check_out_date: room.formCheckOut_whats_app,
                  issued_by: {
                    first_name: "",
                    last_name: "",
                  },
                  net_price_json: room.sendPriceInVoucher
                    ? room.additional_data.map((data) => {
                        return {
                          price: data.net_price,
                          data: data.date,
                        };
                      })
                    : [],
                  remarks: " ",
                  total_price: room.sendPriceInVoucher ? room.price + (formValues[index].addToPrice || 0) : 0,
                  currency: room.currency_type,
                }}
                isVoucherCreated={false}
                setIsVoucherCreated={() => {}}
              />
            </div>
          ) : (
            `${formValues[index].bookingType.label}`
          ),
      },
      {
        key: "20",
        label: ItemLabel({ title: t("emailBody"), Icon: FaFileAlt }),
        children: (
          <BookingEmailModal
            email={`<p>Dear <strong>${room.hotel}</strong>,</p> 
        <p>Reservation Department</p> 
        <p>Hi, we are from Nugget Tours.<br> Kindly ask you to reserve and confirm the following details:</p> 
        <ul>
          <li><strong>Hotel:</strong> ${room.hotel}</li>
          <li><strong>Room Type:</strong> ${room.selectedroomEn}</li>
          <li><strong>Number of Adults:</strong> ${room.persons_data.adults}</li>
          <li><strong>Meal Plan:</strong> ${room.mealType}</li>
          <li><strong>From:</strong> ${room.formCheckIn_whats_app}</li>
          <li><strong>Till:</strong> ${room.formCheckOut_whats_app}</li>
        </ul>
        <p><strong>Guest Name:</strong> ${formValues[index]?.clientsNames[0].english_name}</p>
        <p><strong>Nationality:</strong> ${room.nationality}</p>
        <h3>Pricing Details</h3>
        ${
          room.sendPriceInMail && room.additional_data.length > 0
            ? `<ul>${room.additional_data
                .map(
                  (data) =>
                    `<li><strong>Date:</strong> ${data.date}, <strong>Net Price:</strong> ${data.net_price} ${room.currency_type}</li>`,
                )
                .join("")}</ul>`
            : ""
        }
        <p><strong>Total Price:</strong> ${room.price + (formValues[index].addToPrice || 0)} ${room.currency_type}</p>

        ${
          formValues[index].bookingType.label.toLowerCase() === "voucher"
            ? `<p>Kindly find the attached voucher for the above reservation.</p>`
            : ""
        }

        <p>Your soonest reply will be highly appreciated.</p>
        <p>Best Regards</p>`}
          />
        ),
      },

      {},
    ];
  });

  return (
    <div className="w-full h-full min-h-[100dvh] py-16 px-8">
      {rooms.map((room, index) => {
        return (
          <Card
            dir={direction}
            title={`${room.selectedroom}`}
            key={index}
            className={"mb-4"}
            styles={{
              title: {
                textAlign: "center",
              },
            }}
          >
            <Descriptions className={"mb-4"} bordered items={items[index]} />
          </Card>
        );
      })}
    </div>
  );
}

export default BookingConfirmation;
