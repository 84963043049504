import React, { useEffect, useState } from "react";
import { FaFileAlt } from "react-icons/fa";
import { Modal } from "antd";
import VoucherImage from "../../VoucherImage/VoucherImage";

function VoucherModal({ voucher, isVoucherCreated, setIsVoucherCreated, voucherRef, modalFirstOpen = false }) {
  const [isModalOpen, setIsModalOpen] = useState(modalFirstOpen);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (voucher) {
      if (isVoucherCreated) {
        handleOk();
      }
    }
  }, [isVoucherCreated, voucher]);

  return (
    <>
      <FaFileAlt className="cursor-pointer w-4 h-4" onClick={showModal} />

      {voucher && (
        <Modal
          width={"fit-content"}
          footer={null}
          centered={true}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <VoucherImage
            voucher={voucher}
            isVoucherCreated={isVoucherCreated}
            setIsVoucherCreated={setIsVoucherCreated}
            ref={voucherRef}
          />
        </Modal>
      )}
    </>
  );
}

export default VoucherModal;
