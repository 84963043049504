import React, { useEffect, useState } from "react";
import { BiSolidDownArrow } from "react-icons/bi";
import ListSelectedRoom from "../ListSelectedRoom";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Loading from "../Loading/Loading";
import { getMoreRoomsAction } from "../../../redux/actions/SelectedSearchAction";
import ButtonLoading from "../ButtonLoading/ButtonLoading";
import { useParams } from "react-router";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "react-image-lightbox/style.css";
import { getHotelsDescription } from "../../../redux/actions/bookingOrderAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { FcDam, FcInfo } from "react-icons/fc";
import { IoLocation } from "react-icons/io5";
import { CiLollipop } from "react-icons/ci";
import { FaCity } from "react-icons/fa";
import { HiWifi } from "react-icons/hi";
import { BsFillCreditCardFill } from "react-icons/bs";
import { GiMoneyStack } from "react-icons/gi";
import { PiRoadHorizonDuotone } from "react-icons/pi";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { FiExternalLink } from "react-icons/fi";
import { Button, Modal, Popover, Rating } from "flowbite-react";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import { Spin } from "antd";

const SelectedSearch = ({ selectedHotel, userinfo }) => {
  const { bedOnlyRoom } = useSelector((state) => state.filterReducer);
  const { breakfastRoom } = useSelector((state) => state.filterReducer);
  const { halfboardRoom } = useSelector((state) => state.filterReducer);
  const { fullboardRoom } = useSelector((state) => state.filterReducer);
  const { allInclusive } = useSelector((state) => state.filterReducer);
  const selectedHotels = useSelector((state) => state.SelectedSearchReducer.selectedHotel);
  const { hotelStatus } = useSelector((state) => state.SelectedSearchReducer);
  const { moreRoomsData } = useSelector((state) => state.SelectedSearchReducer);
  const { moreRoomFlag } = useSelector((state) => state.SelectedSearchReducer);
  const [status, setStatus] = useState(false);
  const [check, setCheck] = useState(false);
  const [open, setOpen] = useState(false);
  const [hotelsDetails, setHotelsDetails] = useState();
  const [photoIndex, setPhotoIndex] = useState(0);
  const [detailsFlag, setdetailsFlag] = useState(false);
  const [selectRoomFlag, setSelectRoomFlag] = useState(false);
  const params = useParams();
  const [t, i18n] = useTranslation();
  const localeCode = t("localeCode");
  const [singleContract, setSingleContract] = useState();
  const [MergedContract, setMergedContract] = useState();
  //////To get Smaller Offer Price if has more than one contract
  const [priceSmaller, setPriceSmaller] = useState(selectedHotel.rooms.map((item) => item.contracts));
  const [priceSmallerMerged, setPriceSmallerMerged] = useState(
    selectedHotel.rooms.map((item) => item.merged_contracts),
  );

  const allOffers = priceSmaller.flat();

  const smallestOfferPrice = Math.min(...allOffers.map((offer) => offer.offer_price));

  const allOffersMerged = priceSmallerMerged.flat();
  const smallestOfferPriceMerged = Math.min(...allOffersMerged.map((offer) => offer.offer_price));

  const startDateFrom =
    moreRoomsData &&
    moreRoomsData.length > 0 &&
    moreRoomsData.map((room) => {
      const contract = room?.rooms?.[0]?.contracts?.[0]; // Assuming you're interested in the first room and contract
      if (contract && contract?.validities) {
        const contractKeys = Object.keys(contract?.validities || {});

        return contract?.validities[contractKeys]?.days_data?.days_list[0]["date"];
      } else if (room.rooms?.[0]?.merged_contracts?.shared_validities?.[0]?.days_data?.days_list) {
        return room.rooms[0]?.merged_contracts?.shared_validities[0]?.days_data?.days_list[0]["date"];
      } else {
        return null; // Handle the case where no valid start date is found
      }
    });

  const itemDataStart = startDateFrom;
  const chosenHotel = moreRoomsData && moreRoomsData.length > 0 ? moreRoomsData?.map((room) => room?.hotel_pk) : [];
  const startDate_data = itemDataStart ? itemDataStart?.map((s) => s) : "loading";

  const { hotel } = selectedHotel;
  const { hotel_description } = selectedHotel;
  const { hotel_image } = selectedHotel;
  const { hotel_rate } = selectedHotel;
  const { hotel_pk } = selectedHotel;

  useEffect(() => {}, []);

  const { dataFromSearchForReseve } = useSelector((state) => state?.reservationReducer);

  const currencySearch = cookie.load("currency");
  const { markupCheck } = useSelector((state) => state.SelectedSearchReducer);
  const moreRoom = {
    city: dataFromSearchForReseve.city,
    area: dataFromSearchForReseve.area,
    from_date: dataFromSearchForReseve.from_date,
    to_date: dataFromSearchForReseve.to_date,
    nights: dataFromSearchForReseve.nights,
    persons: dataFromSearchForReseve.persons,
    hotel: selectedHotel.hotel_pk,
    currency: currencySearch,
    markup: dataFromSearchForReseve.markup,
    country: dataFromSearchForReseve.nationality,
    unique_code: cookie.load("code"),
    people: JSON.stringify(dataFromSearchForReseve?.details),
    // details: true, // Fixed From backend
  };

  const dispatch = useDispatch();
  const token = cookie.load("access_token");
  //

  useEffect(() => {
    setCheck(true);
  }, [moreRoomsData]);

  const [hotelData, setDataHotel] = useState();
  const [listRooms, setListRoom] = useState(false);
  const [hotelId, setHotelId] = useState();
  const [selectedofferId, setSelectedOfferIdData] = useState();

  const handleSelectedRooms = async () => {
    // Perform state updates synchronously
    setListRoom(!listRooms);
    setStatus(!status);
    setSelectRoomFlag(true);

    try {
      // Dispatch the action and wait for it to complete
      const result = await dispatch(
        getMoreRoomsAction(token, moreRoom, selectedHotel?.hotel_pk, selectedHotel?.hotel, status, t("lan")),
      );

      // After dispatch is complete, update hotelId state
      const hotelID = selectedHotel?.hotel_pk;
      setHotelId(hotelID);
    } catch (error) {}
  };
  useEffect(() => {
    // Do something with the updated hotelId
  }, [hotelId]);
  //state to get sorted prices of all room

  let testArray1 = [];
  let dayList2 = [];

  selectedHotel.rooms.map((selectedroom, index1) => {});

  const { storeHotel } = useSelector((state) => state.SelectedSearchReducer);

  const { hotelNameRoom } = useSelector((state) => state.SelectedSearchReducer);

  if (moreRoomsData.length > 0) {
    moreRoomsData.map((selectedHotel2, index1) => {
      if (selectedHotel2.hotel_pk === selectedHotel.hotel_pk) {
        selectedHotel2.rooms.map((selectedroom, index1) => {
          if (selectedroom.merged_contracts.shared_validities.length !== 0) {
            selectedroom.merged_contracts.shared_validities.map((item, index2) =>
              item.days_data.days_list.slice(0, 1).map((commission) => {
                item.total_price.map((price, index3) => {
                  if (item.contract_meal_included === price.offer_type) {
                    item.days_data.days_list.slice(0, 1).map((info2) => {
                      testArray1.push({
                        uniqueId: price.pk + price.offer_price,
                        room_extra_info: selectedroom.room_extra_info,
                        price: price.offer_price,
                        original_price: price.offer_price_original,
                        netPrice: price.net_price_original,
                        offerType: price.offer_type,
                        commissionType: commission.commission_type,
                        persons_num: selectedroom.persons.adults,
                        children_num: selectedroom.persons.children.length,
                        children_ages: selectedroom.persons.children,
                        hotel: item,
                        selectedroom: selectedroom.room_name,
                        selectedroomEn: selectedroom.room_name_en,
                        sendPriceInMail: selectedroom.hotel_send_price_in_mail,
                        sendPriceInVoucher: selectedroom.hotel_send_price_in_voucher,
                        hotelAddress: selectedroom.hotel_address,
                        hotelMapAddress: selectedroom.hotel_map_address,
                        hotelname: selectedroom.hotel_name,
                        currency: item.currency,
                        rooms_extra_offers: selectedroom?.rooms_extra_offers,
                        search_currency: price.currency_search,
                        roomtypeId: price.pk,
                        roomId: selectedroom.room_pk,
                        room_images: selectedroom.room_image,
                        roomDescription: selectedroom.room_description,
                        inventory_status: selectedroom.inventory_status,
                        payment_type: selectedHotel.payment_type,
                        first_night: info2.total_price,
                        dayList: item.days_data.days_list,
                        hotelSlug: selectedroom.hotel_slug,
                        hotelEn: selectedHotel.hotel_en,
                      });
                    });
                  } else {
                    item.days_data.days_list.slice(0, 1).map((info2) => {
                      Object.values(info2.meals_offers).map((info3) => {
                        if (price.offer_type === info3.offer_type) {
                          testArray1.push({
                            uniqueId: price.pk + price.offer_price,
                            room_extra_info: selectedroom.room_extra_info,
                            price: price.offer_price,
                            original_price: price.offer_price_original,
                            netPrice: price.net_price_original,
                            offerType: price.offer_type,
                            commissionType: commission.commission_type,
                            persons_num: selectedroom.persons.adults,
                            children_num: selectedroom.persons.children.length,
                            children_ages: selectedroom.persons.children,
                            hotel: item,
                            selectedroom: selectedroom.room_name,
                            selectedroomEn: selectedroom.room_name_en,
                            sendPriceInMail: selectedroom.hotel_send_price_in_mail,
                            sendPriceInVoucher: selectedroom.hotel_send_price_in_voucher,
                            hotelAddress: selectedroom.hotel_address,
                            hotelMapAddress: selectedroom.hotel_map_address,
                            hotelname: selectedroom.hotel_name,
                            currency: item.currency,
                            rooms_extra_offers: selectedroom?.rooms_extra_offers,
                            search_currency: price.currency_search,
                            roomtypeId: price.pk,
                            roomId: selectedroom.room_pk,
                            room_images: selectedroom.room_image,
                            roomDescription: selectedroom.room_description,
                            inventory_status: selectedroom.inventory_status,
                            payment_type: selectedHotel.payment_type,
                            first_night: info3.total_price,
                            dayList: item.days_data.days_list,
                            hotelSlug: selectedroom.hotel_slug,
                            hotelEn: selectedHotel.hotel_en,
                          });
                        }
                      });
                    });
                  }
                });
              }),
            );
          }
          if (selectedroom.contracts.length !== 0) {
            selectedroom.contracts.map((item, index2) =>
              item.total_price.map((price, index3) => {
                if (item.contract_meal_included === price.offer_type) {
                  Object.values(item.validities).map((info) => {
                    info.days_data.days_list.slice(0, 1).map((info2) => {
                      // dayList2.push({ date: info.days_data.days_list });
                      testArray1.push({
                        uniqueId: price.pk + price.offer_price,
                        room_extra_info: selectedroom.room_extra_info,
                        price: price.offer_price,
                        original_price: price.offer_price_original,
                        netPrice: price.net_price_original,
                        offerType: price.offer_type,
                        roomId: selectedroom.room_pk,
                        currency: item.currency,
                        rooms_extra_offers: selectedroom?.rooms_extra_offers,
                        search_currency: price.currency_search,
                        persons_num: selectedroom.persons.adults,
                        children_num: selectedroom.persons.children.length,
                        children_ages: selectedroom.persons.children,
                        inventory_status: selectedroom.inventory_status,
                        roomDescription: selectedroom.room_description,
                        hotel: item,
                        selectedroom: selectedroom.room_name,
                        selectedroomEn: selectedroom.room_name_en,
                        sendPriceInMail: selectedroom.hotel_send_price_in_mail,
                        sendPriceInVoucher: selectedroom.hotel_send_price_in_voucher,
                        hotelAddress: selectedroom.hotel_address,
                        hotelMapAddress: selectedroom.hotel_map_address,
                        hotelname: selectedroom.hotel_name,
                        roomtypeId: price.pk,
                        room_images: selectedroom.room_image,
                        payment_type: selectedHotel.payment_type,
                        first_night: info2.total_price,
                        dayList: info.days_data.days_list,
                        contract_meal: item.contract_meal_included,
                        hotelSlug: selectedroom.hotel_slug,
                        hotelEn: selectedHotel.hotel_en,
                      });
                    });
                  });
                } else {
                  Object.values(item.validities).map((info) => {
                    info.days_data.days_list.slice(0, 1).map((info3) => {
                      Object.values(info3.meals_offers).map((info4) => {
                        if (price.offer_type === info4.offer_type) {
                          // dayList2.push({ date: info.days_data.days_list });
                          testArray1.push({
                            uniqueId: price.pk + price.offer_price,
                            room_extra_info: selectedroom.room_extra_info,
                            price: price.offer_price,
                            original_price: price.offer_price_original,
                            netPrice: price.net_price_original,
                            offerType: price.offer_type,
                            roomId: selectedroom.room_pk,
                            currency: item.currency,
                            rooms_extra_offers: selectedroom?.rooms_extra_offers,
                            search_currency: price.currency_search,
                            persons_num: selectedroom.persons.adults,
                            children_num: selectedroom.persons.children.length,
                            children_ages: selectedroom.persons.children,
                            inventory_status: selectedroom.inventory_status,
                            roomDescription: selectedroom.room_description,
                            hotel: item,
                            selectedroom: selectedroom.room_name,
                            selectedroomEn: selectedroom.room_name_en,
                            sendPriceInMail: selectedroom.hotel_send_price_in_mail,
                            sendPriceInVoucher: selectedroom.hotel_send_price_in_voucher,
                            hotelAddress: selectedroom.hotel_address,
                            hotelMapAddress: selectedroom.hotel_map_address,
                            hotelname: selectedroom.hotel_name,
                            roomtypeId: price.pk,
                            room_images: selectedroom.room_image,
                            payment_type: selectedHotel.payment_type,
                            first_night: info4.total_price,
                            dayList: info.days_data.days_list,
                            contract_meal: item.contract_meal_included,
                            hotelSlug: selectedroom.hotel_slug,
                            hotelEn: selectedHotel.hotel_en,
                          });
                        }
                      });
                    });
                  });
                }
              }),
            );
          }
        });
      }
    });
  }

  // Filter Hotels Based On Selected Rooms in filter

  let allRoom = testArray1.sort((a, b) => a.price - b.price); // Amr Enta shaghal ala de

  ///////////////

  // Filtred bedOnlyRoom
  let bedRoomOnlyArray = [];
  const roomOnlyBed = allRoom.filter((item) => item.offerType === "b.o");
  roomOnlyBed.map((item) => bedRoomOnlyArray.push(item));

  // Filtered Breakfast Room
  let breakfastArray = [];
  const roomWithBreakFast = allRoom.filter((item) => item.offerType === "b.b");
  roomWithBreakFast.map((item) => breakfastArray.push(item));

  //Filtered HalfBoard
  let halfboardArray = [];
  const roomWithHalfBoard = allRoom.filter((item) => item.offerType === "h.b");
  roomWithHalfBoard.map((item) => halfboardArray.push(item));

  //Filtered FullBoard
  let fullboardArray = [];
  const roomWithFullBoard = allRoom.filter((item) => item.offerType === "f.b");
  roomWithFullBoard.map((item) => fullboardArray.push(item));

  //Filtered BedOnly and BreakFast
  let BedOnlyAndBreakfast = [];
  const roomWithBOandBB = allRoom.filter((item) => item.offerType === "b.o" || item.offerType === "b.b");
  roomWithBOandBB.map((item) => BedOnlyAndBreakfast.push(item));

  //Filtered Bedonly and Halfboard
  let BedOnlyAndHalfBoard = [];
  const roomWithBOandHB = allRoom.filter((item) => item.offerType === "b.o" || item.offerType === "h.b");
  roomWithBOandHB.map((item) => BedOnlyAndHalfBoard.push(item));

  //Filtered Bedonly and Fullboard
  let BedOnlyAndFullBoard = [];
  const roomWithBOandFB = allRoom.filter((item) => item.offerType === "b.o" || item.offerType === "f.b");
  roomWithBOandFB.map((item) => BedOnlyAndFullBoard.push(item));

  //Filtered BreakFast and Halfboard
  let breakfastAndHalfBoard = [];
  const roomWithBBandHB = allRoom.filter((item) => item.offerType === "b.b" || item.offerType === "h.b");
  roomWithBBandHB.map((item) => breakfastAndHalfBoard.push(item));

  //Filtered Bedonly and BreakFast and Halfboard
  let BedOnlyAndBreakfastAndHalfBoard = [];
  const roomWithBOandBBandHB = allRoom.filter(
    (item) => item.offerType === "b.o" || item.offerType === "b.b" || item.offerType === "h.b",
  );
  roomWithBOandBBandHB.map((item) => BedOnlyAndBreakfastAndHalfBoard.push(item));

  //Filtered Bedonly and BreakFast and Fullboard
  let BedOnlyAndBreakfastAndFullBoard = [];
  const roomWithBOandBBandFB = allRoom.filter(
    (item) => item.offerType === "b.o" || item.offerType === "b.b" || item.offerType === "f.b",
  );
  roomWithBOandBBandFB.map((item) => BedOnlyAndBreakfastAndFullBoard.push(item));

  //Filtered Bedonly and HalfBoard and Fullboard
  let BedOnlyAndHalfBoardAndFullBoard = [];
  const roomWithBOandBHandFB = allRoom.filter(
    (item) => item.offerType === "b.o" || item.offerType === "h.b" || item.offerType === "f.b",
  );
  roomWithBOandBHandFB.map((item) => BedOnlyAndHalfBoardAndFullBoard.push(item));

  //Filtered  BreakFast and Halfboard and Fullboard
  let BreakfastAndHalfBoardAndFullBoard = [];
  const roomWithBBandHBandFB = allRoom.filter(
    (item) => item.offerType === "b.b" || item.offerType === "h.b" || item.offerType === "f.b",
  );
  roomWithBBandHBandFB.map((item) => BreakfastAndHalfBoardAndFullBoard.push(item));

  //Filtered BedOnly and BreakFast and Halfboard and Fullboard
  let BedOnlyAndBreakfastAndHalfBoardAndFullBoard = [];
  const roomWithBOandBBandHBandFB = allRoom.filter(
    (item) =>
      item.offerType === "b.o" || item.offerType === "b.b" || item.offerType === "h.b" || item.offerType === "f.b",
  );
  roomWithBOandBBandHBandFB.map((item) => BedOnlyAndBreakfastAndHalfBoardAndFullBoard.push(item));

  //////////////////////////////

  const handleRoom = () => {
    if (
      listRooms &&
      bedOnlyRoom === "b.o" &&
      breakfastRoom === "b.b" &&
      halfboardRoom === "h.b" &&
      fullboardRoom === "f.b"
    ) {
      return (
        <div>
          {BedOnlyAndBreakfastAndHalfBoardAndFullBoard.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  hotelId={hotelId}
                  room_extra_info={item.room_extra_info}
                  hotel={hotel.hotel_pk}
                  data={hotelData}
                  startDate={startDate_data}
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms && breakfastRoom === "b.b" && halfboardRoom === "h.b" && fullboardRoom === "f.b") {
      return (
        <div>
          {BreakfastAndHalfBoardAndFullBoard.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  moreRoom={moreRoom}
                  hotelId={hotelId}
                  room_extra_info={item?.room_extra_info}
                  hotel={chosenHotel}
                  data={hotelData}
                  startDate={startDate_data}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms && bedOnlyRoom === "b.o" && halfboardRoom === "h.b" && fullboardRoom === "f.b") {
      return (
        <div>
          {BedOnlyAndHalfBoardAndFullBoard.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  hotelId={hotelId}
                  data={hotelData}
                  hotel={chosenHotel}
                  startDate={startDate_data}
                  room_extra_info={item?.room_extra_info}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms && bedOnlyRoom === "b.o" && breakfastRoom === "b.b" && fullboardRoom === "f.b") {
      return (
        <div>
          {BedOnlyAndBreakfastAndFullBoard.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  data={hotelData}
                  room_extra_info={item?.room_extra_info}
                  hotel={chosenHotel}
                  hotelId={hotelId}
                  startDate={startDate_data}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms && bedOnlyRoom === "b.o" && breakfastRoom === "b.b" && halfboardRoom === "h.b") {
      return (
        <div>
          {BedOnlyAndBreakfastAndHalfBoard.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  data={hotelData}
                  hotelId={hotelId}
                  room_extra_info={item?.room_extra_info}
                  hotel={chosenHotel}
                  startDate={startDate_data}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms && bedOnlyRoom === "b.o" && fullboardRoom === "f.b") {
      return (
        <div>
          {BedOnlyAndFullBoard.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  data={hotelData}
                  room_extra_info={item?.room_extra_info}
                  hotel={chosenHotel}
                  hotelId={hotelId}
                  startDate={startDate_data}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms && bedOnlyRoom === "b.o" && halfboardRoom === "h.b") {
      return (
        <div>
          {BedOnlyAndHalfBoard.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  data={hotelData}
                  room_extra_info={item?.room_extra_info}
                  hotel={chosenHotel}
                  hotelId={hotelId}
                  startDate={startDate_data}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms && bedOnlyRoom === "b.o" && breakfastRoom === "b.b") {
      return (
        <div>
          {BedOnlyAndBreakfast.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  data={hotelData}
                  room_extra_info={item?.room_extra_info}
                  hotel={chosenHotel}
                  hotelId={hotelId}
                  startDate={startDate_data}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms && breakfastRoom === "b.b" && halfboardRoom === "h.b") {
      return (
        <div>
          {breakfastAndHalfBoard.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  data={hotelData}
                  room_extra_info={item?.room_extra_info}
                  hotel={chosenHotel}
                  hotelId={hotelId}
                  startDate={startDate_data}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms && bedOnlyRoom === "b.o") {
      return (
        <div>
          {bedRoomOnlyArray.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  data={hotelData}
                  room_extra_info={item?.room_extra_info}
                  hotel={chosenHotel}
                  hotelId={hotelId}
                  startDate={startDate_data}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms && breakfastRoom === "b.b") {
      return (
        <div>
          {breakfastArray.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  data={hotelData}
                  room_extra_info={item?.room_extra_info}
                  hotel={chosenHotel}
                  hotelId={hotelId}
                  startDate={startDate_data}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms && halfboardRoom === "h.b") {
      return (
        <div>
          {halfboardArray.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  data={hotelData}
                  room_extra_info={item?.room_extra_info}
                  hotel={chosenHotel}
                  hotelId={hotelId}
                  startDate={startDate_data}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms && fullboardRoom === "f.b") {
      return (
        <div>
          {fullboardArray.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className=" flex w-full " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  data={hotelData}
                  room_extra_info={item?.room_extra_info}
                  hotel={chosenHotel}
                  hotelId={hotelId}
                  startDate={startDate_data}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
              </div>
            </div>
          ))}
        </div>
      );
    } else if (listRooms) {
      return (
        <div className={``}>
          {allRoom.map((item, index) => (
            <div className="w-full sm:py-3 px-5 " key={index}>
              <div className="  w-full  " key={index}>
                <ListSelectedRoom
                  selectedroom={item.selectedroom}
                  selectedroomEn={item.selectedroomEn}
                  sendPriceInMail={item.sendPriceInMail}
                  sendPriceInVoucher={item.sendPriceInVoucher}
                  uniqueId={item.uniqueId}
                  rooms_extra_offers={item?.rooms_extra_offers}
                  userinfo={userinfo}
                  hotel_AR={selectedHotel?.hotel}
                  data={hotelData}
                  room_extra_info={item?.room_extra_info}
                  hotel={chosenHotel}
                  hotelId={hotelId}
                  startDate={startDate_data}
                  hotel_slug={item.hotelSlug}
                  key={index}
                  item={item.hotel}
                  persons={item.persons_num}
                  children_num={item.children_num}
                  children_ages={item.children_ages}
                  room_description={item.roomDescription}
                  roomImage={item.room_images}
                  room_id={item.roomId}
                  roomtype_id={item.roomtypeId}
                  commissions={item.commissionType}
                  currency={item.currency}
                  price={item.price}
                  offerType={item.offerType}
                  nameofhotel={item.hotelname}
                  inventoryStatus={item.inventory_status}
                  daysDataList={item.dayList}
                  firstNight={item.first_night}
                  contractMeal={item.contract_meal}
                  currencySearch={item.search_currency}
                  originalPrice={item.original_price}
                  hotel_En={item.hotelEn}
                  paymentType={item.payment_type}
                  net_Price={item.netPrice}
                  hotel_address={item.hotelAddress}
                  hotel_map_address={item.hotelMapAddress}
                />
                {/* <ListSelectedRoom   key={index}     roomtype_id = {item.roomtypeId}  price={item.price} offerType={item.offerType}  /> */}
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  ////// get More Rooms

  ////////////////
  const handleHotelsDetials = () => {
    dispatch(
      getHotelsDescription(token, selectedHotel.hotel_pk, t("lan"), (result) => {
        if (result.status === 200) {
          setdetailsFlag(true);
          setHotelsDetails(result.data);
        }
      }),
    );
  };

  const key = Math.random() * 10;
  const images = [
    { src: hotel_image, title: hotel, description: hotel_description },
    { src: hotel_image, title: hotel, description: hotel_description },
    { src: hotel_image, title: hotel, description: hotel_description },
    // Add more images as needed
  ];

  const splideOptions = {
    perPage: 1,
    perMove: 1,
    arrows: true,
    keyboard: "global",
    rewind: true, // Set rewind to true for looping
    pagination: false, // Enable pagination if needed
    direction: t("dir") === "en" ? "ltr" : "rtl",
    autoplay: true, // Enable autoplay
    interval: 3000, // Set interval for autoplay in milliseconds (optional)
    speed: 500, // Set transition speed if desired (in milliseconds)

    // breakpoints: {
    //   1200: { perPage: 1},
    //   991 : { perPage: 1},
    //   768 : { perPage: 1},
    //   500 : { perPage: 1},
    //   425 : { perPage: 1},
    // },
  };

  const [markerPosition, setMarkerPosition] = useState({
    lat: Number(detailsFlag && hotelsDetails.latitude),
    lng: Number(detailsFlag && hotelsDetails.longitude),
  });
  // const onMarkerDragEnd = (coord, index) => {
  //   const { latLng } = coord;
  //   const lat = latLng.lat();
  //   const lng = latLng.lng();

  //   setMarkerPosition({ lat, lng });
  //
  // };
  function getLocationData(data) {
    setMarkerPosition(data);
  }

  const defaultCenter = {
    lat: Number(detailsFlag && hotelsDetails.latitude),
    lng: Number(detailsFlag && hotelsDetails.longitude),
  };
  const mapStyles = {
    height: "30vh",
    width: "100%",
  };
  const googleMapsUrl = `https://www.google.com/maps?q=${defaultCenter.lat},${defaultCenter.lng}`;

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [SearchInfo, setSearchInfo] = useState();

  const {
    data: hotelExtraData,
    error: hotelExtraError,
    isLoading,
  } = useQuery({
    queryKey: ["hotel-extra-info", SearchInfo],
    queryFn: ({ queryKey }) => {
      const [_key, name] = queryKey;
      return fetchDataQuery(`/hotels/api/v1/hotel-extra-info?name=${name}`, {
        "Accept-Language": t("lan"),
      });
    },
    enabled: !!SearchInfo,
  });

  const [OffersInfo, setOffersInfo] = useState(false);

  const {
    data: OffersData,
    error: OffersError,
    isLoading: Offersloading,
  } = useQuery({
    queryKey: ["hotel-upgrade-periods", OffersInfo],
    queryFn: ({ queryKey }) => {
      const [_key, name] = queryKey;
      return fetchDataQuery(
        `/upgrade-tool/hotel-upgrade-periods/?hotel_id=${selectedHotel?.hotel_pk}&start_date=${dataFromSearchForReseve?.from_date}&end_date=${dataFromSearchForReseve?.to_date}`,
        { "Accept-Language": t("lan") },
      );
    },
    enabled: !!OffersInfo,
  });

  return (
    <>
      {open && (
        <Modal size={"6xl"} dismissible show={open} onClose={() => setOpen(false)}>
          <Modal.Header></Modal.Header>
          <Modal.Body dir={t("dir")}>
            {detailsFlag ? (
              <div className="flex md:flex-row flex-col justify-between">
                <div className="  flex flex-col  justify-start items-start bg-white md:w-[50%] w-full  h-full  ">
                  <Splide options={splideOptions}>
                    {hotelsDetails &&
                      hotelsDetails.images.map((item) => (
                        <SplideSlide>
                          <div className="w-full">
                            <img src={item.image_url} className="w-full lg:h-[340px] h-[200px] rounded-xl" />
                          </div>
                        </SplideSlide>
                      ))}
                  </Splide>
                  <div className="w-full flex flex-col  items-center justify-center ">
                    {hotelsDetails.features.map((item, index) => {
                      return (
                        <p className=" font-semibold capitalize text-black  " key={index}>
                          {item.feature_name}
                        </p>
                      );
                    })}
                  </div>
                </div>
                <div className=" flex flex-col justify-center items-center md:items-start gap-y-3 bg-white md:mt-8 mt-3 md:mr-10 md:w-[50%] ">
                  <div className="w-full flex flex-col md:flex-row gap-y-2 justify-between items-center ">
                    <div className=" flex flex-col  gap-y-1 justify-start items-start w-full ">
                      <div className="flex md:flex-row flex-col gap-y-4  items-center justify-between  w-full ">
                        <p className="flex items-center gap-x-1 md:text-xl text-md text-[#002244] font-semibold  ">
                          <FcDam className="w-7 h-7" /> {hotelsDetails.name}
                        </p>

                        <div className="flex items-center justify-between gap-x-3">
                          {hotelsDetails.internet === "yes" && (
                            <div className="w-full flex  justify-start items-center gap-x-2  font-semibold text-black capitalize ">
                              <div className="flex gap-y-1 justify-center items-center rounded-md border-gray-300 border w-10 h-10  ">
                                <HiWifi className="w-6 h-6 opacity-70 text-[#002244]" />
                              </div>
                            </div>
                          )}
                          <div className="w-full flex flex-col  justify-start items-center gap-x-2  font-semibold text-black capitalize ">
                            {hotelsDetails.payment_type === "cash" ? (
                              <div className="flex gap-y-1 justify-center items-center rounded-md border-gray-300 border w-10 h-10  ">
                                <GiMoneyStack className="w-8 h-8 opacity-50 text-[#002244]" />
                              </div>
                            ) : (
                              <div className="flex gap-y-1 justify-center items-center rounded-md border-gray-300 border w-10 h-10  ">
                                <BsFillCreditCardFill className="w-7 h-7 opacity-50" />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" flex items-center gap-x-1 capitalize">
                    {hotelsDetails.extra_info?.map((item) =>
                      item.details_en || item.details_ar ? (
                        <Popover
                          content={
                            <div className="z-10 w-64 text-sm text-gray-500 dark:text-gray-400">
                              <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                                <h3 className="font-semibold text-gray-900 dark:text-white">{t("details")}</h3>
                              </div>
                              <div className="px-3 py-2">
                                <p>{t("lan") === "en" ? item.details_en : item.details_ar}</p>
                              </div>
                            </div>
                          }
                        >
                          <p className="cursor-pointer text-black text-xs bg-yellow-500 p-1 rounded-md">
                            {t("lan") === "en" ? item.name_en : item.name_ar}
                          </p>
                        </Popover>
                      ) : (
                        <p className=" text-black text-xs bg-yellow-500 p-1 rounded-md">
                          {t("lan") === "en" ? item.name_en : item.name_ar}
                        </p>
                      ),
                    )}
                  </div>
                  <p className="flex items-center gap-x-1 line-clamp-none md:h-full  md:px-0 px-2 overflow-y-auto text-gray-700  ">
                    <FcInfo className="w-5 h-5" />
                    {hotelsDetails.description}
                  </p>

                  <div className="flex flex-col  items-center justify-center w-full gap-y-5    ">
                    <div className="w-full flex flex-col md:flex-row justify-between">
                      {hotelsDetails.videos.length > 0 && (
                        <div className="w-full flex justify-center items-center ">
                          <video
                            className="shadow-lg rounded-lg w-full sm:h-80"
                            controls
                            src={hotelsDetails.videos[0]?.video_url} // Replace with your video source URL
                          ></video>
                        </div>
                      )}

                      <div className="flex flex-col gap-y-2 w-full  justify-start  items-center md:mt-0 mt-5   ">
                        <div className="w-full text-center">
                          <p className="text-xl font-bold ">{t("hotelDetails")}</p>
                        </div>

                        <div className=" grid md:grid-cols-1 grid-cols-1 gap-y-2 w-[95%] justify-center  items-center  pl-6 ">
                          <p className="w-full flex  justify-start items-center gap-x-2 font-bold">
                            <div className="border-gray-300 border rounded-lg p-1 ">
                              <IoLocation className="w-5 h-5 text-red-900" />
                            </div>
                            {hotelsDetails._country_name}
                          </p>
                          <p className="w-full flex  justify-start items-center gap-x-2 font-bold">
                            <div className="border-gray-300 border rounded-lg p-1 ">
                              {" "}
                              <CiLollipop className="w-5 h-5 text-red-900" />{" "}
                            </div>
                            {hotelsDetails.city}{" "}
                          </p>
                          <p className="w-full flex  justify-start items-center gap-x-2 font-bold">
                            <div className="border-gray-300 border rounded-lg p-1 ">
                              {" "}
                              <FaCity className="w-5 h-5 text-red-900" />{" "}
                            </div>
                            {hotelsDetails.area}
                          </p>
                          <p className="w-full flex  justify-start items-center gap-x-2 font-bold">
                            <div className="border-gray-300 border rounded-lg p-1 ">
                              {" "}
                              <PiRoadHorizonDuotone className="w-5 h-5 text-red-900" />{" "}
                            </div>

                            {hotelsDetails.address}
                          </p>
                        </div>
                      </div>
                    </div>

                    <GoogleMap
                      key={selectedDate.toISOString()}
                      mapContainerStyle={mapStyles}
                      zoom={13}
                      center={defaultCenter}
                    >
                      <Marker position={defaultCenter} />
                    </GoogleMap>
                    <a
                      className=" text-base font-medium text-blue-500 text-center flex items-center gap-2 "
                      href={googleMapsUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Visit this location on Google Maps:
                      <FiExternalLink className=" text-base font-medium text-blue-500" />
                    </a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="ww-full flex h-full items-center justify-between ">
                <TopBarProgress />
                <Loading />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button color="gray" onClick={() => setOpen(false)}>
              {t("exit")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {OffersInfo && (
        <Modal dismissible show={OffersInfo} onClose={() => setOffersInfo(false)}>
          <Modal.Header></Modal.Header>
          <Modal.Body>
            {!Offersloading ? (
              OffersData &&
              OffersData?.length > 0 && (
                <div dir={t("dir")}>
                  {OffersData.map((Offer) => (
                    <div className=" gap-5 flex justify-between flex-col">
                      <p className="md:text-2xl text-xl font-bold text-[#002244]">{Offer?.hotel_upgrade_name} 🔥</p>

                      <p className="mb-5 md:text-base text-sm font-medium text-gray-600">
                        {Offer?.hotel_upgrade_description}
                      </p>
                    </div>
                  ))}
                </div>
              )
            ) : (
              <div className="ww-full flex h-full items-center justify-between ">
                <TopBarProgress />
                <Loading />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button color="gray" onClick={() => setOffersInfo(false)}>
              {t("exit")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <div className="flex flex-col items-center justify-center w-full bg-white border boxShadow  rounded-xl h-[50%] relative  ">
        {selectedHotel?.preferred && (
          <div
            className="z-10  bg-orange-500 text-white ant-ribbon ant-ribbon-placement-end  css-dev-only-do-not-override-98ntnt"
            style={{ backgroundColor: "#115e59 ", color: "#115e59 " }}
          >
            <span className="ant-ribbon-text ">
              <span className="flex justify-center items-center gap-1">
                {t("preferred")}
                <img src="/images/favorite.png" alt="img" className="scale-animation w-4" />
              </span>
            </span>
            <div className="ant-ribbon-corner "></div>
          </div>
        )}

        {selectedHotel?.hotel_extra_offers && (
          <div
            className={`z-10 ${selectedHotel?.preferred && "!mt-8"}  bg-red-600 text-white ant-ribbon ant-ribbon-placement-end  cursor-pointer  css-dev-only-do-not-override-98ntnt`}
            style={{ backgroundColor: "#eb3833 ", color: "#eb3833 " }}
            onClick={() => setOffersInfo(true)}
          >
            <span className="ant-ribbon-text showHide">
              <span style={{ cursor: "pointer" }} className="flex justify-center items-center gap-1">
                {t("OffersHotel")}
              </span>
            </span>
            <div className="ant-ribbon-corner "></div>
          </div>
        )}

        <div className="flex  flex-col md:flex-row  justify-between w-full  items-end gap-x-3   duration-300 transition-all  p-5 ">
          <div className="flex flex-col xl:flex-row  items-start gap-x-5 w-full xl:w-[80%] h-full  ">
            <div className="w-full  xl:w-[40%] md:mt-0 mt-3 ">
              <img
                src={hotel_image}
                className="w-full h-[200px] lg:w-[700px]  rounded-xl cursor-pointer"
                onClick={() => {
                  setOpen(true);
                  handleHotelsDetials();
                }}
              />
            </div>
            <div className="grid gap-y-3  xl:w-[60%]  ">
              <p className="flex flex-col lg:flex-row items-start  lg:text-lg  text-md  gap-x-2  text-[#002244] hover:text-black duration-300 transition-all font-bold   font-serif capitalize">
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setOpen(true);
                    handleHotelsDetials();
                  }}
                >
                  {hotel}{" "}
                </p>{" "}
                <div className="flex justify-start items-center mt-1">
                  <Rating>
                    {Array.from({ length: Number(selectedHotel.hotel_rate) }, (_, indedx) => (
                      <Rating.Star />
                    ))}
                  </Rating>
                </div>
              </p>
              <div className=" flex items-center gap-x-1 capitalize">
                {selectedHotel.hotel_extra_info?.map((item) => {
                  return (
                    <Popover
                      content={
                        hotelExtraData?.some((item) => item.details_en || item.details_ar) ? (
                          <div className="z-10 w-64 text-sm text-gray-500 dark:text-gray-400">
                            <div className="border-b border-gray-200 bg-gray-100 px-3 py-2 dark:border-gray-600 dark:bg-gray-700">
                              <h3 className="font-semibold text-gray-900 dark:text-white">{t("details")}</h3>
                            </div>
                            <div className="px-3 py-2">
                              {hotelExtraData?.map((item) => (
                                <p>{t("lan") === "en" ? item.details_en : item.details_ar}</p>
                              ))}
                            </div>
                          </div>
                        ) : isLoading ? (
                          <div className="z-10 w-20 text-sm text-gray-500 dark:text-gray-400">
                            <div className="px-3 py-2 flex justify-center">
                              <Spin />
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      }
                      aria-labelledby="default-popover"
                    >
                      <p
                        onClick={() => {
                          setSearchInfo(item);
                        }}
                        className="cursor-pointer text-black text-xs bg-yellow-500 p-1 rounded-md"
                      >
                        {item}
                      </p>
                    </Popover>
                  );
                })}
              </div>
              <p className="text-xs lg:text-sm line-clamp-2">{hotel_description}</p>
              <div className="flex items-center "></div>
            </div>
          </div>
          <div className="flex flex-col justify-between w-full xl:w-[20%]  ">
            <div className="flex flex-col items-center">
              <p className="flex  items-end gap-x-1 text-black   md:text-2xl text-xl font-semibold">
                {selectedHotel.rooms.slice(0, 1).map((selectedroom, index1) => {
                  return (
                    <div key={index1}>
                      {selectedroom.contracts.length !== 0 && selectedroom.merged_contracts.length !== 0
                        ? selectedroom.merged_contracts.map((price, index2) => (
                            <div key={index2} className="flex items-end gap-x-1">
                              <p>
                                {index2 === 0 &&
                                  new Intl.NumberFormat(localeCode).format(
                                    smallestOfferPrice > smallestOfferPriceMerged
                                      ? smallestOfferPriceMerged
                                      : smallestOfferPrice,
                                  )}
                              </p>
                              <p className=" md:text-md text-sm font-semibold text-yellow-600">
                                {index2 === 0 && t(price.currency_search)}
                              </p>
                            </div>
                          ))
                        : selectedroom.contracts.length === 0
                          ? selectedroom.merged_contracts.map((price, index2) => (
                              <div key={index2} className="flex items-end gap-x-1">
                                <p>
                                  {index2 === 0 && new Intl.NumberFormat(localeCode).format(smallestOfferPriceMerged)}
                                </p>
                                <p className=" md:text-md text-sm font-semibold text-yellow-600">
                                  {index2 === 0 && t(price.currency_search)}
                                </p>
                              </div>
                            ))
                          : selectedroom.contracts.map((price, index2) => {
                              return (
                                <div key={index2} className="flex items-end gap-x-1">
                                  <p>{index2 === 0 && new Intl.NumberFormat(localeCode).format(smallestOfferPrice)}</p>
                                  <p className=" md:text-md text-sm font-semibold text-yellow-600">
                                    {index2 === 0 && t(price.currency_search)}
                                  </p>
                                </div>
                              );
                            })}
                    </div>
                  );
                })}

                <span className=" md:text-xl text-sm font-normal text-gray-500 "></span>
                {/* <span className='text-2xl font-normal text-black '>{item.currency}</span> */}
              </p>

              <button
                className=" h-8 w-32 md:h-10 flex justify-center items-center gap-x-2  rounded-md text-white text-xs sm:text-sm bg-[#002244]  hover:bg-blue-900 active:scale-95 duration-500 transition-all"
                disabled={moreRoomFlag}
                onClick={() => {
                  handleRoom();
                  handleSelectedRooms(selectedHotel.hotel_pk);
                }}
              >
                {" "}
                {t("selectRoom")}{" "}
                {status ? (
                  moreRoomsData.length === 0 || moreRoomFlag ? (
                    <ButtonLoading />
                  ) : (
                    <BiSolidDownArrow
                      className={`w-3 h-3 mt-1 ${status ? " rotate-180 duration-500 transition-all " : ""} `}
                    />
                  )
                ) : (
                  <BiSolidDownArrow className="w-3 h-3 mt-1" />
                )}{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="  w-full ">{handleRoom()}</div>
      </div>
    </>
  );
};

export default SelectedSearch;
