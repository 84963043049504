import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { Select } from "antd";

const { Option } = Select;

const CityPriceSelector = ({ cityOptions, index }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium">Select City</label>
      <Field
        name={`markup_tool_price[${index}].city_price_markup_tool`}
        render={({ field, form }) => {
          const normalizeCity = (city) => (typeof city === "object" && city !== null ? city.id : city);

          // Extract selected cities as an array of IDs
          const selectedCities = (field.value || []).map((item) => normalizeCity(item.city));

          // Ensure each entry in `cityPrices` has `id` and `price`
          const cityPrices = (field.value || []).map((item) => ({
            city: normalizeCity(item.city),
            price: item.price || "",
          }));

          // Handle price change for a specific city
          const handlePriceChange = (cityId, price) => {
            const updatedPrices = cityPrices.map((item) => (item.city === cityId ? { ...item, price } : item));
            setFieldValue(field.name, updatedPrices);
          };

          // Set the same price for all selected cities
          const setPriceForAll = (price) => {
            const updatedPrices = cityPrices.map((item) => ({
              ...item,
              price,
            }));
            setFieldValue(field.name, updatedPrices);
          };

          return (
            <>
              {/* City Selector */}
              <Select
                mode="multiple"
                placeholder="Select City"
                className="w-full"
                value={selectedCities} // Always an array of IDs
                onChange={(value) => {
                  let updatedValue;
                  if (value.includes("all")) {
                    if (selectedCities.includes("all") || selectedCities.length === cityOptions.length) {
                      // Deselect "All"
                      updatedValue = [];
                    } else {
                      // Select all cities
                      updatedValue = cityOptions.map((city) => city.id);
                    }
                  } else {
                    updatedValue = value;
                  }

                  const newCityPrices = updatedValue.map((cityId) => {
                    const existingEntry = cityPrices.find((item) => item.city === cityId);
                    return existingEntry || { city: cityId, price: "" };
                  });

                  setFieldValue(field.name, newCityPrices);
                }}
                onBlur={() => form.setFieldTouched(field.name, true)}
              >
                <Option key="all" value="all">
                  All
                </Option>
                {cityOptions.map((city) => (
                  <Option key={city.id} value={city.id}>
                    {city.name}
                  </Option>
                ))}
              </Select>

              {/* City Prices List */}
              <div className="mt-4 flex flex-col gap-2">
                {cityPrices.map(({ city, price }) => {
                  const selectedCity = cityOptions.find((option) => option.id === city);

                  if (!selectedCity) return null;

                  return (
                    <div key={city} className="flex input-par items-center bg-gray-50 p-2 rounded-md">
                      <span className="input-chil">{selectedCity.name}</span>
                      <div className="input-chil gap-2 flex">
                        {/* Price Input */}
                        <input
                          type="number"
                          className="border rounded-md p-1 flex-1"
                          placeholder="Price"
                          value={price}
                          onChange={(e) => handlePriceChange(city, e.target.value)}
                        />
                        {/* Set Fixed Price */}
                        <button
                          type="button"
                          className="px-2 py-1 md:text-md text-sm w-max bg-blue-500 text-white rounded"
                          onClick={() => setPriceForAll(price)}
                        >
                          Fixed Price
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        }}
      />

      {/* Error message for city_price_markup_tool */}
      <ErrorMessage
        name={`markup_tool_price[${index}].city_price_markup_tool`}
        component="div"
        className="text-xs text-red-500"
      />
    </div>
  );
};

export default CityPriceSelector;
