import React, { useEffect, useState } from "react";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import Loading from "../Loading/Loading";

const MarkupDetails = () => {
  const [markupData, setMarkupData] = useState(null); // Start with null to show loading state
  const { id } = useParams();
  const {
    data: markup,
    error,
    isSuccess,
    isLoading: markupLoading,
  } = useQuery({
    queryKey: ["markupdata", id],
    queryFn: () => fetchDataQuery(`/markup/api/${id}/`),
  });

  // Set markupData once the query is successful
  useEffect(() => {
    if (isSuccess && markup) {
      setMarkupData(markup);
    }
  }, [markup, isSuccess]);

  const MarkupDetails = ({ id, name, nameAr }) => (
    <div className=" flex flex-col rounded-lg  gap-4 mb-4 ">
      <span className="mb-4 border rounded-lg bg-gray-50 shadow-sm p-4 ">
        Markup ID # <span className="font-semibold">{id}</span>
      </span>
      <div className="mb-4 border justify-between w-full input-par  rounded-lg bg-gray-50 shadow-sm p-4 flex gap-4 ">
        <p className="text-md text-gray-800 mb-4 input-chil">
          Name: <span className="font-semibold">{name}</span>
        </p>
        <p className="text-md text-gray-800 mb-4 input-chil">
          Arabic Name: <span className="font-semibold">{nameAr}</span>
        </p>
      </div>
    </div>
  );
  const RoomsList = ({ rooms, roomPrice, label }) => (
    <div className="flex flex-col">
      <span className="block font-semibold underline">{label}</span>
      <div className="grid  md:grid-cols-2 border rounded-md border-orange-400 grid-cols-1 mt-4 bg-slate-100 p-2 gap-2">
        {rooms?.map((room) => (
          <div
            key={room.id}
            className="flex flex-col gap-2   bg-slate-50 rounded-md p-2 text-sm font-semibold text-gray-600"
          >
            <span>
              ID: <span className="text-gray-800">{room.id}</span>
            </span>
            <span>
              Hotel Name: <span className="text-gray-800">{room.hotel_name.name}</span>
            </span>
            <div className="flex gap-2 items-center justify-between w-full">
              <span>
                Room: <span className="text-gray-800">{room.room_name}</span>
              </span>
              <span>
                Price: <span className="text-gray-800">{roomPrice}</span>
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  const PriceMarkupList = ({ data, label, type }) => {
    const borderClasses = {
      hotel: "border border-red-400",
      country: "border border-green-400",
      city: "border border-blue-400",
      rooms: "border border-orange-400",
      default: "border border-yellow-400",
    };

    const typeTitles = {
      hotel: "Hotels Markup",
      country: "Countries Markup",
      city: "Cities Markup",
      rooms: "Rooms Markup",
      default: "All Markup",
    };

    return (
      <div className="flex flex-col ">
        <span className="block font-semibold underline">{typeTitles[type] || typeTitles.default}</span>
        <div
          className={`${borderClasses[type] || borderClasses.default} rounded-md grid md:grid-cols-2 grid-cols-1 mt-4 bg-slate-100 p-2 gap-2`}
        >
          {data.map((item) => (
            <div
              key={item.id}
              className="flex flex-col gap-2 bg-slate-50 rounded-md p-2 text-sm font-semibold text-gray-600"
            >
              <span>
                ID: <span className="text-gray-800">{item.id}</span>
              </span>
              <div className="flex gap-2 items-center justify-between w-full">
                <span>
                  {label}: <span className="text-gray-800">{item[label.toLowerCase()]?.name || "N/A"}</span>
                </span>
                <span>
                  Price: <span className="text-gray-800">{item.price || "N/A"}</span>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const MarkupToolPrice = ({ item }) => (
    <div className="p-4 mb-4 border rounded-lg bg-gray-50 shadow-sm">
      <div className="mb-3">
        <p className="text-sm font-semibold text-gray-600">
          Market: <span className="text-gray-800">{item.market?.name}</span>
        </p>
        <p className="text-sm font-semibold text-gray-600">
          User Category: <span className="text-gray-800">{item.user_category?.name}</span>
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4 mb-3">
        <p className="text-sm font-semibold text-gray-600">
          Price Type: <span className="text-gray-800 capitalize">{item?.type_price}</span>
        </p>
      </div>
      <div className="mb-3">
        <p className="text-sm font-semibold text-gray-600">
          Minimum Nights: <span className="text-gray-800">{item?.min_nights}</span>
        </p>
        <p className="text-sm font-semibold text-gray-600">
          Valid From: <span className="text-gray-800">{item?.from_date}</span>
        </p>
        <p className="text-sm font-semibold text-gray-600">
          Valid To: <span className="text-gray-800">{item?.to_date}</span>
        </p>
      </div>

      {item.country_price_markup_tool?.length > 0 && (
        <PriceMarkupList data={item.country_price_markup_tool} label="Country" type="country" />
      )}
      {item.city_price_markup_tool?.length > 0 && (
        <PriceMarkupList data={item.city_price_markup_tool} label="City" type="city" />
      )}
      {item.hotel_price_markup_tool?.length > 0 && (
        <PriceMarkupList data={item.hotel_price_markup_tool} label="Hotel" type="hotel" />
      )}
      {item.rooms?.length > 0 && (
        <RoomsList rooms={item.rooms} roomPrice={item.price_room} label="Rooms" type="rooms" />
      )}
      {item.price && (
        <p className="text-sm font-semibold text-gray-600 mt-4">
          All Markup Price: <span className="text-gray-800">{item.price}</span>
        </p>
      )}
    </div>
  );
  const MarkupDataDisplay = ({ markupData }) => {
    if (!markupData) return <Loading />;
    return (
      <div className="p-6  rounded-lg flex flex-col  ">
        <MarkupDetails id={markupData.id} name={markupData.name} nameAr={markupData.name_ar} />
        {markupData.markup_tool_price?.map((item) => (
          <MarkupToolPrice key={item.id} item={item} />
        ))}
      </div>
    );
  };

  return <MarkupDataDisplay markupData={markupData} />;
};

export default MarkupDetails;
