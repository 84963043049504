import { useQuery } from "@tanstack/react-query";
import { Select, Spin } from "antd";
import { ErrorMessage, Field, useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import fetchDataQuery from "../../../../react_query/fetchDataQuery";
import Loading from "../../Loading/Loading";

const { Option } = Select;
// const fetchHotels = (city) => fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${city}]`);

const HotelPriceSelector = ({ cityOptions, index }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const [localCityData, setLocalCityData] = useState({});
  const [localHotelData, setLocalHotelData] = useState({});

  const fetchCity = (hotels) =>
    hotels.length > 0 && fetchDataQuery(`/hotels/api/v1/get-hotel-cities/?hotels=${hotels}`);

  const fetchHotels = (cityIDs) =>
    cityIDs.length > 0 && fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${cityIDs}]`);

  useEffect(() => {
    values.markup_tool_price.forEach((_, index) => {
      const hotelIDs = values.markup_tool_price[index]?.hotel_price_markup_tool?.map((item) => item.hotel?.id) || [];

      if (hotelIDs.length > 0) {
        fetchCity(hotelIDs).then((cities) => {
          setLocalCityData((prev) => ({ ...prev, [index]: cities || [] }));
        });
      }
    });
  }, [values.markup_tool_price]);

  useEffect(() => {
    Object.keys(localCityData).forEach((index) => {
      const cityIDs = localCityData[index]?.map((city) => city.id) || [];
      if (cityIDs.length > 0) {
        fetchHotels(cityIDs).then((hotels) => {
          setLocalHotelData((prev) => ({ ...prev, [index]: hotels || [] }));
        });
      }
    });
  }, [localCityData]);

  const handleCityChange = (value, form, field, index) => {
    const selectedCity = cityOptions.find((city) => city.id === value);
    setLocalCityData((prev) => ({ ...prev, [index]: selectedCity ? [selectedCity] : [] }));
    form.setFieldValue(field.name, value);
    form.setFieldValue(`markup_tool_price[${index}].hotel_price_markup_tool`, []);
    form.setFieldValue(`markup_tool_price[${index}].hotel_price`, []);
  };

  const handlePriceChange = (hotelId, price, index) => {
    const hotelPrices = values.markup_tool_price[index]?.hotel_price_markup_tool || [];
    const updatedPrices = hotelPrices.map((item) => (item.hotel.id === hotelId ? { ...item, price } : item));
    setFieldValue(`markup_tool_price[${index}].hotel_price_markup_tool`, updatedPrices);
  };

  const setPriceForAll = (price, index) => {
    const hotelPrices = values.markup_tool_price[index]?.hotel_price_markup_tool || [];
    const updatedPrices = hotelPrices.map((item) => ({ ...item, price }));
    setFieldValue(`markup_tool_price[${index}].hotel_price_markup_tool`, updatedPrices);
  };

  return (
    <>
      {/* {values.markup_tool_price.map((_, index) => ( */}
      <div key={index}>
        {/* City Selector */}
        <div className="mb-4">
          <label className="block text-sm font-medium">Select City </label>
          <Field
            name={`markup_tool_price[${index}].city`}
            render={({ field, form }) => (
              <Select
                placeholder="Select city"
                className="w-full"
                value={field.value || { label: localCityData[index]?.[0]?.name, value: localCityData[index]?.[0]?.id }}
                onChange={(value) => handleCityChange(value, form, field, index)}
                onBlur={() => form.setFieldTouched(field.name, true)}
              >
                {cityOptions.map((val) => (
                  <Option key={val.id} value={val.id}>
                    {val.name}
                  </Option>
                ))}
              </Select>
            )}
          />
        </div>
        {/* Hotel Selector */}
        <div className="mb-4">
          <label className="block text-sm font-medium">Select Hotel </label>
          <Field
            name={`markup_tool_price[${index}].hotel_price_markup_tool`}
            render={({ field, form }) => (
              <Select
                mode="multiple"
                placeholder="Select hotel"
                className="w-full"
                value={field.value?.map((item) => ({
                  label: item.hotel.name,
                  value: item.hotel.id,
                }))}
                onChange={(value) => {
                  let updatedValue;

                  if (value.includes("all")) {
                    const allHotelIds = localHotelData[index]?.map((hotel) => hotel.id) || [];
                    const isAllSelected = field.value?.length === allHotelIds.length;

                    if (isAllSelected) {
                      // Deselect all hotels
                      updatedValue = [];
                    } else {
                      // Select all hotels
                      updatedValue = allHotelIds;
                    }
                  } else {
                    updatedValue = value;
                  }

                  // Update field value
                  const newHotelPrices = updatedValue.map((hotelId) => {
                    const existing = field.value?.find((item) => item.hotel.id === hotelId);
                    return existing || { hotel: { id: hotelId }, price: "" };
                  });

                  form.setFieldValue(field.name, newHotelPrices);
                }}
                onBlur={() => form.setFieldTouched(field.name, true)}
              >
                <Option key="all" value="all">
                  All
                </Option>
                {localHotelData[index]?.map((val) => (
                  <Option key={val.id} value={val.id}>
                    {val.name}
                  </Option>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="mt-4 flex flex-col gap-2">
          {values?.markup_tool_price[index]?.hotel_price_markup_tool?.map((hotelMarkup) => {
            const hotel = localHotelData && localHotelData[index]?.find((h) => h.id === hotelMarkup.hotel.id);
            // if (!hotel) return null;

            return hotel ? (
              <div key={hotel?.id} className="flex w-full input-par items-center bg-gray-50 p-2 rounded-md">
                <span className="input-chil">{hotel?.name}</span>
                <div className="flex gap-2 input-chil ">
                  <input
                    type="number"
                    className="border flex-1 rounded-md p-1"
                    placeholder="Price"
                    value={hotelMarkup.price}
                    onChange={(e) => handlePriceChange(hotel?.id, e.target.value, index)}
                  />
                  <button
                    type="button"
                    className="px-2 py-1 bg-blue-500 text-white rounded"
                    onClick={() => setPriceForAll(hotelMarkup.price, index)}
                  >
                    Fixed Price
                  </button>
                </div>
              </div>
            ) : (
              <Loading />
            );
          })}
        </div>
      </div>
      {/* ))} */}
    </>
  );
};

export default HotelPriceSelector;
