import React, { useEffect, useState } from "react";
import ListFilters from "../../../utils/ListFilters";
import { VscGitPullRequestNewChanges } from "react-icons/vsc";
import { MdOutlinePermIdentity, MdOutlineSettings, MdPendingActions } from "react-icons/md";
import { IoLockClosedSharp } from "react-icons/io5";
import { Button, Modal } from "flowbite-react";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { AiFillDelete, AiOutlineEye, AiOutlineLoading } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import { toast } from "react-toastify";
import { DetailsModal } from "../../../customComponents/TableComponents";
import Errorfetch from "../Errorfetch/Errorfetch";
import Loading from "../Loading/Loading";
import usePermissions from "../../../customHooks/usePermissions";

function stripHTMLTags(input) {
  // Use a regular expression to remove any HTML tags
  return input.replace(/<\/?[^>]+(>|$)/g, "");
}

const TasksList = () => {
  const [listPageSize, setListPageSize] = useState(20);

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [activeStatus, setActiveStatus] = useState("new");
  const [taskData, setTaskData] = useState([]);
  const totalPages = taskData && Math.ceil(taskData?.count / listPageSize);
  const {
    data: taskList,
    error: errorTask,
    isSuccess: taskSuccess,
    isLoading: taskLoading,
  } = useQuery({
    queryKey: ["taskList", currentPage, listPageSize, activeStatus],
    queryFn: () =>
      fetchDataQuery(
        `/tasks-management/api/v1/task-assignments/?status=${activeStatus}&page=${currentPage}&page_size=${listPageSize}`,
      ),
  });
  useEffect(() => {
    if (taskSuccess && taskList) {
      setTaskData(taskList);
    }
  }, [taskList, taskSuccess]);
  const rows =
    filteredData &&
    filteredData?.map((task) => {
      return {
        id: task.id,
        name: task.name,
        user: task.user?.map((u) => u.first_name + u.last_name + "(" + u.username + ")"),
        department: task.department?.map((d) => d.name),
        task_details: task.task_details,
        status: task.status,
        created_at: task.created_at,
      };
    });
  useEffect(() => {
    if (taskSuccess && taskList) {
      setTaskData(taskList);

      // Filter tasks based on the default status ("new") when data is first loaded
      const initialFilteredTasks = taskList?.results?.filter(
        (task) => task.status.toLowerCase() === activeStatus.toLowerCase(),
      );
      setFilteredData(initialFilteredTasks);
    }
  }, [taskList, taskSuccess, activeStatus]);
  const updateFilteredList = (updatedTaskList) => {
    const filteredTasks = updatedTaskList.filter((task) => task.status.toLowerCase() === activeStatus.toLowerCase());
    setFilteredData(filteredTasks);
  };
  const [permissions, permissionFlag, permissionsError] = usePermissions([
    "tasks_management.add_taskassignment",
    "tasks_management.view_taskassignment",
    "tasks_management.delete_taskassignment",
    "tasks_management.change_taskassignment",
  ]);
  const isViewingOfferListAllowed =
    permissions?.find((permission) => permission.name === "tasks_management.view_taskassignment")?.value === true;

  const isDeletingOfferAllowed =
    permissions?.find((permission) => permission.name === "tasks_management.delete_taskassignment")?.value === true;

  const isEditingOfferAllowed =
    permissions?.find((permission) => permission.name === "tasks_management.change_taskassignment")?.value === true;

  // this will delete the hotel stored in the state by passing it to the action then remove the popup

  if (!permissionFlag) {
    return <Loading />;
  }

  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  // if (errorTask || !taskList || !permissions) {
  //   return <Errorfetch error={errorTask?.message || "Error Fetching Tasks"} />;
  // }
  const columns = [
    {
      key: "id",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePermIdentity className="w-5 h-5  text-yellow-500" />
          ID
        </div>
      ),
      render: (text, params) => {
        return (
          <Link to={`/dashboard/view-task/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {params.id}
          </Link>
        );
      },
      dataIndex: "id",
    },
    {
      key: "name",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePermIdentity className="w-5 h-5  text-yellow-500" />
          Name
        </div>
      ),
      render: (text, params) => {
        const cleanText = stripHTMLTags(params?.name);
        const truncatedText = cleanText.length > 40 ? `${cleanText.substr(0, 40)}...` : cleanText;
        const truncatedTextTitle = cleanText.length > 40 ? `${cleanText}` : cleanText;
        return (
          <Link
            to={`/dashboard/view-task/${params.id}`}
            className="w-full h-full  flex  gap-x-1 "
            title={truncatedTextTitle}
          >
            {truncatedText}
          </Link>
        );
      },
      dataIndex: "name",
    },
    {
      key: "user",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePermIdentity className="w-5 h-5  text-yellow-500" />
          User
        </div>
      ),
      render: (text, params) => {
        return (
          <div className="w-full h-full  flex  gap-x-1 ">
            {params.user.length > 1 ? (
              <DetailsModal title="Users">
                <div className=" flex flex-col gap-2">
                  {params.user?.map((i, idx) => (
                    <p key={idx}>
                      {idx + 1}- {i}
                    </p>
                  ))}
                </div>
              </DetailsModal>
            ) : (
              params?.user
            )}
          </div>
        );
      },
      dataIndex: "user",
    },
    {
      key: "department",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePermIdentity className="w-5 h-5  text-yellow-500" />
          Department
        </div>
      ),
      render: (text, params) => {
        return (
          <div className="w-full h-full  flex  gap-x-1 ">
            {params.department?.length > 1 ? (
              <DetailsModal title="Departments">
                <div className=" flex flex-col gap-2">
                  {params.department?.map((i, idx) => (
                    <p key={idx}>
                      {idx + 1}- {i}
                    </p>
                  ))}
                </div>
              </DetailsModal>
            ) : (
              params?.department
            )}
          </div>
        );
      },

      dataIndex: "department",
    },
    {
      key: "task_details",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePermIdentity className="w-5 h-5  text-yellow-500" />
          Task Details
        </div>
      ),
      render: (text, params) => {
        const cleanText = stripHTMLTags(params?.task_details);
        const truncatedText = cleanText.length > 20 ? `${cleanText.substr(0, 20)}...` : cleanText;
        const truncatedTextTitle = cleanText.length > 20 ? `${cleanText}` : cleanText;
        return (
          <Link
            title={stripHTMLTags(truncatedTextTitle)}
            to={`/dashboard/view-task/${params.id}`}
            className="w-full h-full  flex  gap-x-1 "
          >
            <div dangerouslySetInnerHTML={{ __html: stripHTMLTags(truncatedText) }} />
          </Link>
        );
      },
      dataIndex: "task_details",
    },
    {
      key: "status",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePermIdentity className="w-5 h-5  text-yellow-500" />
          Status
        </div>
      ),
      render: (text, params) => {
        return (
          <Link to={`/dashboard/view-task/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {params.status}
          </Link>
        );
      },
      dataIndex: "status",
    },
    {
      key: "created_at",
      title: (
        <div className="flex w-full    gap-x-1 text-xs">
          <MdOutlinePermIdentity className="w-5 h-5  text-yellow-500" />
          Created At
        </div>
      ),
      render: (text, params) => {
        const dateStr = params?.created_at?.slice(0, 10).split("-");
        const formattedDate = dateStr && `${dateStr[2]}-${dateStr[1]}-${dateStr[0]}`;
        const dateStr2 = params?.created_at?.slice(11, 19).split(":");
        const formattedDate2 = dateStr && `${dateStr2[0]}:${dateStr2[1]}`;
        return (
          <Link to={`/dashboard/view-task/${params.id}`} className="w-full h-full  flex  gap-x-1 ">
            {formattedDate + ", " + formattedDate2}
          </Link>
        );
      },
      dataIndex: "created_at",
    },
    {
      key: "operations",

      title: (
        <div className=" flex items-center w-full justify-center gap-x-1 text-xs ">
          <MdOutlineSettings className="w-5 h-5  text-yellow-500" />
          OPERATIONS
        </div>
      ),
      dataIndex: "operations",

      render: (text, params) => {
        return (
          <div className="flex items-center justify-center w-full gap-x-2">
            {isViewingOfferListAllowed && (
              <Link to={`/dashboard/view-task/${params.id}`}>
                <AiOutlineEye className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-slate-800" />
              </Link>
            )}

            {isEditingOfferAllowed && (
              <Link to={`/dashboard/Edit-task/${params.id}`}>
                <FiEdit2 className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-green-800" />
              </Link>
            )}

            {isDeletingOfferAllowed && (
              <DeleteTaskModal taskId={params.id} taskName={params.name} updateFilteredList={updateFilteredList} />
            )}
            {/* );
              }
            })} */}
          </div>
        );
      },
    },
  ];

  const handleTableChange = (page, size) => {
    setListPageSize(size);
    setCurrentPage(page);
  };
  const handleChange = (value) => {
    setActiveStatus(value);
    setCurrentPage(1);
    const filteredTasks = taskData?.results?.filter((task) => task.status.toLowerCase() === value.toLowerCase());

    // Set the filtered tasks to filteredData state
    setFilteredData(filteredTasks);
  };
  const isAddingingOfferListAllowed =
    permissions?.find((permission) => permission.name === "tasks_management.add_taskassignment")?.value === true;
  if (permissionsError) return <Errorfetch error={permissionsError.message} />;

  return !taskLoading ? (
    <section className="formPage-wrap">
      <div className=" flex md:flex-row gap-4 max-md:flex-col w-full">
        <div className="sm:w-max w-full flex flex-row max-md:flex-col  justify-center items-center   ">
          <Button.Group className="flex flex-wrap max-sm:gap-2 max-sm:flex-col  max-sm:w-full">
            <Button color={activeStatus === "new" ? "info" : "gray"} onClick={() => handleChange("new")}>
              <span className="flex items-center gap-2">
                <VscGitPullRequestNewChanges />
                New Tasks
              </span>
            </Button>
            <Button color={activeStatus === "pending" ? "info" : "gray"} onClick={() => handleChange("pending")}>
              <span className="flex items-center gap-2">
                <MdPendingActions />
                Pending Tasks
              </span>
            </Button>
            <Button color={activeStatus === "done" ? "info" : "gray"} onClick={() => handleChange("done")}>
              <span className="flex items-center gap-2">
                <IoLockClosedSharp />
                Done Tasks
              </span>
            </Button>
          </Button.Group>
        </div>
        {isAddingingOfferListAllowed && (
          <Link to="/dashboard/add-task" className="addButton whitespace-nowrap	 !py-1 sm:!w-max !w-full text-sm">
            Add Task
          </Link>
        )}
      </div>
      <ListFilters
        columns={columns}
        rows={rows}
        title={
          activeStatus === "new"
            ? "Task Manager New List"
            : activeStatus === "pending"
              ? "Task Manager pending List"
              : "Task Manager closed List"
        }
        handlePagination={handleTableChange}
        currentPage={currentPage}
        totalPages={totalPages}
        listPageSize={listPageSize}
      />{" "}
    </section>
  ) : (
    <Loading />
  );
};

export default TasksList;
const DeleteTaskModal = ({ taskId, taskName, updateFilteredList }) => {
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();

  const { isPending, mutate: deleteTask } = useMutation({
    mutationFn: () => mutateDataQuery(`/tasks-management/api/v1/task-assignments/${taskId}/`, "delete"),
    mutationKey: ["delete-task", taskId],
    networkMode: "always",
    retry: false,
    onSuccess: () => {
      toast.success(`Task ${taskName} deleted successfully`);

      // Close the modal
      setShowModal(false);

      // Invalidate the query to refetch the list of tasks
      queryClient.invalidateQueries(["taskList"], {
        onSuccess: (updatedTaskList) => {
          // Call the passed callback to update the filtered list
          updateFilteredList(updatedTaskList.results);
        },
      });
    },
    onError: (error) => {
      // Log the entire error object
      if (error.response?.status === 400) {
        error.response.data.forEach((err) => {
          toast.error(err.message);
        });
      } else {
        toast.error(error.message || "Failed to delete task");
      }
    },
  });
  const modalTheme = {
    content: {
      base: "relative h-full w-full p-4 md:h-auto",
      inner: "relative flex max-h-[90dvh] flex-col rounded-lg bg-white shadow dark:bg-gray-700",
    },
  };

  return (
    <>
      <button onClick={() => setShowModal(true)} className="cursor-pointer">
        <AiFillDelete className="w-5 h-5 cursor-pointer hover:scale-105 active:scale-95 duration-300 transition-all text-red-700 hover:text-red-900" />
      </button>

      <Modal
        theme={modalTheme}
        popup
        dismissible
        show={showModal}
        position={"center"}
        onClose={() => setShowModal(false)}
      >
        <Modal.Body className="flex flex-col py-4 bg-gray-100 rounded-lg gap-8">
          <h2 className="text-xl capitalize font-bold text-gray-900 mb-4 text-center">
            Are you sure you want to delete task "{taskName}"?
          </h2>
          <div className="flex justify-center gap-4">
            <Button
              isProcessing={isPending}
              // processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />}
              disabled={isPending}
              color="failure"
              onClick={() => {
                deleteTask();
              }}
            >
              {isPending ? <AiOutlineLoading className="animate-spin w-5 h-5" /> : "Yes, I'm sure"}
            </Button>
            <Button color="gray" onClick={() => setShowModal(false)}>
              No, cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
