import React, { useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import { Button } from "flowbite-react";
import { FormLabel, FormSelectInput } from "../../../customComponents/FormComponents";
import DeleteButton from "../../../customComponents/DeleteButton";
import Loading from "../Loading/Loading";

const fetchCities = () => fetchDataQuery("/search/api/v1/get-cities/");
const fetchAreas = (city) => fetchDataQuery(`/search/api/load-areas/?city_name=${city}`);
const fetchHotels = (city, area) => fetchDataQuery(`/en/hotels/api/v1/hotel-filter/?cities=[${city}]&areas=[${area}]`);
const fetchRooms = (hotel) => fetchDataQuery(`/en/rooms/api/v1/get-rooms-in-hotel/${hotel}/`);
// const fetchContacts = (hotel) => fetchDataQuery(`/hotels/api/hotel-contact/new?hotel=${hotel}`);

const validationSchema = Yup.object({
  city: Yup.object().required("City is required").nullable(),
  area: Yup.object().required("Area is required").nullable(),
  hotel: Yup.object().required("Hotel is required").nullable(),
  meal_plan: Yup.object().required("Meals is required").nullable(),
  room_type: Yup.array().min(1, "At least one room must be selected").required("Room is required").nullable(),
  number_of_persons: Yup.number()
    .typeError("Number of adults must be a number")
    .min(1, "There must be at least 1 adult")
    .required("Adult number is required"),
  childrenAges: Yup.array()
    .of(
      Yup.number().typeError("Age must be a number").min(0, "Age must be greater than or equal to 0"), // No negative ages
    )
    .test("is-sorted", "Each child's age must be greater than or equal to the previous child's age", function (ages) {
      if (!Array.isArray(ages)) return false; // Ensure it's an array
      for (let i = 1; i < ages.length; i++) {
        if (ages[i] < ages[i - 1]) {
          return false; // Check if current age is smaller than the previous age
        }
      }
      return true;
    })
    .required("Children ages are required"),
  check_in_date: Yup.date().nullable().required("Check-in date is required"),

  check_out_date: Yup.date()
    .nullable()
    .required("Check-out date is required")
    .test("is-greater", "Check-out date must be after check-in date", function (value) {
      const { check_in_date } = this.parent;
      return value && check_in_date && value > check_in_date;
    }),
  client_name: Yup.string().required("Client name is required"),
  client_number: Yup.string()
    .matches(/^[0-9]+$/, "Client number must be numeric")
    .required("Client number is required"),
  request_details: Yup.string().required("Request details are required"),
});

const SpecialRateForm = ({ initialValues, onSubmit, isPending, loader, edit }) => {
  const [chosenCity, setChosenCity] = useState(null);
  const [chosenArea, setChosenArea] = useState(null);
  const [chosenHotels, setChosenHotels] = useState([]);
  const [chosenHotelVal, setChosenHotelsVal] = useState(null);
  const [chosenRoomVal, setChosenRoomVal] = useState(null);
  const [roomValue, setRoomValue] = useState(null);
  // const [contactData, setContacts] = useState(null);
  const {
    data: cities,
    isLoading: loadingCities,
    error: citiesError,
  } = useQuery({
    queryKey: "cities",
    queryFn: fetchCities,
  });

  const chosenCityVal = chosenCity?.value;

  const {
    data: areas,
    isLoading: loadingAreas,
    error: areasError,
    refetch: refetchAreas,
  } = useQuery({
    queryKey: ["areas", chosenCityVal, initialValues?.city?.value],
    queryFn: () => fetchAreas(chosenCityVal || initialValues?.city?.value),
    enabled: !!chosenCityVal && chosenCityVal !== "All",
  });
  const {
    data: hotels,
    isLoading: loadingHotels,
    error: hotelsError,
    refetch: refetchHotels,
  } = useQuery({
    queryKey: ["hotels", chosenCityVal, chosenArea, initialValues?.city?.value, initialValues?.area?.value],
    queryFn: () => {
      const areaValue = chosenArea?.value === "All" ? areas.map((area) => area.id) : chosenArea?.value;
      return fetchHotels(chosenCityVal || initialValues?.city?.value, areaValue || initialValues?.area?.value);
    },
    enabled:
      (!!chosenCityVal && (!!chosenArea || chosenCityVal === "All")) ||
      (!!initialValues?.city?.value && !!initialValues?.area?.value),
  });

  const {
    data: rooms,
    isLoading: loadingRooms,
    error: roomError,
    refetch: refetchRooms,
  } = useQuery({
    queryKey: ["rooms", chosenHotelVal?.value, initialValues?.hotel?.value],
    queryFn: () => fetchRooms(chosenHotelVal?.value || initialValues?.hotel?.value),
    enabled: !!chosenHotelVal?.value || !!initialValues?.hotel?.value, // Ensure query only runs if hotel has a value
  });
  useEffect(() => {
    if (chosenCity || initialValues?.city) {
      if (chosenCity?.value !== "All") {
        refetchAreas();
      } else {
        setChosenArea({ label: "All", value: "All" });
      }
    }
  }, [chosenCity, refetchAreas]);

  useEffect(() => {
    if (chosenArea || initialValues?.area) {
      refetchHotels();
    }
  }, [chosenArea, refetchHotels]);
  useEffect(() => {
    if (chosenHotelVal?.value || initialValues.hotel) {
      refetchRooms(); // Refetch rooms when hotel value changes
    }
  }, [chosenHotelVal, refetchRooms]);

  useEffect(() => {
    if (hotels) {
      setChosenHotels(hotels.map((hotel) => ({ label: hotel.name, value: hotel.id })));
    }
  }, [hotels]);

  useEffect(() => {
    if (chosenHotelVal?.value && rooms) {
      setChosenRoomVal(rooms?.map((room) => ({ label: room.room_name, value: room.id })));
    }
  }, [chosenHotelVal?.value, rooms]);

  const cityOptions = useMemo(() => cities?.map((city) => ({ label: city.name, value: city.id })), [cities]);
  const areaOptions = useMemo(
    () => [{ label: "All", value: "All" }, ...(areas?.map((area) => ({ label: area.name, value: area.id })) || [])],
    [areas],
  );

  const hotelOptions = useMemo(() => [...chosenHotels], [chosenHotels]);
  const roomOptions = useMemo(() => {
    if (chosenRoomVal && chosenRoomVal.length > 0) {
      return chosenRoomVal;
    }

    // Return room options if rooms are available
    if (rooms && rooms.length > 0) {
      return rooms.map((room) => ({ label: room.room_name, value: room.id }));
    }

    return []; // Default return value if no room options are available
  }, [chosenRoomVal, rooms]);
  useEffect(() => {
    // When city changes, clear area, hotel, and room selections
    if (chosenCity) {
      setChosenArea(null); // Clear area when city changes
      setChosenHotelsVal(null); // Clear hotel when city changes
      setRoomValue([]); // Clear room when city changes
    }
  }, [chosenCity]);

  useEffect(() => {
    // When area changes, clear hotel and room selections
    if (chosenArea) {
      setChosenHotelsVal(null); // Clear hotel when area changes
      setRoomValue([]); // Clear room when area changes
    }
  }, [chosenArea]);

  useEffect(() => {
    // When hotel changes, clear room selections
    if (chosenHotelVal) {
      setRoomValue([]); // Clear room when hotel changes
    }
  }, [chosenHotelVal]);
  const mealsOptions = [
    { label: "B.O", value: "B.O" },
    { label: "B.B", value: "B.B" },
    { label: "H.B", value: "H.B" },
    { label: "F.B", value: "F.B" },
    { label: "Soft All", value: "Softall" },
  ];
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ values, setFieldValue, errors, touched }) => (
        <Form className="form-wrap gap-4 grid">
          <div className="input-par">
            <div className="input-chil">
              <label className="font-semibold w-full flex gap-x-2 text-[#002244]">City</label>
              <Select
                className="w-full h-10 text-gray-800 font-semibold border rounded-lg bg-white"
                options={cityOptions}
                placeholder="Select City"
                onChange={(e) => {
                  setChosenCity(e);
                  setFieldValue("city", e);
                }}
                value={values.city}
                isLoading={loadingCities}
              />
              {/* {citiesError && <p className="text-red-500">{citiesError.message}</p>} */}
              <ErrorMessage name="city" component="div" className="text-sm text-red-500" />
            </div>
            <div className="input-chil">
              <label className="w-full text-gray-600 font-semibold flex items-center">Area</label>

              <Select
                isLoading={loadingAreas}
                name="area"
                options={areaOptions}
                isSearchable
                isDisabled={!values.city}
                value={values?.area}
                className="w-full h-10 rounded-lg bg-white"
                placeholder="Select Area"
                onChange={(e) => {
                  setChosenArea(e);
                  setFieldValue("area", e);
                }}
              />
              <ErrorMessage name="area" component="div" className="text-sm text-red-500" />
            </div>
          </div>
          <div className="input-par">
            <div className="input-chil">
              <label className="w-full text-gray-600 font-semibold flex items-center">Hotel</label>

              <Select
                name="hotel"
                options={hotelOptions}
                isSearchable
                isDisabled={!values.area}
                value={values.hotel}
                className="w-full  rounded-lg bg-white"
                placeholder="Hotel"
                onChange={(e) => {
                  setFieldValue("hotel", e);
                  setChosenHotelsVal(e);
                }}
              />
              <ErrorMessage name="hotel" component="div" className="text-sm text-red-500" />
            </div>
            <div className="input-chil">
              <label className="w-full text-gray-600 font-semibold flex items-center">Room</label>

              <Select
                name="room"
                options={roomOptions} // Ensure roomOptions is an array of options like [{ label: 'Room 1', value: 'room1' }, ...]
                isSearchable
                isDisabled={!values?.hotel} // Disabled if no hotel is selected
                value={values?.room_type} // Since it's a multi-select, pass the array of selected values
                className="w-full rounded-lg bg-white"
                placeholder="Select Room"
                onChange={(selectedOptions) => {
                  setRoomValue(selectedOptions); // Update state with an array of selected rooms
                  setFieldValue("room_type", selectedOptions); // Update Formik field value with selected options
                }}
                isMulti // Enable multi-select
              />

              <ErrorMessage name="room_type" component="div" className="text-sm text-red-500" />
            </div>
          </div>

          <div className="flex flex-col">
            <div className="input-chil">
              <label htmlFor="number_of_persons">Number of Adults:</label>
              <Field
                value={values?.number_of_persons}
                name="number_of_persons"
                type="number"
                className="border rounded-md w-full h-10 px-2"
              />
              <ErrorMessage name="number_of_persons" component="div" className="text-sm text-red-500" />
            </div>
            <div className="input-chil  form-wrap !bg-gray-50 !p-2 !text-center mb-4">
              <FieldArray name="childrenAges">
                {({ push, remove }) => (
                  <div className="flex flex-col gap-2  ">
                    <div className="flex w-full justify-center items-center">
                      <FormLabel label="Children Ages" htmlFor="childrenAges" />
                    </div>
                    {values?.childrenAges?.length > 0 ? (
                      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                        {values.childrenAges?.map((age, index) => (
                          <div key={index} className="flex justify-between items-center gap-4">
                            <div className="flex flex-col mb-4 w-full">
                              <FormLabel label={`Child ${index + 1} Age`} htmlFor={`childrenAges.${index}`} />
                              <div className="flex justify-between items-center gap-2">
                                {/* Age select input */}
                                <FormSelectInput
                                  styles={{ container: (provided) => ({ ...provided, width: "100%" }) }}
                                  defaultValue={{ label: age, value: age }}
                                  placeholder={`Child ${index + 1} Age`}
                                  name={`childrenAges.${index}`}
                                  options={Array.from({ length: 12 }, (_, i) => ({ label: i, value: i }))}
                                />
                                {/* Delete button for last item */}
                                {index === values.childrenAges.length - 1 && (
                                  <DeleteButton onDelete={() => remove(index)} className="w-9" />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="font-semibold text-gray-600 text-center">No children ages added</p>
                    )}

                    {/* Error message */}
                    <ErrorMessage name="childrenAges" component="div" className="text-sm text-red-500" />

                    {/* Add button */}
                    <Button
                      className="self-center"
                      type="button"
                      onClick={() => {
                        try {
                          push(0); // Or another default value
                        } catch (error) {}
                      }}
                    >
                      Add Child Age
                    </Button>
                  </div>
                )}
              </FieldArray>
            </div>
            <div className="input-chil">
              <label className="w-full text-gray-600 font-semibold flex items-center">Meals Plan</label>
              <Select
                name="meal_plan"
                options={mealsOptions} // Ensure roomOptions is an array of options like [{ label: 'Room 1', value: 'room1' }, ...]
                isSearchable
                // isDisabled={!values?.hotel} // Disabled if no hotel is selected
                value={values?.meal_plan} // Since it's a multi-select, pass the array of selected values
                className="w-full rounded-lg bg-white"
                placeholder="Meals Plan"
                onChange={(selectedOptions) => {
                  setFieldValue("meal_plan", selectedOptions); // Update Formik field value with selected options
                }}
                // isMulti // Enable multi-select
              />

              <ErrorMessage name="meal_plan" component="div" className="text-sm text-red-500" />
            </div>
          </div>

          <div className="input-par">
            <div className="input-chil">
              <label htmlFor="check_in_date">Check-In Date:</label>
              <Field
                defaultValue={values.check_in_date}
                name="check_in_date"
                type="date"
                className="border rounded-md w-full h-10 px-2 floating-input"
              />
              {/* {touched.check_in_date && errors.check_in_date && <div className="text-sm text-red-500">{errors.check_in_date}</div>} */}

              <ErrorMessage name="check_in_date" component="div" className="text-sm text-red-500" />
            </div>

            <div className="input-chil">
              <label htmlFor="check_out_date">Check-Out Date:</label>
              <Field
                defaultValue={values.check_out_date}
                name="check_out_date"
                type="date"
                className="border rounded-md w-full h-10 px-2 floating-input"
              />
              {/* {touched.check_out_date && errors.check_out_date && <div className="text-sm text-red-500">{errors.check_out_date}</div>} */}

              <ErrorMessage name="check_out_date" component="div" className="text-sm text-red-500" />
            </div>
          </div>
          <div className="input-par">
            <div className="input-chil">
              <label htmlFor="client_name">Client Name:</label>
              <Field
                defaultValue={values.client_name}
                name="client_name"
                type="text"
                className="border rounded-md w-full h-10 px-2"
              />
              <ErrorMessage name="client_name" component="div" className="text-sm text-red-500" />
            </div>

            <div className="input-chil">
              <label htmlFor="client_number">Client Number:</label>
              <Field
                defaultValue={values.client_number}
                name="client_number"
                type="number"
                className="border rounded-md w-full h-10 px-2"
              />
              <ErrorMessage name="client_number" component="div" className="text-sm text-red-500" />
            </div>
          </div>

          <div className="input-chil">
            <label htmlFor="request_details">Request Details:</label>
            <Field
              defaultValue={values.request_details}
              as="textarea"
              name="request_details"
              className="border rounded-md w-full h-24 px-2"
            />
            <ErrorMessage name="request_details" component="div" className="text-sm text-red-500" />
          </div>

          <div className="flex w-full justify-center items-center mt-6">
            {edit ? (
              <button className="updateButton" type="submit" disabled={isPending}>
                {isPending ? "updating..." : "update Special Rate"}
              </button>
            ) : (
              <button className="addButton" type="submit" disabled={isPending}>
                {isPending ? "Adding..." : "Add Special Rate"}
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SpecialRateForm;
