import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { message, Steps } from "antd";
import { Button } from "flowbite-react";
import BookingForm from "../../organisms/MultiBooking/components/BookingForm";
import * as Yup from "yup";
import { Navigate } from "react-router-dom";
import Navbar from "../../organisms/Navbar/Navbar";
import { setNestedObjectValues } from "formik";
import { useMutation } from "@tanstack/react-query";
import mutateDataQuery from "../../../react_query/mutateDataQuery";
import cookie from "react-cookies";
import { useTranslation } from "react-i18next";
import BookingConfirmation from "../../organisms/MultiBooking/components/BookingConfirmation";
import BookingsEmails from "../../organisms/MultiBooking/components/BookingEmails";
import Loading from "../../organisms/Loading/Loading";

const MultiBooking = () => {
  const [t] = useTranslation();
  const localeCode = t("localeCode");
  const direction = localeCode.startsWith("ar") ? "rtl" : "ltr";

  const BookingValidationSchema = Yup.object().shape({
    clientsNames: Yup.array()
      .of(
        Yup.object().shape({
          english_name: Yup.string()
            .matches(/^[A-Za-z\s]+$/, t("clientNameErrorEn"))
            .required(t("clientNameRequiredEn")),
          arabic_name: Yup.string()
            .matches(/^[\u0621-\u064A\s]+$/, t("clientNameErrorAr"))
            .required(t("clientNameRequiredAr")),
        }),
      )
      .test("at-least-one-client", "At least one client name is required.", (clients) => {
        if (!clients || clients.length === 0) return false;
        return clients.some((client) => client.english_name?.trim() && client.arabic_name?.trim());
      }),
    departureTime: Yup.string().required(t("departureTimeRequired")),
    arrivalTime: Yup.string().required(t("arrivalTimeRequired")),
    email: Yup.string().email(t("invalidEmail")).required(t("emailRequired")),
    phoneNumber: Yup.string().required(t("phoneNumberRequired")),
    whatsappNumber: Yup.string().required(t("whatsappRequired")),
    bookingType: Yup.object()
      .shape({
        label: Yup.string().required(),
        value: Yup.boolean().required(),
      })
      .required(t("bookingTypeRequired")),
  });

  const { cardData } = useSelector((state) => state.cardReducer);

  const roomsData = useMemo(() => {
    return cardData.flatMap((item) => Array.from({ length: item.quantity }, () => ({ ...item })));
  }, [cardData]);

  const [current, setCurrent] = useState(0);
  const [currentBookingStep, setCurrentBookingStep] = useState(0);

  const [formValues, setFormValues] = useState(
    roomsData.map((room) => {
      const value = {
        clientsNames: Array.from({ length: room.persons_data.adults }, () => ({ english_name: "", arabic_name: "" })),
        departureTime: "",
        arrivalTime: "",
        email: "",
        phoneNumber: "",
        whatsappNumber: "",
        bookingType: room.payment_type === "credit" ? { label: "Voucher", value: true } : { label: "Pay", value: true },
        specialRequests: [],
        extraRequests: "",
        addToPrice: 0,
      };

      if (room.markupType === "agent") {
        const agent = room.markup;

        value.email = Object.values(agent.email_address)[0];
        value.phoneNumber = Object.values(agent.mobile_numbers)[0];
        value.whatsappNumber = Object.values(agent.whats_app_numbers)[0];
      }

      return value;
    }),
  );
  const formContainerRef = useRef(null);
  const formikRefs = useRef([]);

  const {
    isPending: isAddingSingleRoom,
    mutate: addSingleRoom,
    data: singleBooking,
  } = useMutation({
    mutationKey: ["add-book-room"],
    mutationFn: (data) => {
      return mutateDataQuery("/booking/api/v1/add-book-room/", "POST", data, {
        "content-type": "application/json",
      });
    },
    onSuccess: () => {
      setCurrentBookingStep(2);
    },
  });

  const {
    isPending: isAddingMultiRooms,
    mutate: addMultiRooms,
    data: multiBookings,
  } = useMutation({
    mutationKey: ["add-multiple-book-room"],
    mutationFn: (data) => {
      return mutateDataQuery("/booking/api/v1/add-multiple-book-room/", "POST", data, {
        "content-type": "application/json",
      });
    },
    onSuccess: () => {
      setCurrentBookingStep(2);
    },
  });

  const bookings = singleBooking || multiBookings;

  const isLoading = isAddingSingleRoom || isAddingMultiRooms;

  function mapRoomData(room, formValue) {
    return {
      number_of_persons: room.number_of_persons,
      client_names: formValue.clientsNames,
      phone_number: formValue.phoneNumber,
      whatsapp_number: formValue.whatsappNumber,
      booking_type: room.payment_type === "credit" ? formValue.bookingType.label : "",
      email: formValue.email,
      room_id: room.room_id,
      currency_type: room.currency_type,
      client_requests_text: formValue.extraRequests,
      hotel: room.hotel,
      roomEn: room.selectedroomEn,
      booked_by: room.markupType === "agent" ? room.markup.username : room.booked_by,
      meal_plan: room.mealType,
      adults: room.number_of_persons,
      client_requests_tags: formValue.specialRequests?.map((tag) => tag.label) || [],
      arrival_time_from: formValue.arrivalTime,
      arrival_time_to: formValue.departureTime,
      add_to_total_price: parseInt(formValue.addToPrice),
      total_price: parseInt(room.price),
      from_date: room.formCheckIn_whats_app,
      to_date: room.formCheckOut_whats_app,
      nights: room.nights_whats_app,
      nationality: room.nationality,
      payment_type: room.payment_type,
      inventory_status: room.inventory_status,
      net_price: room.net_price,
      first_night: room.first_night,
      additional_data: room.additional_data,
      contract_meal: room.contract_meal,
      searchPrice: room.searchPrice,
      searchCurrency: room.searchCurrency,
      hotel_address: room.hotel_address,
      hotel_map_address: room.hotel_map_address,
      unique_code: cookie.load("code") || null,
      source_channel: room.source_channel,
      persons_data: room.persons_data,
      voucher: room.payment_type === "credit" ? formValue.bookingType.value : false,
      pay_donotpay: room.payment_type === "cash" ? formValue.bookingType.value : false,
    };
  }

  function groupRoomsByHotel(mappedRooms) {
    return mappedRooms.reduce((hotels, room) => {
      const existingHotel = hotels.find((hotel) => hotel.hotel.name === room.hotel);
      if (existingHotel) {
        existingHotel.hotel.rooms.push(room);
      } else {
        hotels.push({ hotel: { name: room.hotel, rooms: [room] } });
      }
      return hotels;
    }, []);
  }

  function handleBooking() {
    const isSingleRoom = roomsData.length === 1;

    if (isSingleRoom) {
      const singleRoom = mapRoomData(roomsData[0], formValues[0]);
      addSingleRoom(singleRoom);
    } else {
      const mappedRoomsData = roomsData.map((room, index) => mapRoomData(room, formValues[index]));
      const groupedData = groupRoomsByHotel(mappedRoomsData);
      addMultiRooms(groupedData);
    }
  }

  useEffect(() => {
    roomsData.forEach((_, index) => {
      if (!formikRefs.current[index]) {
        formikRefs.current[index] = React.createRef();
      }
    });
  }, [roomsData]);

  useEffect(() => {
    const currentFormikRef = formikRefs.current[current]?.current;
    if (formContainerRef.current) {
      const firstInput = formContainerRef.current.querySelector("input");
      if (firstInput) {
        firstInput.focus();
      } else if (currentFormikRef) {
        void currentFormikRef.validateForm();
      }
    }
  }, [current]);

  const roomSteps = roomsData?.map((item, index) => ({
    key: `${item?.selectedroom} ${index}`,
    title: item?.selectedroom,
  }));

  const bookingSteps = [
    {
      key: "booking",
      title: t("booking"),
    },
    {
      key: "confirmation",
      title: t("bookingConfirmation"),
    },
    {
      key: "emails",
      title: t("emails"),
    },
  ];

  const isFormValid = async () => {
    const currentFormikRef = formikRefs.current[current]?.current;

    if (currentFormikRef) {
      await currentFormikRef.validateForm(); // Validates the form and populates `errors`
      const errors = currentFormikRef.errors; // Access the errors
      // Set the touched state for all fields
      await currentFormikRef.setTouched(setNestedObjectValues(errors, true));
      if (Object.keys(errors).length === 0) {
        await currentFormikRef.submitForm();
        return true;
      } else {
        message.error(t("fixErrors"));
        return false;
      }
    }
    return false;
  };

  const handleNext = async () => {
    const isValid = await isFormValid();

    if (isValid && current < roomsData.length - 1) {
      setCurrent(current + 1);
    }
  };
  const handlePrev = async () => {
    const currentFormikRef = formikRefs.current[current]?.current;
    if (currentFormikRef) {
      await currentFormikRef.submitForm();
    }
    if (current > 0) {
      setCurrent(current - 1);
    }
  };

  const handleDone = async () => {
    const allErrors = [];

    for (let i = 0; i < formikRefs.current.length; i++) {
      const currentFormikRef = formikRefs.current[i]?.current;
      if (currentFormikRef) {
        await currentFormikRef.validateForm(); // Validates the form
        const errors = currentFormikRef.errors; // Access the errors
        // Set the touched state for all fields
        await currentFormikRef.setTouched(setNestedObjectValues(errors, true));
        if (Object.keys(errors).length > 0) {
          allErrors.push({ index: i, errors });
        } else {
          await currentFormikRef.submitForm(); // Submit form if no errors
        }
      }
    }

    if (allErrors.length === 0) {
      message.success("All forms are valid! Processing complete.");
      setCurrentBookingStep(1);
    } else {
      message.error(t("fixErrors"));
      const firstInvalidFormIndex = allErrors[0]?.index;
      setCurrent(firstInvalidFormIndex);
    }
  };

  if (cardData.length === 0 || !cardData) {
    return <Navigate to="/" replace={true} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Navbar />
      <div dir={direction}>
        <Steps className={"py-8 px-8"} labelPlacement="vertical" current={currentBookingStep} items={bookingSteps} />
      </div>

      {currentBookingStep === 0 && (
        <div dir={direction} className="p-8 flex flex-col items-center justify-between gap-8 min-h[100dvh] h-full ">
          <Steps
            onChange={(value) => {
              setCurrent(value);
            }}
            className="flex justify-center w-fit"
            labelPlacement="vertical"
            current={current}
            items={roomSteps}
          />
          <div ref={formContainerRef} className="flex-1 w-full ">
            <BookingForm
              validationSchema={BookingValidationSchema}
              initialValues={formValues[current]}
              key={current}
              ref={formikRefs.current[current]}
              roomInfo={roomsData[current]}
              onSubmit={(values) => {
                setFormValues((prev) => prev.map((v, i) => (i === current ? values : v)));
              }}
            />
          </div>
          <div dir={direction} className={"flex gap-2"}>
            {current > 0 && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={handlePrev}
              >
                {t("back")}
              </Button>
            )}

            {current === roomsData.length - 1 && (
              <Button color="success" onClick={handleDone}>
                {t("Done")}
              </Button>
            )}
            {current < roomsData.length - 1 && (
              <Button color={"light"} onClick={handleNext}>
                {t("next")}
              </Button>
            )}
          </div>
        </div>
      )}
      {currentBookingStep === 1 && (
        <div>
          <BookingConfirmation rooms={roomsData} formValues={formValues} />
          <div dir={direction} className={"flex gap-2 justify-center mb-4"}>
            <Button
              color={"light"}
              disabled={isLoading}
              onClick={() => {
                setCurrentBookingStep(0);
              }}
            >
              {t("back")}
            </Button>

            <Button
              disabled={isLoading}
              isProcessing={isLoading}
              color="success"
              onClick={() => {
                handleBooking();
                setCurrentBookingStep(2);
              }}
            >
              {t("Done")}
            </Button>
          </div>
        </div>
      )}
      {currentBookingStep === 2 && <BookingsEmails bookings={bookings} />}
    </>
  );
};
export default MultiBooking;
