import React, { useEffect, useState } from "react";
import {
  updatedAreaAction,
  updatedDestinationAction,
  updatedHotelAction,
  updatedNationalityAction,
  updateMarkupAction,
} from "../../../redux/actions/searchformAction";
import { clearDataOfRoom, selectedSearchAction } from "../../../redux/actions/SelectedSearchAction";
import { useDispatch, useSelector } from "react-redux";
import cookie from "react-cookies";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetDataFromSearchForReseve } from "../../../redux/actions/reservationAction";
import DateRangePickerComponent from "../calendar/calendar";
import Travel from "../../travell";
import { GoSearch } from "react-icons/go";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";
import { dataOfWhatsApp } from "../../../redux/actions/dataOfWhatsApp";
import fetchDataQuery from "../../../react_query/fetchDataQuery";
import { useQuery } from "@tanstack/react-query";
import { useDebouncedCallback } from "use-debounce";
import { flushSync } from "react-dom";
import AsyncSelect from "react-select/async";
import { FaHotel } from "react-icons/fa"; // Import hotel icon
import { FaMapLocationDot, FaTreeCity } from "react-icons/fa6";

// const CustomValueContainer = ({ children, ...props }) => (
//   <components.ValueContainer {...props}>
//     <FaHotel style={{ marginRight: '8px' }} /> {/* Add the icon here */}
//     {children}
//   </components.ValueContainer>
// );
const SearchForm = ({ setValue, setValuesArray, setStar, setSearchFlag, setCityFormHotel }) => {
  //redux
  const { dataFromSearchForReseve } = useSelector((state) => state.reservationReducer);
  const { destinationData } = useSelector((state) => state.SearchFormReducer);
  const { areaData } = useSelector((state) => state.SearchFormReducer);
  const { hotelData } = useSelector((state) => state.SearchFormReducer);
  const { markupCheck } = useSelector((state) => state.SelectedSearchReducer);
  const { defaultAreaSearch } = useSelector((state) => state.SelectedSearchReducer);
  const { defaultHotelSearch } = useSelector((state) => state.SelectedSearchReducer);
  const { roomDetails } = useSelector((state) => state.SelectedSearchReducer);
  const { selectedDataFromForm } = useSelector((state) => state.SelectedSearchReducer);
  const { nationalities } = useSelector((state) => state.SearchFormReducer);

  //use
  const location = useLocation();
  const currentPath = location.pathname;
  const parts = currentPath.split("/");
  const lastParams = parts[parts.length - 1];
  const [t, i18n] = useTranslation();
  const params = useParams();
  const decoded = jwtDecode(cookie.load("access_token"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [toggle, setToggle] = useState(true);
  const token = cookie.load("access_token");
  const currencySearch = cookie.load("currency");

  //state
  const [formDes, setFormDes] = useState();
  const [formDesLoading, setFormDesLoading] = useState(false);
  const [formDesLabel, setFormDesLabel] = useState();
  const [formDesLabelLoading, setFormDesLabelLoading] = useState(false);
  const [formArea, setFormArea] = useState();
  const [formAreaLabel, setFormAreaLabel] = useState();
  const [formApi, setFormApi] = useState(true);
  const [formAreaLabelLoading, setFormAreaLabelLoading] = useState(false);
  const [formHotelId, setFormHotelId] = useState();
  const [formHotelLabel, setFormHotelLabel] = useState();
  const [markupName, setMarkupName] = useState();

  //
  const [formNation, setFormNation] = useState(
    cookie.load("code") ? null : dataFromSearchForReseve.length === 0 && "Saudi Arabia",
  );
  const [formNationLabel, setFormNationLabel] = useState(
    cookie.load("code") ? null : dataFromSearchForReseve.length === 0 && "Saudi Arabia",
  );
  const [formNationImg, setFormNationImg] = useState(
    cookie.load("code") ? null : dataFromSearchForReseve.length === 0 && "https://flagcdn.com/w320/sa.png",
  );
  const [nationFlag, setNationFlag] = useState(false);
  const [formCheckIn, setFormCheckIn] = useState();
  const [formCheckOut, setFormCheckOut] = useState();
  const [person, setPerson] = useState();
  const [adult, setAdultt] = useState([]);
  const [formRoom, setFormRooms] = useState(1);
  const [formkids, setFormKids] = useState(0);
  const [nights, setNights] = useState(1);
  const [validation, setValidation] = useState(false);
  const [details, setDetails] = useState();
  const [adultPerson, setAdultPerson] = useState(1);
  const [markup, setMarkp] = useState();
  const [markupId, setMarkupId] = useState();
  const [markupLable, setMarkupLable] = useState();
  const [markupLoading, setMarkupLoading] = useState(false);
  const [markupType, setMarkupType] = useState("system");
  const [agentInfo, setAgentInfo] = useState("");

  const [clientInfo, setClientInfo] = useState("");
  const [agentData, setAgentData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [markupLabel, setMarkupLabel] = useState();

  const {
    refetch: fetchAgents,

    data: agent,
    error: errorType,
    isSuccess: typeSuccess,
    isLoadingTask: typeLoading,
  } = useQuery({
    queryKey: ["agent"],
    queryFn: () => fetchDataQuery(`/auth/api/v1/agents/search/?q=${agentInfo}`),
  });
  useEffect(() => {
    if (typeSuccess && agent) {
      setAgentData(agent);
      setMarkupName(agent.nickname);
    }
  }, [agent, typeSuccess]);

  const {
    refetch: fetchClients,

    data: client,
    error: errorClient,
    isSuccess: clientSuccess,
    isLoadingTask: clientLoading,
  } = useQuery({
    queryKey: ["client"],
    queryFn: () => fetchDataQuery(`/auth/api/v1/customers/search/?q=${clientInfo}`),
  });
  useEffect(() => {
    if (clientSuccess && client) {
      setClientData(client);
    }
  }, [client, clientSuccess]);

  const loadAgentsOptions = useDebouncedCallback((inputValue, callback) => {
    flushSync(() => {
      setAgentInfo(inputValue);
    });
    fetchAgents().then((result) => {
      callback(result.data?.map((agent) => ({ label: agent.nickname, value: agent })) || []);
    });
  }, 1000);
  const loadClientsOptions = useDebouncedCallback((inputValue, callback) => {
    flushSync(() => {
      setClientInfo(inputValue);
    });
    fetchClients().then((result) => {
      callback(
        result?.data?.map((client) => ({
          label: client.user.first_name + " " + client.user.last_name,
          value: client.markup.id,
        })) || [],
      );
    });
  }, 1000);

  /// Default Value oF form came from first page or first form
  useEffect(() => {
    if (dataFromSearchForReseve.length !== 0) {
      setFormCheckIn(dataFromSearchForReseve.from_date);
      setFormCheckOut(dataFromSearchForReseve.to_date);
      setNights(dataFromSearchForReseve.nights);

      //cityData
      if (dataFromSearchForReseve.city) {
        ////////lang Destination //////////////
        setFormDesLoading(true);
        dispatch(
          updatedDestinationAction(token, t("lan"), cookie.load("code"), (result) => {
            if (result.status === 200) {
              setFormDesLoading(false);
              setFormDesLabel(result.data.filter((item) => item.id === dataFromSearchForReseve.city)[0].name);
              setFormDes(dataFromSearchForReseve.city);
            }
          }),
        );
      }

      //areaData
      if (dataFromSearchForReseve.area) {
        setFormDesLabelLoading(true);
        dispatch(
          updatedAreaAction(token, dataFromSearchForReseve.city, t("lan"), cookie.load("code"), (result) => {
            if (result.status === 200) {
              // const newData = [...result.data, { id: "all", name: t("allArea") }];

              if (dataFromSearchForReseve.area) {
                if (dataFromSearchForReseve.area === "all") {
                  setFormDesLabelLoading(false);
                  setFormAreaLabel(t("allArea"));
                  setFormArea(dataFromSearchForReseve.area);
                } else {
                  setFormDesLabelLoading(false);
                  const FilterArea = result.data.find(
                    (item) => item.id === JSON.parse(dataFromSearchForReseve.area)[0],
                  ).name;
                  setFormArea(dataFromSearchForReseve.area);
                  setFormAreaLabel(FilterArea);
                }
              }
            }
          }),
        );
      }

      //Hotel
      setFormAreaLabelLoading(true);
      dispatch(
        updatedHotelAction(
          token,
          dataFromSearchForReseve.area,
          t("lan"),
          cookie.load("code"),
          (result) => {
            if (result.status === 200) {
              setFormAreaLabelLoading(false);

              setFormHotelLabel(
                dataFromSearchForReseve.hotel
                  ? result.data.filter((item) => item.id === dataFromSearchForReseve.hotel)[0].name
                  : null,
              );
              setFormHotelId(dataFromSearchForReseve.hotel ? dataFromSearchForReseve.hotel : null);
            }
          },
          dataFromSearchForReseve.city,
        ),
      );

      //nationality
      if (dataFromSearchForReseve.nationality) {
        setNationFlag(true);
        dispatch(
          updatedNationalityAction(token, t("lan"), cookie.load("code"), (result) => {
            if (result.status === 200) {
              setNationFlag(false);

              const nationality = cookie.load("code")
                ? null
                : dataFromSearchForReseve.length === 0
                  ? "Saudi Arabia"
                  : dataFromSearchForReseve && dataFromSearchForReseve.nationality;

              setFormNation(nationality);

              const nationalityLabel = cookie.load("code")
                ? null
                : dataFromSearchForReseve.length === 0
                  ? "Saudi Arabia"
                  : dataFromSearchForReseve && dataFromSearchForReseve.nationality;

              setFormNationLabel(nationalityLabel);

              const nationalityImg = cookie.load("code")
                ? null
                : dataFromSearchForReseve.length === 0
                  ? "https://flagcdn.com/w320/sa.png"
                  : result.data.filter(
                      (item) =>
                        item.name_en === dataFromSearchForReseve.nationality ||
                        item.name_ar === dataFromSearchForReseve.nationality,
                    )[0].flag;

              setFormNationImg(nationalityImg);
            }
          }),
        );
      }

      //markup
      if (dataFromSearchForReseve.markup && dataFromSearchForReseve.markupType) {
        setMarkupLoading(true);
        dispatch(
          updateMarkupAction(
            token,
            cookie.load("code"),
            (result) => {
              if (result.status === 200) {
                setMarkupLoading(false);
                setMarkp(result.data);
                setMarkupLable(
                  dataFromSearchForReseve?.markupType === "system"
                    ? result?.data?.filter((item) => item.id === dataFromSearchForReseve.markup)[0].name
                    : dataFromSearchForReseve?.markupType === "agent"
                      ? dataFromSearchForReseve?.markupNameData
                      : dataFromSearchForReseve.markupNameData,
                );
                setMarkupId(dataFromSearchForReseve.markup);
                setMarkupType(dataFromSearchForReseve.markupType);
              }
            },
            t("lan"),
          ),
        );
      }
    }
  }, [dataFromSearchForReseve]);

  const flagURL = `https://restcountries.com/v3.1/all`;

  useEffect(() => {
    if ((nights, person && formCheckOut && formCheckIn)) {
      dispatch(dataOfWhatsApp(person, formCheckOut, formCheckIn, nights));
    }
  }, [person, formCheckOut, formCheckIn]);

  useEffect(() => {
    ////////lang Destination //////////////

    setFormDesLoading(true);
    dispatch(
      updatedDestinationAction(token, t("lan"), cookie.load("code"), (result) => {
        if (result.status === 200) {
          setFormDesLoading(false);
          setFormDesLabel(result.data.filter((item) => item.id === formDes)[0].name);
        }
      }),
    );
    ////////////////// lang Area ////////
    if (formDes) {
      setFormDesLabelLoading(true);
      dispatch(
        updatedAreaAction(token, formDes, t("lan"), cookie.load("code"), (result) => {
          if (result.status === 200) {
            // const newData = [...result.data, { id: "all", name: t("allArea") }];

            if (formArea) {
              if (formArea === "all") {
                setFormDesLabelLoading(false);
                setFormAreaLabel(t("allArea"));
              } else {
                setFormDesLabelLoading(false);
                const FilterArea = result.data.find((item) => item.id === JSON.parse(formArea)[0]).name;

                setFormAreaLabel(FilterArea);
              }
            }
          }
        }),
      );
    }

    /////handle lang of hotel ////
    if (formArea) {
      setFormAreaLabelLoading(true);
      dispatch(
        updatedHotelAction(
          token,
          formArea,
          t("lan"),
          cookie.load("code"),
          (result) => {
            if (result.status === 200) {
              setFormAreaLabelLoading(false);
              setFormHotelLabel(result.data.filter((item) => item.id === formHotelId)[0].name);
            }
          },
          formDes,
        ),
      );
    }

    // handle change lang of nationality
    if (formNation) {
      setNationFlag(true);
      dispatch(
        updatedNationalityAction(token, t("lan"), cookie.load("code"), (result) => {
          if (result.status === 200) {
            setNationFlag(false);
            setFormNationLabel(
              result.data.filter((item) => item.name_en === formNation || item.name_ar === formNation)[0].name,
            );
            setFormNationImg(
              result.data.filter((item) => item.name_en === formNation || item.name_ar === formNation)[0].flag,
            );
          }
        }),
      );
    }

    // handle change lang of markup
    setMarkupLoading(true);
    dispatch(
      updateMarkupAction(
        token,
        cookie.load("code"),
        (result) => {
          if (result.status === 200) {
            setMarkupLoading(false);
            setMarkp(result.data);
            setMarkupLable(result.data.filter((item) => item.id === markupId)[0].name);
          }
        },
        t("lan"),
      ),
    );
  }, [cookie.load("language"), t("lan")]);

  // useEffect(() => {
  //   // setFormDesLabel(destinationsDefault);
  //   // setFormAreaLabel(defaultAreaSearch);
  //   if (dataFromSearchForReseve.city) {
  //     dispatch(updatedAreaAction(token, dataFromSearchForReseve.city, t("lan"), cookie.load("code")));
  //   }
  //   if (dataFromSearchForReseve.area) {
  //     dispatch(updatedHotelAction(token, dataFromSearchForReseve.area, t("lan"), cookie.load("code")));
  //   }
  //   dispatch(updatedNationalityAction(token, t("lan"), cookie.load("code")));
  // }, [dataFromSearchForReseve]);

  let destination = destinationData
    ? destinationData.map((item, index) => {
        return { value: item.id, label: item.name };
      })
    : [];

  //  let area = areaData ? areaData.map((item ,index) =>{
  //   return{value:index , label:item}
  //  }) : []
  let area = areaData
    ? areaData.map((item, index) => {
        return { value: JSON.stringify([item.id]), label: item.name };
      })
    : [];

  let hotel = hotelData
    ? hotelData.map((item) => {
        return { value: item.id, label: item.name };
      })
    : [];

  let markupList = markup
    ? markup.map((item) => {
        return { value: item.id, label: item.name };
      })
    : [];

  //    let hotel = hotelData ? hotelData.map((item) =>{
  //     return ({value:item , label:item})
  //  }):[]

  let detailsArray = [];

  if (details) {
    details.map((item) => {
      detailsArray.push(item.adults);
    });
  }

  // const arrayParam =  detailsArray.join(',');
  const selectedData = {
    city: formDes,
    area: cookie.load("code") ? "all" : formArea ? formArea : dataFromSearchForReseve && dataFromSearchForReseve.area,
    from_date: formCheckIn,
    to_date: formCheckOut,
    nights: nights,
    persons: JSON.stringify(detailsArray),
    country: cookie.load("code") ? nationalities && nationalities[0]?.name_en : formNation,
    currency: currencySearch,
    markup: cookie.load("code") ? markup && markup[0]?.id : markupId,
    hotel: formHotelId,
    unique_code: cookie.load("code"),
    people: JSON.stringify(details),
  };
  const selectedDataForReseve = {
    city: formDes,
    area: cookie.load("code") ? "all" : formArea ? formArea : dataFromSearchForReseve && dataFromSearchForReseve.area,
    from_date: formCheckIn,
    to_date: formCheckOut,
    nights: nights,
    persons: JSON.stringify(detailsArray),
    nationality: formNation,
    details: details,
    formRoom: formRoom,
    markup: cookie.load("code") ? markup && markup[0]?.id : markupId,
    hotel: formHotelId,
    unique_code: cookie.load("code"),
    people: JSON.stringify(details),
    markupType: markupType,
    markupNameData: markupLable,
  };
  let flagData = nationalities
    ? nationalities.map((item) => {
        return { value: item.name_en, label: item.name, image: item.flag };
      })
    : [];

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      try {
        // Check if the screen width is "md" based on Tailwind CSS classes
        if (window.innerWidth < 1024) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      } catch (error) {}
    };

    // Add a resize event listener to track changes in screen width
    window.addEventListener("resize", handleResize);

    // Initial check when the component is mounted
    handleResize();

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    //  setCurrencySelect(params.currency)

    setSearchFlag(true);
    setValue([0, 0]);
    setValuesArray([]);
    setStar();
    if (formDes == null && formArea == null && formCheckIn == null && formCheckOut == null) {
      setValidation(true);
    } else {
      dispatch(clearDataOfRoom());
      setValidation(false);
      dispatch(selectedSearchAction(token, selectedData, t("lan")));
      dispatch(GetDataFromSearchForReseve(selectedDataForReseve));
      // Retrieve existing data from localStorage
      let existingData = JSON.parse(localStorage.getItem("DataForReseveAgain")) || [];

      if (selectedData) {
        existingData = [
          { selectedData: selectedData, selectedDataForReseve: selectedDataForReseve },
          ...existingData,
        ].slice(0, 5);
        // Save the updated array back to localStorage
        localStorage.setItem("DataForReseveAgain", JSON.stringify(existingData));
      }

      // navigate('/hotels' , { replace: true } );
    }
    // if (open) {
    //   setToggle(!toggle);
    // }
  };

  useEffect(() => {
    if ((destinationData && formDesLoading === false) || selectedDataFromForm) {
      setCityFormHotel({ formDesLabel: formDesLabel, formDesLoading: formDesLoading });
    }
  }, [destinationData, formDesLoading, selectedDataFromForm]);

  return (
    <div className=" z-50 w-full flex flex-col items-center justify-center relative ">
      {/* <button
        onClick={() => setToggle(!toggle)}
        className="flex items-center justify-center text-sm mx-auto gap-x-1 w-[90%] rounded-lg bg-[#002244] text-white p-2 lg:hidden"
      >
        {t("search")} <BiSearchAlt2 className="w-5 h-5" />
      </button> */}
      <form
        dir={t("dir")}
        className={`font-barlow  top-5 bg-white     shadow-lg   sm:w-full lg:p-3 lg:px-10  rounded-full  max-lg:hidden
         `}
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="grid grid-cols-4 py-2 ">
          <div className="flex flex-col items-center justify-between   border-solid">
            {/* <input data-toggle = "datepicker" type = "text" class = "placeholder:text-eerie-black placeholder:uppercase text-sm outline-none border-none w-full" placeholder="check in"/> */}
            {/* <span class="cursor-pointer">
                                        <img src = "/images/calendar.svg"/>
                                    </span> */}
            <Select
              className="w-full text-gray-800 "
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: "md:18px ",
                  fontWeight: "bold",
                  color: "red",
                  backgroundColor: "white",
                  fontStyle: "bold",

                  borderBottom: "2px thin gray",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  boxShadow: "none",
                  borderRadius: "0",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#002244", // Change this to the desired color for the placeholder
                }),
              }}
              name="destination"
              value={formDesLoading ? null : formDesLabel && [{ label: formDesLabel, value: formDes }]}
              options={formDesLoading ? [] : destination}
              onChange={(e) => {
                setFormDesLabelLoading(true);
                setFormDes(e.value);
                setFormDesLabel(e.label);
                setFormAreaLabel(null);
                setFormArea(null);
                setFormHotelId(null);
                setFormHotelLabel(null);
                setFormApi(true);
                if (defaultAreaSearch) {
                  defaultAreaSearch[0] = null;
                }
                if (defaultHotelSearch) {
                  defaultHotelSearch[0] = null;
                }
                dispatch(
                  updatedAreaAction(token, e.value, t("lan"), cookie.load("code"), (result) => {
                    if (result.status === 200) {
                      setFormDesLabelLoading(false);
                    }
                  }),
                );
              }}
              required
              isSearchable
              placeholder={
                formDesLoading
                  ? t("lan") === "en"
                    ? "Loading..."
                    : "جاري التحميل..."
                  : t("lan") === "en"
                    ? "Destination"
                    : "المدينة"
              }
            />
            {/* <span className='text-lion lg:text-sm'>Destination is Requierd</span> */}
          </div>
          {!cookie.load("code") && (
            <div className="flex flex-col items-center justify-between   border-solid">
              {/* <input data-toggle = "datepicker" type = "text" class = "placeholder:text-eerie-black placeholder:uppercase text-sm outline-none border-none w-full" placeholder="check in"/> */}
              {/* <span class="cursor-pointer">
                                    <img src = "/images/calendar.svg"/>
                                </span> */}
              <Select
                className="w-full text-gray-800 "
                name="area"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",

                    borderBottom: "2px thin gray",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                value={formDesLabelLoading ? null : formAreaLabel && [{ label: formAreaLabel, value: formArea }]}
                options={
                  formDesLabelLoading ? [] : formDesLabel ? [{ label: t("allArea"), value: "all" }, ...area] : []
                }
                onChange={(e) => {
                  setFormAreaLabelLoading(true);
                  setFormArea(e.value);
                  setFormAreaLabel(e.label);
                  setFormHotelId(null);
                  setFormHotelLabel(null);
                  if (defaultHotelSearch) {
                    defaultHotelSearch[0] = null;
                  }
                  dispatch(
                    updatedHotelAction(
                      token,
                      e.value,
                      t("lan"),
                      cookie.load("code"),
                      (result) => {
                        if (result.status === 200) {
                          setFormAreaLabelLoading(false);
                        }
                      },
                      formDes,
                    ),
                  );
                  // else {
                  //     // Handle the case where selectedValues is empty (cleared selection)
                  //     setFormAreaLabelLoading(false);
                  //     setFormHotelId("");
                  //   }
                }}
                required
                isSearchable
                placeholder={
                  formDesLabelLoading
                    ? t("lan") === "en"
                      ? "Loading..."
                      : "جاري التحميل..."
                    : t("lan") === "en"
                      ? "Area"
                      : "المنطقة"
                }
              />

              {/* <span className='text-lion lg:text-sm'>Area is Requierd</span> */}
            </div>
          )}
          {!cookie.load("code") && (
            <div className="flex flex-col items-center justify-between   border-solid">
              {/* <input data-toggle = "datepicker" type = "text" class = "placeholder:text-eerie-black placeholder:uppercase text-sm outline-none border-none w-full" placeholder="check in"/> */}
              {/* <span class="cursor-pointer">
                                    <img src = "/images/calendar.svg"/>
                                </span> */}
              <Select
                className="w-full"
                name="hotel"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",

                    borderBottom: "2px thin gray",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                value={formAreaLabelLoading ? null : formHotelLabel && [{ label: formHotelLabel, value: formHotelId }]}
                options={formAreaLabelLoading ? [] : formAreaLabel ? hotel : []}
                onChange={(e) => {
                  if (e) {
                    setFormHotelId(e.value);
                    setFormHotelLabel(e.label);
                  } else {
                    setFormHotelId(null); // Clear the selection if the user clears the input
                    setFormHotelLabel(null);
                  }
                }}
                isClearable
                isSearchable
                placeholder={
                  formAreaLabelLoading
                    ? t("lan") === "en"
                      ? "Loading..."
                      : "جاري التحميل..."
                    : t("lan") === "en"
                      ? "Hotels"
                      : "الفنادق"
                }
              />
            </div>
          )}
          {!cookie.load("code") && (
            <div className="flex flex-col items-center justify-between   border-solid">
              {/* <input data-toggle = "datepicker" type = "text" class = "placeholder:text-eerie-black placeholder:uppercase text-sm outline-none border-none w-full" placeholder="check in"/> */}
              {/* <span class="cursor-pointer">
                                    <img src = "/images/calendar.svg"/>
                                </span> */}
              <Select
                className="w-full z-50 "
                name="Nationality"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontSize: "md:18px ",
                    fontWeight: "bold",
                    color: "red",
                    backgroundColor: "white",
                    fontStyle: "bold",

                    borderBottom: "2px thin gray",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    boxShadow: "none",
                    borderRadius: "0",
                  }),
                  placeholder: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#002244", // Change this to the desired color for the placeholder
                  }),
                }}
                options={flagData}
                value={
                  nationFlag
                    ? null
                    : formNation && [
                        {
                          value: formNation,
                          label: formNationLabel,
                          image: formNationImg,
                        },
                      ]
                }
                onChange={(e) => {
                  setFormNation(e.value);
                  setFormNationLabel(e.label);
                  setFormNationImg(e.image);
                }}
                formatOptionLabel={(flagData) => (
                  <div className="flex justify-start items-center  gap-x-3 country-option ">
                    <img src={flagData.image} alt="country-image" className="w-5 h-3" />
                    <span>{flagData.label}</span>
                  </div>
                )}
                isSearchable
                required
                placeholder={nationFlag ? (t("lan") === "en" ? "Loading..." : "جاري التحميل...") : "Nationality"}
              />
            </div>
          )}
        </div>
        <div className="w-full flex gap-4 ">
          <div className="w-full ">
            <Travel
              setPerson={setPerson}
              setFormRooms={setFormRooms}
              setDetails={setDetails}
              setFormKids={setFormKids}
              setAdultPerson={setAdultPerson}
            />
          </div>
          <div className="w-full">
            <DateRangePickerComponent
              setFormCheckIn={setFormCheckIn}
              setFormCheckOut={setFormCheckOut}
              setNights={setNights}
            />
          </div>
          <div
            className={`w-full flex items-center ${
              cookie.load("code") ? " justify-end" : " justify-between"
            } gap-x-5  border-solid `}
          >
            {!cookie.load("code") && (
              <div className="flex  w-full flex-col gap-y-4 items-start  px-1      border-b   ">
                {/* <p className="flex items-center gap-x-1 text-md font-medium md:px-10 px-2">
               Markup<span className="text-md text-red-700"> *</span>
               <HiMiniQuestionMarkCircle className="text-amber-500 w-4 h-4" />
             </p> */}
                <div className="w-full flex justify-between items-center ">
                  {/* <CgToolbox className="w-8 h-8 text-[#002244]  " /> */}
                  <Select
                    // defaultValue={{ label: "System", value: "system" }}
                    value={{
                      label:
                        t("lan") === "en"
                          ? selectedDataForReseve.markupType
                          : selectedDataForReseve.markupType === "system"
                            ? "النظام"
                            : selectedDataForReseve.markupType === "agent"
                              ? "وكيل"
                              : "عميل",
                      value: selectedDataForReseve.markupType,
                    }}
                    className="w-full"
                    onChange={(e) => {
                      setMarkupType(e.value);
                      setMarkupLable("");
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: "md:18px ",
                        fontWeight: "bold",
                        color: "red",
                        backgroundColor: "white",
                        fontStyle: "bold",
                        borderBottom: "none",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        boxShadow: "none",
                        borderRadius: "0",
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#002244", // Change this to the desired color for the placeholder
                      }),
                    }}
                    placeholder={t("lan") === "en" ? "Select Markup type" : "اختر سياسة تسعير"}
                    options={[
                      {
                        label: `${t("lan")}` === "en" ? "System" : "النظام",
                        value: "system",
                      },
                      {
                        label: `${t("lan")}` === "en" ? "Agent" : "وكيل",
                        value: "agent",
                      },
                      {
                        label: `${t("lan")}` === "en" ? "Client" : "عميل",
                        value: "client",
                      },
                    ]}
                  ></Select>
                  {markupType === "system" && (
                    <Select
                      className="w-full"
                      value={markupLoading ? null : markupLable && [{ label: markupLable, value: markupId }]}
                      // value={
                      //   markupId
                      //     ? [{ label: markupLabel || dataFromSearchForReseve?.markupName, value: markupId }]
                      //     : null
                      // }
                      // defaultValue={
                      //   dataFromSearchForReseve
                      //     ? [{ label: dataFromSearchForReseve?.markupName, value: dataFromSearchForReseve.markup }]
                      //     : null
                      // }
                      options={markup ? markupList : []}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          fontSize: "md:18px ",
                          fontWeight: "bold",
                          color: "red",
                          backgroundColor: "white",
                          fontStyle: "bold",
                          borderBottom: "none",
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          boxShadow: "none",
                          borderRadius: "0",
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: "#002244", // Change this to the desired color for the placeholder
                        }),
                      }}
                      isClearable
                      required
                      onChange={(e) => {
                        if (e) {
                          setMarkupId(e.value);
                          setMarkupLable(e.label);
                        } else {
                          if (markupCheck) {
                            markupCheck[0] = null;
                          }
                          setMarkupId(null);
                          setMarkupLable(null);
                        }
                      }}
                      isSearchable
                      placeholder={t("lan") === "en" ? " Markup " : "سياسة تسعير"}
                    />
                  )}
                  {markupType === "agent" && (
                    <div className="flex w-full flex-col gap-1">
                      <AsyncSelect
                        placeholder={t("lan") === "en" ? "Agent name or mobile number" : "اسم وكيل او رقم الهاتف"}
                        isClearable
                        value={markupLoading ? null : markupLable && [{ label: markupLable, value: markupId }]}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: "12px ",
                            fontWeight: "bold",
                            color: "red",
                            backgroundColor: "white",
                            fontStyle: "bold",
                            borderBottom: "none",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            boxShadow: "none",
                            borderRadius: "0",
                          }),
                        }}
                        onChange={(e) => {
                          if (e) {
                            setMarkupId(e.value);
                            setMarkupLable(e.label);
                          } else {
                            if (markupCheck) {
                              markupCheck[0] = null;
                            }
                            setMarkupId(null);
                            setMarkupLable(null);
                          }
                        }}
                        loadOptions={loadAgentsOptions}
                        className="w-full border-none"
                      />
                      {!agentData && (
                        <p className="font-medium text-red-700">
                          Error fetching agent data, check your internet connection
                        </p>
                      )}
                    </div>
                  )}
                  {markupType === "client" && (
                    <div className="flex w-full flex-col gap-1">
                      <AsyncSelect
                        placeholder={t("lan") === "en" ? "Client name or mobile number" : "اسم العميل او رقم الهاتف"}
                        value={markupLoading ? null : markupLable && [{ label: markupLable, value: markupId }]}
                        isClearable
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: "12px ",
                            fontWeight: "bold",
                            color: "red",
                            backgroundColor: "white",
                            fontStyle: "bold",
                            borderBottom: "none",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            boxShadow: "none",
                            borderRadius: "0",
                          }),
                        }}
                        onChange={(e) => {
                          if (e) {
                            setMarkupId(e.value);
                            setMarkupLable(e.label);
                          } else {
                            if (markupCheck) {
                              markupCheck[0] = null;
                            }
                            setMarkupId(null);
                            setMarkupLable(null);
                          }
                        }}
                        // onChange={(e) => {

                        //   if (e) {
                        //     setMarkupId(e.value);
                        //     setMarkupLabel(e.label);
                        //     dispatch(checkMarkup([{ label: e.label, value: e.value }]));
                        //   } else {
                        //     setMarkupId("");
                        //     setMarkupLabel("");
                        //   }
                        // }}
                        loadOptions={loadClientsOptions}
                        className="w-full"
                      />
                      {!clientData && (
                        <p className="font-medium text-red-700">
                          Error fetching client data, check your internet connection
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full p-4">
          <button
            type="submit"
            className=" w-max bg-[#002244]  text-sm px-2 py-1 z-0   tracking-widest   rounded-md font-barlow  flex items-center justify-center gap-x-1 uppercase text-white transition duration-300   active:scale-95 hover:bg-yellow-600"
          >
            <GoSearch className="w-5 h-6" /> {t("search")}
          </button>
        </div>

        {/* <div class="flex items-center justify-start gap-x-5 px-3 lg:items-start  lg:col-span-3 py-2  border-solid "> */}
        {/* </div> */}
      </form>
      <form
        dir={t("dir")}
        className="font-barlow px-5 pb-5  bg-white !text-center shadow-lg  w-full  lg:hidden   "
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="lg:hidden  my-2 gap-2  rounded-md border-2 border-[#002244] border-solid flex   items-center justify-between   ">
          <FaTreeCity className=" mx-2" />

          <Select
            className="w-full text-gray-800"
            name="destination"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: "md:18px ",
                fontWeight: "bold",
                color: "red",
                backgroundColor: "white",
                fontStyle: "bold",

                borderBottom: "2px thin gray",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                boxShadow: "none",
                borderRadius: "0",
              }),
              placeholder: (baseStyles, state) => ({
                ...baseStyles,
                color: "#002244", // Change this to the desired color for the placeholder
              }),
            }}
            value={formDesLoading ? null : formDesLabel && [{ label: formDesLabel, value: formDes }]}
            options={formDesLoading ? [] : destination}
            onChange={(e) => {
              setFormDesLabelLoading(true);
              setFormDes(e.value);
              setFormDesLabel(e.label);
              setFormAreaLabel("");
              setFormArea("");
              setFormHotelId("");
              setFormApi(true);
              if (defaultAreaSearch) {
                defaultAreaSearch[0] = null;
              }
              if (defaultHotelSearch) {
                defaultHotelSearch[0] = null;
              }
              dispatch(
                updatedAreaAction(token, e.value, t("lan"), cookie.load("code"), (result) => {
                  if (result.status === 200) {
                    setFormDesLabelLoading(false);
                  }
                }),
              );
            }}
            required
            isSearchable
            placeholder={
              formDesLoading
                ? t("lan") === "en"
                  ? "Loading..."
                  : "جاري التحميل..."
                : t("lan") === "en"
                  ? "Destination"
                  : "المدينة"
            }
          />
          {/* <span className='text-lion lg:text-sm'>Destination is Requierd</span> */}
        </div>
        {!cookie.load("code") && (
          <div className="my-2 rounded-md border-2 border-[#002244] border-solid flex  items-center justify-between   ">
            <div>
              <FaMapLocationDot className="mx-2" />
            </div>
            <Select
              className="w-full text-gray-800 "
              name="area"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: "md:18px ",
                  fontWeight: "bold",
                  color: "red",
                  backgroundColor: "white",
                  fontStyle: "bold",

                  borderBottom: "2px thin gray",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  boxShadow: "none",
                  borderRadius: "0",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#002244", // Change this to the desired color for the placeholder
                }),
              }}
              value={formDesLabelLoading ? null : formAreaLabel && [{ label: formAreaLabel, value: formArea }]}
              options={formDesLabelLoading ? [] : formDesLabel ? [{ label: t("allArea"), value: "all" }, ...area] : []}
              onChange={(e) => {
                setFormAreaLabelLoading(true);
                setFormArea(e.value);
                setFormAreaLabel(e.label);
                setFormHotelId("");
                setFormHotelLabel("");
                if (defaultHotelSearch) {
                  defaultHotelSearch[0] = null;
                }
                dispatch(
                  updatedHotelAction(
                    token,
                    e.value,
                    t("lan"),
                    cookie.load("code"),
                    (result) => {
                      if (result.status === 200) {
                        setFormAreaLabelLoading(false);
                      }
                    },
                    formDes,
                  ),
                );
              }}
              required
              isSearchable
              placeholder={
                formDesLabelLoading
                  ? t("lan") === "en"
                    ? "Loading..."
                    : "جاري التحميل..."
                  : t("lan") === "en"
                    ? "Area"
                    : "المنطقة"
              }
            />
            {/* <span className='text-lion lg:text-sm'>Area is Requierd</span> */}
          </div>
        )}
        {!cookie.load("code") && (
          <div className="my-2 rounded-md border-2 border-[#002244] border-solid flex  items-center justify-between   ">
            <FaHotel className="mx-2" /> {/* Add the icon here */}
            <Select
              className="w-full"
              name="hotel"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: "md:18px ",
                  fontWeight: "bold",
                  color: "red",
                  backgroundColor: "white",
                  fontStyle: "bold",

                  borderBottom: "2px thin gray",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  boxShadow: "none",
                  borderRadius: "0",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#002244", // Change this to the desired color for the placeholder
                }),
              }}
              value={formAreaLabelLoading ? null : formHotelLabel && [{ label: formHotelLabel, value: formHotelId }]}
              options={formAreaLabelLoading ? [] : formAreaLabel ? hotel : []}
              onChange={(e) => {
                if (e) {
                  setFormHotelId(e.value);
                  setFormHotelLabel(e.label);
                } else {
                  setFormHotelId(null); // Clear the selection if the user clears the input
                  setFormHotelLabel(null);
                }
              }}
              isClearable
              isSearchable
              placeholder={
                formAreaLabelLoading
                  ? t("lan") === "en"
                    ? "Loading..."
                    : "جاري التحميل..."
                  : t("lan") === "en"
                    ? "Hotels"
                    : "الفنادق"
              }
            />
          </div>
        )}
        {!cookie.load("code") && (
          <div className="my-2 rounded-md border-2 border-[#002244] border-solid flex flex-col items-center justify-between  ">
            {/* <input data-toggle = "datepicker" type = "text" class = "placeholder:text-eerie-black placeholder:uppercase text-sm outline-none border-none w-full" placeholder="check in"/> */}
            {/* <span class="cursor-pointer">
                      <img src = "/images/calendar.svg"/>
                  </span> */}
            <Select
              className="w-full  "
              name="Nationality"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  fontSize: "md:18px ",
                  fontWeight: "bold",
                  color: "red",
                  backgroundColor: "white",
                  fontStyle: "bold",

                  borderBottom: "2px thin gray",
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  boxShadow: "none",
                  borderRadius: "0",
                }),
                placeholder: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "#002244", // Change this to the desired color for the placeholder
                }),
              }}
              value={
                nationFlag
                  ? null
                  : formNation && [
                      {
                        value: formNation,
                        label: formNation,
                        image: formNationImg,
                      },
                    ]
              }
              options={flagData}
              onChange={(e) => {
                setFormNation(e.value);
                setFormNationLabel(e.label);
                setFormNationImg(e.image);
              }}
              formatOptionLabel={(flagData) => (
                <div className="flex justify-start items-center  gap-x-3 country-option  ">
                  <img src={flagData.image} alt="country-image" className="w-5 h-3" />
                  <span>{flagData.label}</span>
                </div>
              )}
              required
              isSearchable
              placeholder={nationFlag ? (t("lan") === "en" ? "Loading..." : "جاري التحميل...") : "Nationality"}
            />
          </div>
        )}

        <div className="my-2 rounded-md border-2 border-[#002244] border-solid flex flex-col items-center justify-between    ">
          <Travel
            setPerson={setPerson}
            setFormRooms={setFormRooms}
            setDetails={setDetails}
            setFormKids={setFormKids}
            setAdultPerson={setAdultPerson}
          />
        </div>

        <div className="my-2 rounded-md border-2 border-[#002244] border-solid flex z-[60]   px-2">
          <DateRangePickerComponent
            setFormCheckIn={setFormCheckIn}
            setFormCheckOut={setFormCheckOut}
            setNights={setNights}
            formCheckIn={formCheckIn}
            formCheckOut={formCheckOut}
          />
        </div>
        {!cookie.load("code") && (
          // <div class="my-2 rounded-md border-2 border-[#002244] border-solid flex flex-col items-center justify-between px-3 lg:items-start lg:col-span-2 py-2  z-50">
          //   <Select
          //     className="w-full "
          //     options={markup ? markupList : []}
          //     value={markupLoading ? null : markupLabel && [{ label: markupLable, value: markupId }]}
          //     styles={{
          //       control: (baseStyles, state) => ({
          //         ...baseStyles,
          //         fontSize: "md:18px ",
          //         fontWeight: "bold",
          //         color: "red",
          //         backgroundColor: "white",
          //         fontStyle: "bold",

          //         borderBottom: "2px thin gray",
          //         borderTop: "none",
          //         borderLeft: "none",
          //         borderRight: "none",
          //         boxShadow: "none",
          //         borderRadius: "0",
          //       }),
          //       placeholder: (baseStyles, state) => ({
          //         ...baseStyles,
          //         color: "#002244", // Change this to the desired color for the placeholder
          //       }),
          //     }}
          //     onChange={(e) => {
          //       if (e) {
          //         setMarkupId(e.value);
          //         setMarkupLable(e.label);
          //       } else {
          //         // if (markupCheck) {
          //         //   markupCheck[0] = null;
          //         // }
          //         setMarkupId(null);
          //         setMarkupLable(null);
          //       }
          //     }}
          //     required
          //     isSearchable
          //     placeholder={
          //       markupLoading
          //         ? t("lan") === "en"
          //           ? "Loading..."
          //           : "جاري التحميل..."
          //         : t("lan") === "en"
          //           ? "Markup"
          //           : "سياسة التسعير"
          //     }
          //   />
          // </div>
          <div
            className={`w-full flex items-center  ${
              cookie.load("code") ? " justify-end" : " justify-between"
            } gap-x-5  border-solid `}
          >
            {!cookie.load("code") && (
              <div className="flex  w-full flex-col gap-y-4 items-start   gap-2    border-b   ">
                {/* <p className="flex items-center gap-x-1 text-md font-medium md:px-10 px-2">
               Markup<span className="text-md text-red-700"> *</span>
               <HiMiniQuestionMarkCircle className="text-amber-500 w-4 h-4" />
             </p> */}
                <div className="w-full flex justify-between items-center my-2  rounded-md border-2 border-[#002244] border-solid  flex-col   ">
                  {/* <CgToolbox className="w-8 h-8 text-[#002244]  " /> */}
                  <Select
                    // defaultValue={{ label: "System", value: "system" }}
                    value={{
                      label:
                        t("lan") === "en"
                          ? selectedDataForReseve.markupType
                          : selectedDataForReseve.markupType === "system"
                            ? "النظام"
                            : selectedDataForReseve.markupType === "agent"
                              ? "وكيل"
                              : "عميل",
                      value: selectedDataForReseve.markupType,
                    }}
                    className="w-full border-b  capitalize"
                    onChange={(e) => {
                      setMarkupType(e.value);
                      setMarkupLable("");
                    }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: "md:18px ",
                        fontWeight: "bold",
                        color: "red",
                        backgroundColor: "white",
                        fontStyle: "bold",
                        borderBottom: "none",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        boxShadow: "none",
                        borderRadius: "0",
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: "#002244", // Change this to the desired color for the placeholder
                      }),
                    }}
                    menuPlacement="top" // This makes the dropdown appear above the input
                    placeholder={t("lan") === "en" ? "Select Markup type" : "اختر سياسة تسعير"}
                    options={[
                      {
                        label: `${t("lan")}` === "en" ? "System" : "النظام",
                        value: "system",
                      },
                      {
                        label: `${t("lan")}` === "en" ? "Agent" : "وكيل",
                        value: "agent",
                      },
                      {
                        label: `${t("lan")}` === "en" ? "Client" : "عميل",
                        value: "client",
                      },
                    ]}
                  ></Select>
                  {markupType === "system" && (
                    <Select
                      className="w-full "
                      value={markupLoading ? null : markupLable && [{ label: markupLable, value: markupId }]}
                      // value={
                      //   markupId
                      //     ? [{ label: markupLabel || dataFromSearchForReseve?.markupName, value: markupId }]
                      //     : null
                      // }
                      // defaultValue={
                      //   dataFromSearchForReseve
                      //     ? [{ label: dataFromSearchForReseve?.markupName, value: dataFromSearchForReseve.markup }]
                      //     : null
                      // }
                      menuPlacement="top" // This makes the dropdown appear above the input
                      options={markup ? markupList : []}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          fontSize: "md:18px ",
                          fontWeight: "bold",
                          color: "red",
                          backgroundColor: "white",
                          fontStyle: "bold",
                          borderBottom: "none",
                          borderTop: "none",
                          borderLeft: "none",
                          borderRight: "none",
                          boxShadow: "none",
                          borderRadius: "0",
                        }),
                        placeholder: (baseStyles, state) => ({
                          ...baseStyles,
                          color: "#002244", // Change this to the desired color for the placeholder
                        }),
                      }}
                      isClearable
                      required
                      onChange={(e) => {
                        if (e) {
                          setMarkupId(e.value);
                          setMarkupLable(e.label);
                        } else {
                          if (markupCheck) {
                            markupCheck[0] = null;
                          }
                          setMarkupId(null);
                          setMarkupLable(null);
                        }
                      }}
                      isSearchable
                      placeholder={t("lan") === "en" ? " Markup " : "سياسة تسعير"}
                    />
                  )}
                  {markupType === "agent" && (
                    <div className="flex w-full flex-col gap-1">
                      <AsyncSelect
                        required
                        placeholder={t("lan") === "en" ? "Agent name or mobile number" : "اسم وكيل او رقم الهاتف"}
                        isClearable
                        value={markupLoading ? null : markupLable && [{ label: markupLable, value: markupId }]}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: "12px ",
                            fontWeight: "bold",
                            color: "red",
                            backgroundColor: "white",
                            fontStyle: "bold",
                            borderBottom: "none",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            boxShadow: "none",
                            borderRadius: "0",
                          }),
                        }}
                        menuPlacement="top" // This makes the dropdown appear above the input
                        onChange={(e) => {
                          if (e) {
                            setMarkupId(e.value);
                            setMarkupLable(e.label);
                          } else {
                            if (markupCheck) {
                              markupCheck[0] = null;
                            }
                            setMarkupId(null);
                            setMarkupLable(null);
                          }
                        }}
                        loadOptions={loadAgentsOptions}
                        className="w-full border-none"
                      />
                      {!agentData && (
                        <p className="font-medium text-red-700">
                          Error fetching agent data, check your internet connection
                        </p>
                      )}
                    </div>
                  )}
                  {markupType === "client" && (
                    <div className="flex w-full flex-col gap-1">
                      <AsyncSelect
                        required
                        placeholder={t("lan") === "en" ? "Client name or mobile number" : "اسم العميل او رقم الهاتف"}
                        value={markupLoading ? null : markupLable && [{ label: markupLable, value: markupId }]}
                        isClearable
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: "12px ",
                            fontWeight: "bold",
                            color: "red",
                            backgroundColor: "white",
                            fontStyle: "bold",
                            borderBottom: "none",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            boxShadow: "none",
                            borderRadius: "0",
                          }),
                        }}
                        menuPlacement="top" // This makes the dropdown appear above the input
                        onChange={(e) => {
                          if (e) {
                            setMarkupId(e.value);
                            setMarkupLable(e.label);
                          } else {
                            if (markupCheck) {
                              markupCheck[0] = null;
                            }
                            setMarkupId(null);
                            setMarkupLable(null);
                          }
                        }}
                        // onChange={(e) => {

                        //   if (e) {
                        //     setMarkupId(e.value);
                        //     setMarkupLabel(e.label);
                        //     dispatch(checkMarkup([{ label: e.label, value: e.value }]));
                        //   } else {
                        //     setMarkupId("");
                        //     setMarkupLabel("");
                        //   }
                        // }}
                        loadOptions={loadClientsOptions}
                        className="w-full"
                      />
                      {!clientData && (
                        <p className="font-medium text-red-700">
                          Error fetching client data, check your internet connection
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col items-center justify-between     md:col-span-2 lg:col-span-1">
          <button type="submit" className=" updateButton !bg-[#002244]">
            <GoSearch className="w-5 h-6" /> {t("search")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchForm;
